import React from 'react';

const DealSearchBar = ({
                           searchTerm,
                           setSearchTerm,
                           statusFilter,
                           setStatusFilter,
                           onAddDealClick,
                       }) => {
    return (
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 space-y-2 md:space-y-0">
            <div className="flex space-x-2 flex-grow">
                <input
                    type="text"
                    placeholder="Search by Deal ID, Vendor ID, or Title"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-96 border border-gray-300 rounded px-4 py-2"
                />
                <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    className="border border-gray-300 rounded px-4 py-2"
                >
                    <option value="">All Statuses</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="paused">Paused</option>
                    <option value="expired">Expired</option>
                    <option value="under_review">Under Review</option>
                    <option value="pending_payment">Pending Payment</option>
                </select>
            </div>
            <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={onAddDealClick}
            >
                Add Deal
            </button>
        </div>
    );
};

export default DealSearchBar;
