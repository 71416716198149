// ChatRoom.jsx
import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../../supabaseClient';
import { FaPaperclip, FaTrash } from 'react-icons/fa';

const ChatRoom = ({ chat, adminId }) => {
    const [messages, setMessages] = useState([]);
    const [chatSession, setChatSession] = useState(chat);
    const [messageInput, setMessageInput] = useState('');
    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [messageToDelete, setMessageToDelete] = useState(null);
    const [otherParticipantName, setOtherParticipantName] = useState('');
    const fileInputRef = useRef(null);
    const messagesEndRef = useRef(null);

    // Determine the other participant's ID.
    const getOtherParticipantId = (session) => {
        return session.participant_1 === adminId ? session.participant_2 : session.participant_1;
    };

    useEffect(() => {
        setChatSession(chat);
        if (chat) {
            fetchMessages(chat.chat_id);
            resetUnreadCount(chat.chat_id);
            subscribeToMessages(chat.chat_id);
            fetchOtherParticipantName(chat);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chat]);

    const fetchOtherParticipantName = async (session) => {
        const otherId = getOtherParticipantId(session);
        try {
            // Try Vendors first.
            const { data: vendorData } = await supabase
                .from('Vendors')
                .select('business_name')
                .eq('vendor_id', otherId)
                .single();
            if (vendorData && vendorData.business_name) {
                setOtherParticipantName(vendorData.business_name);
                return;
            }
            // Fall back to Customers.
            const { data: customerData } = await supabase
                .from('Customers')
                .select('first_name, last_name')
                .eq('customer_id', otherId)
                .single();
            if (customerData) {
                setOtherParticipantName(`${customerData.first_name} ${customerData.last_name}`);
            } else {
                setOtherParticipantName(otherId);
            }
        } catch (err) {
            console.error("Error fetching participant name:", err);
            setOtherParticipantName(otherId);
        }
    };

    const subscribeToMessages = (chatId) => {
        const subscription = supabase
            .channel(`chat:${chatId}`)
            .on(
                'postgres_changes',
                { event: 'INSERT', schema: 'public', table: 'Messages' },
                (payload) => {
                    const newMsg = payload.new;
                    if (newMsg.chat_id !== chatId) return;
                    setMessages((prev) => {
                        if (prev.some((msg) => msg.message_id === newMsg.message_id)) {
                            return prev;
                        }
                        const updated = [...prev, newMsg].sort(
                            (a, b) => new Date(a.created_at) - new Date(b.created_at)
                        );
                        return updated;
                    });
                    scrollToBottom();
                }
            )
            .subscribe();
        return () => {
            supabase.removeChannel(subscription);
        };
    };

    const fetchMessages = async (chatId) => {
        try {
            const { data, error } = await supabase
                .from('Messages')
                .select('*')
                .eq('chat_id', chatId)
                .order('created_at', { ascending: true });
            if (error) {
                console.error("Error fetching messages:", error);
            } else {
                setMessages(data);
                scrollToBottom();
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };

    const resetUnreadCount = async (chatId) => {
        try {
            const { data, error } = await supabase
                .from('ChatSession')
                .select('participant_1, participant1_unread_count, participant2_unread_count')
                .eq('chat_id', chatId)
                .single();
            if (error) {
                console.error("Error fetching chat session:", error);
                return;
            }
            if (data) {
                if (data.participant_1 === adminId) {
                    await supabase.from('ChatSession').update({ participant1_unread_count: 0 }).eq('chat_id', chatId);
                } else {
                    await supabase.from('ChatSession').update({ participant2_unread_count: 0 }).eq('chat_id', chatId);
                }
            }
        } catch (err) {
            console.error("Error resetting unread count:", err);
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const checkAbusiveText = async (text) => {
        try {
            const response = await fetch(
                `https://www.purgomalum.com/service/containsprofanity?text=${encodeURIComponent(text)}`
            );
            const result = await response.text();
            return result.toLowerCase() === "true";
        } catch (err) {
            console.error("Error checking abusive text:", err);
            return false;
        }
    };

    const handleSendMessage = async () => {
        if (!messageInput.trim() || !chatSession) return;
        const isAbusive = await checkAbusiveText(messageInput.trim());
        if (isAbusive) {
            setFeedbackMessage("🚫 Abusive messages are not allowed!");
            setTimeout(() => setFeedbackMessage(null), 3000);
            return;
        }
        const now = new Date().toISOString();
        const newMsg = {
            message_id: `${Date.now()}_${Math.random().toString(36).substring(2, 15)}`,
            chat_id: chatSession.chat_id,
            sender_id: adminId,
            receiver_id: chatSession.participant_1 === adminId ? chatSession.participant_2 : chatSession.participant_1,
            message: messageInput.trim(),
            attachment_url: null,
            status: "sent",
            read_status: false,
            created_at: now
        };

        setMessages((prev) => [...prev, newMsg]);
        setMessageInput('');
        scrollToBottom();

        try {
            const { error } = await supabase.from('Messages').insert([newMsg]);
            if (error) {
                console.error("Error sending message:", error);
                setMessages((prev) => prev.filter(msg => msg.message_id !== newMsg.message_id));
                setFeedbackMessage("That message wasn't sent.");
                setTimeout(() => setFeedbackMessage(null), 3000);
            }
            await supabase.from('ChatSession').update({
                last_message: newMsg.message,
                last_message_time: now
            }).eq('chat_id', chatSession.chat_id);
        } catch (err) {
            console.error("Error:", err);
            setMessages((prev) => prev.filter(msg => msg.message_id !== newMsg.message_id));
            setFeedbackMessage("That message wasn't sent.");
            setTimeout(() => setFeedbackMessage(null), 3000);
        }
    };

    const confirmDeleteMessage = (messageId) => {
        setMessageToDelete(messageId);
        setDeleteModalOpen(true);
    };

    const handleDeleteMessage = async () => {
        if (!messageToDelete) return;
        const prevMessages = messages;
        setMessages(prev => prev.filter(msg => msg.message_id !== messageToDelete));
        try {
            const { error } = await supabase.from('Messages').delete().eq('message_id', messageToDelete);
            if (error) {
                console.error("Error deleting message:", error);
                setMessages(prevMessages);
                setFeedbackMessage("Failed to delete message.");
                setTimeout(() => setFeedbackMessage(null), 3000);
            }
        } catch (err) {
            console.error("Error:", err);
            setMessages(prevMessages);
            setFeedbackMessage("Failed to delete message.");
            setTimeout(() => setFeedbackMessage(null), 3000);
        } finally {
            setDeleteModalOpen(false);
            setMessageToDelete(null);
        }
    };

    const handleFileChange = async (e) => {
        if (!chatSession) return;
        const file = e.target.files[0];
        if (!file) return;
        if (file.size > 200 * 1024 * 1024) {
            console.error("File exceeds 200MB limit.");
            return;
        }
        const fileName = file.name;
        const filePath = `chat_media/${chatSession.chat_id}/${fileName}`;
        const { error } = await supabase.storage
            .from('chat_media')
            .upload(filePath, file, { cacheControl: '3600', upsert: false });
        if (error) {
            console.error("Error uploading file:", error);
            return;
        }
        const { publicURL, error: urlError } = supabase.storage
            .from('chat_media')
            .getPublicUrl(filePath);
        if (urlError || !publicURL) {
            console.error("Error getting public URL:", urlError);
            return;
        }
        try {
            const now = new Date().toISOString();
            const receiverId = chatSession.participant_1 === adminId ? chatSession.participant_2 : chatSession.participant_1;
            const newMsg = {
                message_id: `${Date.now()}_${Math.random().toString(36).substring(2, 15)}`,
                chat_id: chatSession.chat_id,
                sender_id: adminId,
                receiver_id: receiverId,
                message: "",
                attachment_url: publicURL,
                status: "sent",
                read_status: false,
                created_at: now
            };
            setMessages((prev) => [...prev, newMsg]);
            scrollToBottom();
            const { error: msgError } = await supabase.from('Messages').insert([newMsg]);
            if (msgError) {
                console.error("Error sending attachment message:", msgError);
                setMessages((prev) => prev.filter(msg => msg.message_id !== newMsg.message_id));
                setFeedbackMessage("That message wasn't sent.");
                setTimeout(() => setFeedbackMessage(null), 3000);
            }
        } catch (err) {
            console.error("Error:", err);
            setFeedbackMessage("That message wasn't sent.");
            setTimeout(() => setFeedbackMessage(null), 3000);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    if (!chatSession) {
        return (
            <div className="flex-1 p-4">
                <p className="text-gray-500">Select a chat to start messaging.</p>
            </div>
        );
    }

    const otherId = getOtherParticipantId(chatSession);

    return (
        <div className="flex flex-col h-full bg-gray-50 p-6 relative">
            <div className="border-b pb-4 mb-4">
                <h2 className="text-2xl font-bold text-gray-800">
                    Chat with {otherParticipantName} ({otherId})
                </h2>
            </div>
            <div className="flex-1 overflow-auto mb-4 space-y-3">
                {messages
                    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                    .map((msg) => (
                        <div
                            key={msg.message_id}
                            className={`relative p-3 rounded-lg shadow-sm max-w-md break-words transition-all duration-200 ${
                                msg.sender_id === adminId
                                    ? "bg-blue-100 text-gray-900 self-end ml-auto"
                                    : "bg-gray-100 text-gray-900 self-start border border-gray-200"
                            }`}
                        >
                            {msg.attachment_url && (
                                <img src={msg.attachment_url} alt="attachment" className="w-full h-auto mb-2 rounded" />
                            )}
                            {msg.message && <p className="whitespace-pre-wrap">{msg.message}</p>}
                            <span className="text-xs text-gray-500 block text-right mt-1">
                {new Date(msg.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </span>
                            {msg.sender_id === adminId && (
                                <button
                                    onClick={() => confirmDeleteMessage(msg.message_id)}
                                    className="absolute top-1 right-1 p-1 rounded-full text-gray-500 opacity-0 hover:opacity-100 transition-opacity duration-200"
                                    title="Delete Message"
                                >
                                    <FaTrash className="h-4 w-4" />
                                </button>
                            )}
                        </div>
                    ))}
                <div ref={messagesEndRef} />
            </div>
            {feedbackMessage && (
                <div className="mb-4 p-3 bg-red-100 text-red-700 rounded transition-opacity duration-200">
                    {feedbackMessage}
                </div>
            )}
            <div className="flex items-center space-x-3">
                <button
                    className="p-2 rounded-full bg-blue-100 hover:bg-blue-200 transition"
                    onClick={() => fileInputRef.current.click()}
                    aria-label="Attach File"
                >
                    <FaPaperclip className="h-6 w-6 text-blue-600" />
                </button>
                <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleFileChange}
                />
                <textarea
                    className="flex-1 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 resize-none transition"
                    placeholder="Type your message..."
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    rows={1}
                />
                <button
                    onClick={handleSendMessage}
                    className="bg-blue-200 hover:bg-blue-300 text-gray-900 px-5 py-3 rounded-lg transition-colors duration-150"
                >
                    Send
                </button>
            </div>

            {/* Delete Confirmation Modal */}
            {deleteModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="bg-white rounded-lg shadow-lg z-10 p-6 max-w-sm w-full mx-4 animate-fadeIn">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Confirm Deletion</h3>
                        <p className="text-gray-700 mb-6">Are you sure you want to delete this message?</p>
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={() => {
                                    setDeleteModalOpen(false);
                                    setMessageToDelete(null);
                                }}
                                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDeleteMessage}
                                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatRoom;
