import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

const AddPlanModal = ({
                          closeAddModal,
                          addPlanData,
                          setAddPlanData,
                          handleAddPlan,
                          isAdding,
                      }) => {
    const [errors, setErrors] = useState({});

    // Validate required fields
    const validateFields = () => {
        const newErrors = {};
        if (!addPlanData.plan_name || addPlanData.plan_name.trim() === '') {
            newErrors.plan_name = 'Plan Name is required';
        }
        if (!addPlanData.plan_description || addPlanData.plan_description.trim() === '') {
            newErrors.plan_description = 'Plan Description is required';
        }
        if (!addPlanData.subscription_fee || isNaN(addPlanData.subscription_fee)) {
            newErrors.subscription_fee = 'Subscription Fee is required';
        }
        if (!addPlanData.subscription_renewal_type) {
            newErrors.subscription_renewal_type = 'Renewal Type is required';
        }
        // Optionally, validate IAP IDs if needed.
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Local handler to validate, then call parent's handleAddPlan
    const handleSave = () => {
        if (validateFields()) {
            handleAddPlan();
        }
    };

    // Handle input changes for both text and select fields.
    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddPlanData((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg max-w-xl w-full relative">
                <button onClick={closeAddModal} className="absolute top-2 right-2 text-gray-500">
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">Add New Subscription Plan</h2>

                <div className="grid grid-cols-2 gap-4">
                    {/* Plan Name */}
                    <div>
                        <label className="block font-semibold mb-1">
                            Plan Name<span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="plan_name"
                            value={addPlanData.plan_name}
                            onChange={handleChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.plan_name && <p className="text-red-500 text-sm">{errors.plan_name}</p>}
                    </div>

                    {/* Plan Description */}
                    <div>
                        <label className="block font-semibold mb-1">
                            Plan Description<span className="text-red-500">*</span>
                        </label>
                        <textarea
                            name="plan_description"
                            value={addPlanData.plan_description}
                            onChange={handleChange}
                            rows={3}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.plan_description && <p className="text-red-500 text-sm">{errors.plan_description}</p>}
                    </div>

                    {/* Subscription Fee */}
                    <div>
                        <label className="block font-semibold mb-1">
                            Subscription Fee<span className="text-red-500">*</span>
                        </label>
                        <input
                            type="number"
                            name="subscription_fee"
                            value={addPlanData.subscription_fee}
                            onChange={handleChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.subscription_fee && <p className="text-red-500 text-sm">{errors.subscription_fee}</p>}
                    </div>

                    {/* Renewal Type */}
                    <div>
                        <label className="block font-semibold mb-1">
                            Renewal Type<span className="text-red-500">*</span>
                        </label>
                        <select
                            name="subscription_renewal_type"
                            value={addPlanData.subscription_renewal_type}
                            onChange={handleChange}
                            className="border border-gray-300 rounded w-full p-2"
                        >
                            <option value="">Select Renewal Type</option>
                            <option value="monthly">Monthly</option>
                            <option value="yearly">Yearly</option>
                        </select>
                        {errors.subscription_renewal_type && (
                            <p className="text-red-500 text-sm">{errors.subscription_renewal_type}</p>
                        )}
                    </div>

                    {/* IAP Apple ID (optional) */}
                    <div>
                        <label className="block font-semibold mb-1">IAP Apple ID</label>
                        <input
                            type="text"
                            name="IAP_id_apple"
                            value={addPlanData.IAP_id_apple || ''}
                            onChange={handleChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    {/* IAP Google ID (optional) */}
                    <div>
                        <label className="block font-semibold mb-1">IAP Google ID</label>
                        <input
                            type="text"
                            name="IAP_id_google"
                            value={addPlanData.IAP_id_google || ''}
                            onChange={handleChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        onClick={handleSave}
                        className="bg-blue-500 text-white px-4 py-2 rounded shadow"
                        disabled={isAdding}
                    >
                        {isAdding ? <FaSpinner className="animate-spin" size={16} /> : "Create Plan"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddPlanModal;
