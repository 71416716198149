import React, { useState } from 'react';

const DeleteCustomerModal = ({
                                 selectedCustomer,
                                 deleteConfirmInput,
                                 setDeleteConfirmInput,
                                 closeDeleteModal,
                                 handleConfirmDelete,
                             }) => {
    const [errorMessage, setErrorMessage] = useState('');

    if (!selectedCustomer) return null;

    const userIdToDelete = selectedCustomer.user_id || '';

    const handleDeleteClick = () => {
        if (deleteConfirmInput !== userIdToDelete) {
            setErrorMessage('The entered user ID does not match. Please try again.');
        } else {
            setErrorMessage('');
            handleConfirmDelete();
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg max-w-md w-full relative">
                <button
                    onClick={closeDeleteModal}
                    className="absolute top-2 right-2 text-gray-500"
                >
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">Delete Customer</h2>
                <p>
                    Are you sure you want to delete this customer? This action is
                    <strong> irreversible</strong>. To confirm, please type the user ID{' '}
                    <strong>{userIdToDelete}</strong>:
                </p>
                <input
                    type="text"
                    value={deleteConfirmInput}
                    onChange={(e) => setDeleteConfirmInput(e.target.value)}
                    className="border border-gray-300 rounded w-full p-2 mt-2 mb-4"
                />
                {errorMessage && (
                    <p className="text-red-500 mb-4">{errorMessage}</p>
                )}
                <div className="flex justify-center space-x-2">
                    <button
                        onClick={closeDeleteModal}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleDeleteClick}
                        className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteCustomerModal;