// src/redux/actions/appConfigActions.js
import {
    FETCH_APPCONFIGS_SUCCESS,
    FETCH_APPCONFIGS_ERROR,
    UPDATE_APPCONFIG_SUCCESS,
    UPDATE_APPCONFIG_ERROR,
    ADD_APPCONFIG_SUCCESS,
    ADD_APPCONFIG_ERROR,
} from "../types";
import { getAllAppConfigs, updateAppConfigAdmin, addAppConfigAdmin } from "../../api/api";

export const fetchAppConfigs = () => async (dispatch) => {
    try {
        const configs = await getAllAppConfigs();
        dispatch({ type: FETCH_APPCONFIGS_SUCCESS, payload: configs });
    } catch (error) {
        dispatch({ type: FETCH_APPCONFIGS_ERROR, payload: error.message });
    }
};

export const updateAppConfig = (configId, updates) => async (dispatch) => {
    try {
        const updatedResponse = await updateAppConfigAdmin(configId, updates);
        // The update endpoint returns { message, data } where data is an array.
        dispatch({ type: UPDATE_APPCONFIG_SUCCESS, payload: updatedResponse.data[0] });
    } catch (error) {
        dispatch({ type: UPDATE_APPCONFIG_ERROR, payload: error.message });
    }
};

export const addAppConfig = (configData) => async (dispatch) => {
    try {
        const addedResponse = await addAppConfigAdmin(configData);
        dispatch({ type: ADD_APPCONFIG_SUCCESS, payload: addedResponse.data[0] });
    } catch (error) {
        dispatch({ type: ADD_APPCONFIG_ERROR, payload: error.message });
    }
};
