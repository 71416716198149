import React, { useState, useMemo, useEffect } from 'react';
import { FaChevronRight, FaChevronDown, FaSpinner, FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Advanced inline Pagination component
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const [jumpPage, setJumpPage] = useState(currentPage);

    useEffect(() => {
        setJumpPage(currentPage);
    }, [currentPage]);

    if (totalPages <= 1) return null;

    // Generate page numbers with ellipses for large page counts
    const getPageNumbers = (current, total) => {
        const delta = 2;
        const pages = [];
        pages.push(1);

        if (current - delta > 2) {
            pages.push("left-ellipsis");
        } else {
            for (let i = 2; i < Math.max(2, current - delta); i++) {
                pages.push(i);
            }
        }

        for (let i = Math.max(2, current - delta); i <= Math.min(total - 1, current + delta); i++) {
            pages.push(i);
        }

        if (current + delta < total - 1) {
            pages.push("right-ellipsis");
        } else {
            for (let i = Math.min(total - 1, current + delta) + 1; i < total; i++) {
                pages.push(i);
            }
        }

        if (total > 1) {
            pages.push(total);
        }

        return pages;
    };

    const pages = getPageNumbers(currentPage, totalPages);

    const handleJumpSubmit = (e) => {
        e.preventDefault();
        const page = Number(jumpPage);
        if (page >= 1 && page <= totalPages && page !== currentPage) {
            onPageChange(page);
        }
    };

    return (
        <div className="flex justify-center mt-6">
            <div className="flex items-center space-x-2">
                <button
                    onClick={() => onPageChange(1)}
                    disabled={currentPage === 1}
                    title="First Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleDoubleLeft size={18} />
                </button>
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    title="Previous Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleLeft size={18} />
                </button>

                {pages.map((page, idx) =>
                    page === "left-ellipsis" || page === "right-ellipsis" ? (
                        <span key={idx} className="px-3 py-2">
                            &hellip;
                        </span>
                    ) : (
                        <button
                            key={page}
                            onClick={() => onPageChange(page)}
                            className={`px-4 py-2 rounded transition-colors ${
                                page === currentPage
                                    ? "bg-blue-500 text-white font-semibold"
                                    : "bg-gray-200 hover:bg-gray-300"
                            }`}
                        >
                            {page}
                        </button>
                    )
                )}

                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    title="Next Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleRight size={18} />
                </button>
                <button
                    onClick={() => onPageChange(totalPages)}
                    disabled={currentPage === totalPages}
                    title="Last Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleDoubleRight size={18} />
                </button>

                <form onSubmit={handleJumpSubmit} className="flex items-center border border-gray-300 rounded overflow-hidden">
                    <input
                        type="number"
                        min="1"
                        max={totalPages}
                        value={jumpPage}
                        onChange={(e) => setJumpPage(e.target.value)}
                        placeholder="Page"
                        className="w-16 px-2 py-2 text-center outline-none"
                    />
                    <button
                        type="submit"
                        className="px-3 py-2 bg-blue-500 text-white hover:bg-blue-600 transition-colors"
                        title="Go to Page"
                    >
                        <FaArrowRight size={14} />
                    </button>
                </form>
            </div>
        </div>
    );
};

const StoreLocationsTable = ({ filteredLocations, handleEditClick, handleToggleStatus, loading }) => {
    // Expanded rows state.
    const [expandedRows, setExpandedRows] = useState([]);
    // Sorting state.
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    // Pagination state.
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    // Handler to toggle row expansion.
    const handleRowExpand = (locationId) => {
        setExpandedRows((prev) =>
            prev.includes(locationId)
                ? prev.filter((id) => id !== locationId)
                : [...prev, locationId]
        );
    };

    // Handler to toggle sorting.
    const handleSort = (key) => {
        setSortConfig((prev) => {
            if (prev.key === key) {
                return { key, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
            }
            return { key, direction: 'asc' };
        });
    };

    // Sort the locations based on sortConfig.
    const sortedLocations = useMemo(() => {
        const { key, direction } = sortConfig;
        const items = [...(filteredLocations || [])];
        if (key) {
            items.sort((a, b) => {
                let aVal = '';
                let bVal = '';
                switch (key) {
                    case 'locationId':
                        aVal = a.location_id?.toLowerCase() || '';
                        bVal = b.location_id?.toLowerCase() || '';
                        break;
                    case 'vendorId':
                        aVal = a.vendor_id?.toLowerCase() || '';
                        bVal = b.vendor_id?.toLowerCase() || '';
                        break;
                    case 'address':
                        aVal = a.address?.toLowerCase() || '';
                        bVal = b.address?.toLowerCase() || '';
                        break;
                    case 'active':
                        aVal = a.active ? 'active' : 'inactive';
                        bVal = b.active ? 'active' : 'inactive';
                        break;
                    default:
                        return 0;
                }
                if (aVal < bVal) return direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return items;
    }, [filteredLocations, sortConfig]);

    // Get only locations for the current page.
    const currentLocations = useMemo(
        () => sortedLocations.slice((currentPage - 1) * pageSize, currentPage * pageSize),
        [sortedLocations, currentPage]
    );

    const totalPages = Math.ceil(sortedLocations.length / pageSize);

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full text-left">
                {/* TABLE HEADER */}
                <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th
                        onClick={() => handleSort('locationId')}
                        className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                    >
                        Location ID
                        {sortConfig.key === 'locationId' && (
                            <span className="ml-1">
                                    {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                </span>
                        )}
                    </th>
                    <th
                        onClick={() => handleSort('vendorId')}
                        className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                    >
                        Vendor ID
                        {sortConfig.key === 'vendorId' && (
                            <span className="ml-1">
                                    {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                </span>
                        )}
                    </th>
                    <th
                        onClick={() => handleSort('address')}
                        className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                    >
                        Address
                        {sortConfig.key === 'address' && (
                            <span className="ml-1">
                                    {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                </span>
                        )}
                    </th>
                    <th
                        onClick={() => handleSort('active')}
                        className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                    >
                        Status
                        {sortConfig.key === 'active' && (
                            <span className="ml-1">
                                    {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                </span>
                        )}
                    </th>
                    <th className="py-3 px-6 font-semibold text-center">Actions</th>
                </tr>
                </thead>
                {/* TABLE BODY */}
                <tbody className="text-gray-700 text-sm font-light">
                {loading ? (
                    <tr>
                        <td colSpan={5} className="text-center py-6">
                            <div className="flex items-center justify-center space-x-2">
                                <FaSpinner className="animate-spin" size={24} />
                                <span>Loading store locations, please wait...</span>
                            </div>
                        </td>
                    </tr>
                ) : currentLocations.length > 0 ? (
                    currentLocations.map((loc) => {
                        const isExpanded = expandedRows.includes(loc.location_id);
                        const isActive = loc.active;
                        return (
                            <React.Fragment key={loc.location_id}>
                                <tr
                                    className={`border-b border-gray-200 ${
                                        isActive ? 'bg-green-50' : 'bg-red-50'
                                    } hover:bg-gray-100`}
                                >
                                    <td className="py-3 px-6 text-center font-medium text-blue-600">
                                        <div className="flex items-center justify-center space-x-2">
                                            <button
                                                onClick={() => handleRowExpand(loc.location_id)}
                                                className="focus:outline-none"
                                                title={isExpanded ? 'Collapse details' : 'Expand details'}
                                            >
                                                {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                                            </button>
                                            <span>{loc.location_id}</span>
                                        </div>
                                    </td>
                                    <td className="py-3 px-6 text-center font-bold">
                                        {loc.vendor_id}
                                    </td>
                                    <td className="py-3 px-6 text-center font-bold">
                                        {loc.address || "(No address)"}
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        {isActive ? (
                                            <span className="bg-green-200 text-green-800 px-2 py-1 rounded">
                                                    active
                                                </span>
                                        ) : (
                                            <span className="bg-red-200 text-red-800 px-2 py-1 rounded">
                                                    inactive
                                                </span>
                                        )}
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <div className="flex items-center justify-center space-x-6">
                                            <button
                                                onClick={() => handleEditClick(loc)}
                                                className="text-blue-500 font-medium hover:opacity-80 transition-opacity"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() => handleToggleStatus(loc)}
                                                className={`font-medium px-4 py-2 rounded transition-opacity hover:opacity-80 w-28 text-center ${
                                                    isActive
                                                        ? 'bg-red-500 text-white'
                                                        : 'bg-blue-500 text-white'
                                                }`}
                                            >
                                                {isActive ? 'Deactivate' : 'Activate'}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                {isExpanded && (
                                    <tr>
                                        <td colSpan={5}>
                                            <div className="bg-white border border-gray-200 rounded-md shadow-sm p-6">
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                    {/* LEFT: Store details + Vendor info */}
                                                    <div className="space-y-4">
                                                        <h3 className="text-lg font-semibold">Store Details</h3>
                                                        <p className="text-sm text-gray-700">
                                                            <strong>Store Manager:</strong> {loc.store_manager || 'N/A'}
                                                        </p>
                                                        <p className="text-sm text-gray-700">
                                                            <strong>Phone:</strong> {loc.mobile_phone || 'N/A'}
                                                        </p>
                                                        <p className="text-sm text-gray-700">
                                                            <strong>Email:</strong> {loc.store_email || 'N/A'}
                                                        </p>
                                                        <p className="text-sm text-gray-700">
                                                            <strong>Street:</strong> {loc.street || 'N/A'}
                                                        </p>
                                                        <p className="text-sm text-gray-700">
                                                            <strong>City / State:</strong> {loc.city}, {loc.state}
                                                        </p>
                                                        <p className="text-sm text-gray-700">
                                                            <strong>Country / Zip:</strong> {loc.country}, {loc.zipcode}
                                                        </p>
                                                    </div>
                                                    {/* RIGHT: Deals associated with this store */}
                                                    <div className="space-y-4">
                                                        <h3 className="text-lg font-semibold">Deals for this Location</h3>
                                                        {loc.deals && loc.deals.length > 0 ? (
                                                            <div>
                                                                <div className="flex flex-wrap gap-4">
                                                                    {loc.deals.slice(0, 10).map((deal) => (
                                                                        <Link
                                                                            key={deal.deal_id}
                                                                            to={`/deals?dealId=${deal.deal_id}`}
                                                                            className="border p-3 rounded-md shadow-sm hover:bg-gray-50 cursor-pointer"
                                                                        >
                                                                            <p className="text-xs text-gray-600">
                                                                                Deal ID: {deal.deal_id}
                                                                            </p>
                                                                            <p className="text-sm font-medium">{deal.deal_title}</p>
                                                                        </Link>
                                                                    ))}
                                                                </div>
                                                                {loc.deals.length > 10 && (
                                                                    <div className="mt-2">
                                                                        <Link
                                                                            to={`/deals?storeLocationId=${loc.location_id}`}
                                                                            className="text-blue-600 hover:underline"
                                                                        >
                                                                            See All Deals
                                                                        </Link>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <p className="text-sm text-gray-700">
                                                                No deals associated with this store location.
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={5} className="text-center py-6">
                            <span>No store locations found.</span>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            {sortedLocations.length > pageSize && (
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
            )}
        </div>
    );
};

export default StoreLocationsTable;
