import React, { useState, useMemo } from "react";
import {
    FaChevronRight,
    FaChevronDown,
    FaSpinner,
    FaArrowRight,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { regenerateCoupon } from "../../redux/actions/couponActions";
import Pagination from "../../components/Pagination";

// Helper to determine payment method
const getPaymentMethod = (coupon) => {
    if (coupon.stripe_payment && Number(coupon.stripe_payment) > 0)
        return "Stripe";
    if (coupon.wallet_payment && Number(coupon.wallet_payment) > 0)
        return "Wallet";
    if (typeof coupon.isFirst === "boolean") {
        return coupon.isFirst
            ? "Subscription (1st coupon free)"
            : "Subscription (2nd coupon 5%)";
    }
    return "N/A";
};

// Helper to calculate customer value realized per coupon and wah revenue generated.
// Calculation is applied only if the coupon status is redeemed.
const calculateCouponMetrics = (coupon) => {
    const isRedeemed =
        coupon.status && coupon.status.toLowerCase() === "redeemed";
    const regularPrice = Number(coupon.Deals?.regular_price || 0);
    const wahDealPrice = Number(coupon.Deals?.wah_price || 0);
    const wahFee = wahDealPrice * 0.10;
    const stripeFee = wahDealPrice * 0.029 + 0.30;
    let customerValue = 0;
    let wahRevenue = 0;
    if (isRedeemed) {
        customerValue = regularPrice - (wahDealPrice + wahFee + stripeFee);
        wahRevenue = wahFee;
    }
    return { customerValue, wahRevenue, wahFee, stripeFee };
};

// Mapping for status badge colors
const badgeMapping = {
    active: { bg: "bg-green-200", text: "text-green-800" },
    generated: { bg: "bg-blue-200", text: "text-blue-800" },
    redeemed: { bg: "bg-purple-200", text: "text-purple-800" },
    cancelled: { bg: "bg-red-200", text: "text-red-800" },
    expired: { bg: "bg-red-200", text: "text-red-800" },
    default: { bg: "bg-gray-200", text: "text-gray-800" },
};

const CouponTable = ({ filteredCoupons, loading }) => {
    const dispatch = useDispatch();
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [expandedRows, setExpandedRows] = useState({});

    // Sorting logic
    const sortedCoupons = useMemo(() => {
        if (!filteredCoupons) return [];
        const items = [...filteredCoupons];
        const { key, direction } = sortConfig;
        if (key) {
            items.sort((a, b) => {
                let aVal, bVal;
                switch (key) {
                    case "couponId":
                        aVal = a.coupon_id?.toLowerCase() || "";
                        bVal = b.coupon_id?.toLowerCase() || "";
                        break;
                    case "customerName":
                        aVal = `${a.Customers?.first_name || ""} ${a.Customers?.last_name || ""}`
                            .trim()
                            .toLowerCase();
                        bVal = `${b.Customers?.first_name || ""} ${b.Customers?.last_name || ""}`
                            .trim()
                            .toLowerCase();
                        break;
                    case "dealTitle":
                        aVal = a.Deals?.deal_title?.toLowerCase() || "";
                        bVal = b.Deals?.deal_title?.toLowerCase() || "";
                        break;
                    case "vendorName":
                        aVal = a.Deals?.vendor_name?.toLowerCase() || "";
                        bVal = b.Deals?.vendor_name?.toLowerCase() || "";
                        break;
                    case "status":
                        aVal = a.status?.toLowerCase() || "";
                        bVal = b.status?.toLowerCase() || "";
                        break;
                    case "paymentMethod":
                        aVal = getPaymentMethod(a).toLowerCase();
                        bVal = getPaymentMethod(b).toLowerCase();
                        break;
                    case "customerValue":
                        aVal = calculateCouponMetrics(a).customerValue;
                        bVal = calculateCouponMetrics(b).customerValue;
                        break;
                    case "wahRevenue":
                        aVal = calculateCouponMetrics(a).wahRevenue;
                        bVal = calculateCouponMetrics(b).wahRevenue;
                        break;
                    default:
                        return 0;
                }
                if (aVal < bVal) return direction === "asc" ? -1 : 1;
                if (aVal > bVal) return direction === "asc" ? 1 : -1;
                return 0;
            });
        }
        return items;
    }, [filteredCoupons, sortConfig]);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const totalPages = Math.ceil(sortedCoupons.length / pageSize);
    const currentCoupons = useMemo(
        () =>
            sortedCoupons.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
            ),
        [sortedCoupons, currentPage]
    );

    const toggleExpand = (couponId) => {
        setExpandedRows((prev) => ({
            ...prev,
            [couponId]: !prev[couponId],
        }));
    };

    // Handle regeneration of a coupon's QR code
    const handleRegenerate = (couponId) => {
        dispatch(regenerateCoupon(couponId));
    };

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="w-full overflow-x-auto">
                <table className="min-w-full text-left table-auto">
                    <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th
                            onClick={() =>
                                setSortConfig({
                                    key: "couponId",
                                    direction:
                                        sortConfig.direction === "asc" ? "desc" : "asc",
                                })
                            }
                            className="py-3 px-4 text-center cursor-pointer select-none"
                        >
                            Coupon ID{" "}
                            {sortConfig.key === "couponId" && (
                                <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                            )}
                        </th>
                        <th
                            onClick={() =>
                                setSortConfig({
                                    key: "customerName",
                                    direction:
                                        sortConfig.direction === "asc" ? "desc" : "asc",
                                })
                            }
                            className="py-3 px-4 text-center cursor-pointer select-none"
                        >
                            Customer Name{" "}
                            {sortConfig.key === "customerName" && (
                                <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                            )}
                        </th>
                        <th
                            onClick={() =>
                                setSortConfig({
                                    key: "status",
                                    direction:
                                        sortConfig.direction === "asc" ? "desc" : "asc",
                                })
                            }
                            className="py-3 px-4 text-center cursor-pointer select-none"
                        >
                            Coupon Status{" "}
                            {sortConfig.key === "status" && (
                                <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                            )}
                        </th>
                        <th className="py-3 px-4 text-center">Coupon QR Code</th>
                        <th
                            onClick={() =>
                                setSortConfig({
                                    key: "dealTitle",
                                    direction:
                                        sortConfig.direction === "asc" ? "desc" : "asc",
                                })
                            }
                            className="py-3 px-4 text-center cursor-pointer select-none"
                        >
                            Deal Title{" "}
                            {sortConfig.key === "dealTitle" && (
                                <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                            )}
                        </th>
                        <th
                            onClick={() =>
                                setSortConfig({
                                    key: "vendorName",
                                    direction:
                                        sortConfig.direction === "asc" ? "desc" : "asc",
                                })
                            }
                            className="py-3 px-4 text-center cursor-pointer select-none"
                        >
                            Vendor Name{" "}
                            {sortConfig.key === "vendorName" && (
                                <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                            )}
                        </th>
                        <th
                            onClick={() =>
                                setSortConfig({
                                    key: "paymentMethod",
                                    direction:
                                        sortConfig.direction === "asc" ? "desc" : "asc",
                                })
                            }
                            className="py-3 px-4 text-center cursor-pointer select-none"
                        >
                            Payment Method{" "}
                            {sortConfig.key === "paymentMethod" && (
                                <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                            )}
                        </th>
                        <th
                            onClick={() =>
                                setSortConfig({
                                    key: "customerValue",
                                    direction:
                                        sortConfig.direction === "asc" ? "desc" : "asc",
                                })
                            }
                            className="py-3 px-4 text-center cursor-pointer select-none"
                        >
                            Customer Value Realized{" "}
                            {sortConfig.key === "customerValue" && (
                                <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                            )}
                        </th>
                        <th
                            onClick={() =>
                                setSortConfig({
                                    key: "wahRevenue",
                                    direction:
                                        sortConfig.direction === "asc" ? "desc" : "asc",
                                })
                            }
                            className="py-3 px-4 text-center cursor-pointer select-none"
                        >
                            Wah Revenue Generated (Coupon Sale){" "}
                            {sortConfig.key === "wahRevenue" && (
                                <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                            )}
                        </th>
                        <th className="py-3 px-4 text-center">Actions</th>
                    </tr>
                    </thead>
                    <tbody className="text-gray-700 text-sm font-light">
                    {loading ? (
                        <tr>
                            <td colSpan={10} className="text-center py-6">
                                <div className="flex items-center justify-center space-x-2">
                                    <FaSpinner className="animate-spin inline-block" size={24} />
                                    <span>Loading coupons, please wait...</span>
                                </div>
                            </td>
                        </tr>
                    ) : currentCoupons.length > 0 ? (
                        currentCoupons.map((coupon) => {
                            const expanded = expandedRows[coupon.coupon_id] || false;
                            const { customerValue, wahRevenue, wahFee, stripeFee } =
                                calculateCouponMetrics(coupon);
                            const paymentMethod = getPaymentMethod(coupon);
                            return (
                                <React.Fragment key={coupon.coupon_id}>
                                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                                        <td className="py-3 px-4 text-center font-medium text-blue-600">
                                            <div className="flex items-center justify-center space-x-2">
                                                <button
                                                    onClick={() => toggleExpand(coupon.coupon_id)}
                                                    className="focus:outline-none"
                                                    title={
                                                        expanded ? "Collapse details" : "Expand details"
                                                    }
                                                >
                                                    {expanded ? <FaChevronDown /> : <FaChevronRight />}
                                                </button>
                                                <span>{coupon.coupon_id}</span>
                                            </div>
                                        </td>
                                        <td className="py-3 px-4 text-center font-bold">
                                            {`${coupon.Customers?.first_name || ""} ${
                                                coupon.Customers?.last_name || ""
                                            }`.trim() || "N/A"}
                                        </td>
                                        <td className="py-3 px-4 text-center">
                        <span
                            className={`px-2 py-1 rounded ${
                                badgeMapping[coupon.status?.toLowerCase()]
                                    ? `${badgeMapping[coupon.status.toLowerCase()].bg} ${badgeMapping[coupon.status.toLowerCase()].text}`
                                    : "bg-gray-200 text-gray-800"
                            }`}
                        >
                          {coupon.status?.toLowerCase() || "unknown"}
                        </span>
                                        </td>
                                        <td className="py-3 px-4 text-center font-bold">
                                            {coupon.qr_code || "N/A"}
                                        </td>
                                        <td className="py-3 px-4 text-center font-bold">
                                            {coupon.Deals?.deal_title || "N/A"}
                                        </td>
                                        <td className="py-3 px-4 text-center font-bold">
                                            {coupon.Deals?.vendor_name || "N/A"}
                                        </td>
                                        <td className="py-3 px-4 text-center">
                                            {paymentMethod}
                                        </td>
                                        <td className="py-3 px-4 text-center">
                                            {coupon.status?.toLowerCase() === "redeemed"
                                                ? `$${Number(customerValue || 0).toFixed(2)}`
                                                : "$0.00"}
                                        </td>
                                        <td className="py-3 px-4 text-center">
                                            {coupon.status?.toLowerCase() === "redeemed"
                                                ? `$${Number(wahRevenue || 0).toFixed(2)}`
                                                : "$0.00"}
                                        </td>
                                        <td className="py-3 px-4 text-center whitespace-nowrap">
                                            {coupon.status?.toLowerCase() === "active" ? (
                                                <button
                                                    onClick={() => handleRegenerate(coupon.coupon_id)}
                                                    className="px-3 py-1 bg-orange-700 text-white rounded hover:bg-orange-800"
                                                >
                                                    Regenerate
                                                </button>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                    </tr>
                                    {expanded && (
                                        <tr className="bg-gray-50">
                                            <td colSpan={10} className="p-4">
                                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm">
                                                    <div>
                                                        <h3 className="font-semibold mb-1">Identifiers</h3>
                                                        <p>
                                                            <strong>Customer ID:</strong>{" "}
                                                            {coupon.customer_id || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Deal ID:</strong>{" "}
                                                            {coupon.deal_id || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Vendor ID:</strong>{" "}
                                                            {coupon.Deals?.vendor_id || "N/A"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h3 className="font-semibold mb-1">
                                                            Coupon Timing
                                                        </h3>
                                                        <p>
                                                            <strong>Created At:</strong>{" "}
                                                            {coupon.created_at
                                                                ? new Date(coupon.created_at).toLocaleString()
                                                                : "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Updated At:</strong>{" "}
                                                            {coupon.updated_at
                                                                ? new Date(coupon.updated_at).toLocaleString()
                                                                : "N/A"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h3 className="font-semibold mb-1">
                                                            Deal Pricing Details
                                                        </h3>
                                                        <p>
                                                            <strong>Regular Price:</strong>{" "}
                                                            {coupon.Deals?.regular_price
                                                                ? `$${Number(coupon.Deals.regular_price).toFixed(2)}`
                                                                : "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Wah Deal Price:</strong>{" "}
                                                            {coupon.Deals?.wah_price
                                                                ? `$${Number(coupon.Deals.wah_price).toFixed(2)}`
                                                                : "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Wah Fee:</strong>{" "}
                                                            {coupon.status?.toLowerCase() === "redeemed"
                                                                ? `$${Number(wahFee).toFixed(2)}`
                                                                : "$0.00"}
                                                        </p>
                                                        <p>
                                                            <strong>Stripe Fee:</strong>{" "}
                                                            {coupon.status?.toLowerCase() === "redeemed"
                                                                ? `$${Number(stripeFee).toFixed(2)}`
                                                                : "$0.00"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={10} className="text-center py-6">
                                <span>No coupons found.</span>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {totalPages > 1 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                />
            )}
        </div>
    );
};

export default CouponTable;
