import {
    FETCH_VENDOR_WALLETS_SUCCESS,
    FETCH_VENDOR_WALLETS_ERROR,
    FETCH_CUSTOMER_WALLETS_SUCCESS,
    FETCH_CUSTOMER_WALLETS_ERROR,
    UPDATE_WALLET_SUCCESS,
    UPDATE_WALLET_ERROR,
} from '../types';

const initialState = {
    vendorWallets: [],
    customerWallets: [],
    error: null,
    loading: false,
};

const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VENDOR_WALLETS_SUCCESS:
            return { ...state, vendorWallets: action.payload, error: null, loading: false };
        case FETCH_VENDOR_WALLETS_ERROR:
            return { ...state, vendorWallets: [], error: action.payload, loading: false };
        case FETCH_CUSTOMER_WALLETS_SUCCESS:
            return { ...state, customerWallets: action.payload, error: null, loading: false };
        case FETCH_CUSTOMER_WALLETS_ERROR:
            return { ...state, customerWallets: [], error: action.payload, loading: false };
        case UPDATE_WALLET_SUCCESS:
            return { ...state, error: null };
        case UPDATE_WALLET_ERROR:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default walletReducer;
