import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateAppConfig } from "../../redux/actions/appConfigActions";

// Function to simulate PDF upload and return URL
const uploadPDF = async (file) => {
    const uploadBaseUrl = process.env.REACT_APP_UPLOAD_URL; // e.g. "https://jcjqmhkgkjfmvfmlgzen.supabase.co/storage/v1/object/public/wah_policies"
    // In a real implementation, you would upload the file to your storage (e.g. Supabase Storage) and get the file URL.
    // Here we simulate the upload by returning the base URL appended with the file name.
    return `${uploadBaseUrl}/${file.name}`;
};

const EditAppConfigModal = ({ config, closeEditModal }) => {
    const dispatch = useDispatch();
    const [editedConfig, setEditedConfig] = useState({ ...config });
    const [notification, setNotification] = useState(null);
    const [saving, setSaving] = useState(false);

    // Handle regular input changes.
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setEditedConfig((prev) => ({ ...prev, [name]: newValue }));
    };

    // Handle file upload for PDF fields.
    const handleFileChange = async (e, fieldName) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const url = await uploadPDF(file);
                setEditedConfig((prev) => ({ ...prev, [fieldName]: url }));
            } catch (err) {
                console.error("Error uploading file:", err);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
        const updates = { ...editedConfig };
        // Remove non-updatable fields.
        delete updates.created_at;
        delete updates.updated_at;
        try {
            await dispatch(updateAppConfig(config.id, updates));
            setNotification("Configuration updated successfully.");
            closeEditModal();
        } catch (err) {
            setNotification("Error updating configuration.");
        }
        setSaving(false);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-lg max-h-[90vh] overflow-y-auto relative">
                <button onClick={closeEditModal} className="absolute top-2 right-2 text-gray-500">
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">Edit Configuration</h2>
                {notification && (
                    <div className="mb-4 p-2 bg-green-100 text-green-700 rounded">{notification}</div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="space-y-6">
                        {/* PDF Documents Section */}
                        <div className="border p-4 rounded">
                            <h3 className="text-lg font-semibold mb-4">PDF Documents</h3>
                            <div className="space-y-4">
                                {/* Wah Deal Terms & Conditions */}
                                <div className="border p-4 rounded">
                                    <h4 className="font-semibold mb-2">Wah Deal Terms &amp; Conditions</h4>
                                    {editedConfig.wah_deal_tc && editedConfig.wah_deal_tc.startsWith("http") ? (
                                        <a
                                            href={editedConfig.wah_deal_tc}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline block mb-2"
                                        >
                                            View Current PDF
                                        </a>
                                    ) : editedConfig.wah_deal_tc ? (
                                        <p className="mb-2">{editedConfig.wah_deal_tc}</p>
                                    ) : null}
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        onChange={(e) => handleFileChange(e, "wah_deal_tc")}
                                        className="w-full"
                                    />
                                </div>
                                {/* Vendor Terms & Conditions */}
                                <div className="border p-4 rounded">
                                    <h4 className="font-semibold mb-2">Vendor Terms &amp; Conditions</h4>
                                    {editedConfig.vendor_tc && editedConfig.vendor_tc.startsWith("http") ? (
                                        <a
                                            href={editedConfig.vendor_tc}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline block mb-2"
                                        >
                                            View Current PDF
                                        </a>
                                    ) : editedConfig.vendor_tc ? (
                                        <p className="mb-2">{editedConfig.vendor_tc}</p>
                                    ) : null}
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        onChange={(e) => handleFileChange(e, "vendor_tc")}
                                        className="w-full"
                                    />
                                </div>
                                {/* Customer Terms & Conditions */}
                                <div className="border p-4 rounded">
                                    <h4 className="font-semibold mb-2">Customer Terms &amp; Conditions</h4>
                                    {editedConfig.customer_tc && editedConfig.customer_tc.startsWith("http") ? (
                                        <a
                                            href={editedConfig.customer_tc}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline block mb-2"
                                        >
                                            View Current PDF
                                        </a>
                                    ) : editedConfig.customer_tc ? (
                                        <p className="mb-2">{editedConfig.customer_tc}</p>
                                    ) : null}
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        onChange={(e) => handleFileChange(e, "customer_tc")}
                                        className="w-full"
                                    />
                                </div>
                                {/* Vendor Privacy Policy */}
                                <div className="border p-4 rounded">
                                    <h4 className="font-semibold mb-2">Vendor Privacy Policy</h4>
                                    {editedConfig.vendor_pp && editedConfig.vendor_pp.startsWith("http") ? (
                                        <a
                                            href={editedConfig.vendor_pp}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline block mb-2"
                                        >
                                            View Current PDF
                                        </a>
                                    ) : editedConfig.vendor_pp ? (
                                        <p className="mb-2">{editedConfig.vendor_pp}</p>
                                    ) : null}
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        onChange={(e) => handleFileChange(e, "vendor_pp")}
                                        className="w-full"
                                    />
                                </div>
                                {/* Customer Privacy Policy */}
                                <div className="border p-4 rounded">
                                    <h4 className="font-semibold mb-2">Customer Privacy Policy</h4>
                                    {editedConfig.customer_pp && editedConfig.customer_pp.startsWith("http") ? (
                                        <a
                                            href={editedConfig.customer_pp}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline block mb-2"
                                        >
                                            View Current PDF
                                        </a>
                                    ) : editedConfig.customer_pp ? (
                                        <p className="mb-2">{editedConfig.customer_pp}</p>
                                    ) : null}
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        onChange={(e) => handleFileChange(e, "customer_pp")}
                                        className="w-full"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Basic Configuration Fields */}
                        <div>
                            <label className="block text-gray-700">App Version</label>
                            <input
                                type="text"
                                name="app_version"
                                value={editedConfig.app_version}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                                required
                            />
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                name="maintenance_mode"
                                checked={editedConfig.maintenance_mode}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <label className="text-gray-700">Maintenance Mode</label>
                        </div>
                        <div>
                            <label className="block text-gray-700">Maintenance Message</label>
                            <textarea
                                name="maintenance_message"
                                value={editedConfig.maintenance_message}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                                rows="2"
                            ></textarea>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                name="force_update"
                                checked={editedConfig.force_update}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <label className="text-gray-700">Force Update</label>
                        </div>
                        <div>
                            <label className="block text-gray-700">Force Update Message</label>
                            <textarea
                                name="forceupdatemessage"
                                value={editedConfig.forceupdatemessage}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                                rows="2"
                            ></textarea>
                        </div>
                        <div>
                            <label className="block text-gray-700">Vendor Free Max Deal Limit</label>
                            <input
                                type="text"
                                name="vendor_free_max_deal_limit"
                                value={editedConfig.vendor_free_max_deal_limit}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Vendor Paid Max Deal Limit</label>
                            <input
                                type="text"
                                name="vendor_paid_max_deal_limit"
                                value={editedConfig.vendor_paid_max_deal_limit}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Vendor Commission</label>
                            <input
                                type="number"
                                step="0.01"
                                name="vendor_commision"
                                value={editedConfig.vendor_commision}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Customer Commission</label>
                            <input
                                type="number"
                                step="0.01"
                                name="customer_commision"
                                value={editedConfig.customer_commision}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>

                        {/* Additional Configuration Fields */}
                        <div>
                            <label className="block text-gray-700">Subscribed Customer Commission</label>
                            <input
                                type="number"
                                step="0.01"
                                name="subscribed_customer_commision"
                                value={editedConfig.subscribed_customer_commision || ""}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Stripe Charges</label>
                            <input
                                type="number"
                                step="0.01"
                                name="stripe_charges"
                                value={editedConfig.stripe_charges || ""}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Upsell Commission</label>
                            <input
                                type="number"
                                step="0.01"
                                name="upsell_commision"
                                value={editedConfig.upsell_commision || ""}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                name="isSubscription_enabled"
                                checked={editedConfig.isSubscription_enabled}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <label className="text-gray-700">Subscription Enabled</label>
                        </div>

                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={closeEditModal}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={saving}
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                            >
                                {saving ? "Saving..." : "Save Changes"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditAppConfigModal;
