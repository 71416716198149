import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DateFilterDropdown from "./DateFilterDropdown";
import { getDateRangeFromFilter } from "./dateUtils";
// import Chart from "react-apexcharts";  // Charts commented out
// import Modal from "./Modal";

const DealsTile = ({ data }) => {
    const navigate = useNavigate();

    // Live stats from backend
    const livePublished = typeof data?.published === "number" ? data.published : 0;
    const liveActive = typeof data?.active === "number" ? data.active : 0;
    const liveInactive = typeof data?.inactive === "number" ? data.inactive : 0;
    const livePaused = typeof data?.paused === "number" ? data.paused : 0;
    const liveExpired = typeof data?.expired === "number" ? data.expired : 0;
    const liveUnderReview = typeof data?.under_review === "number" ? data.under_review : 0;
    const livePendingPayment = typeof data?.pending_payment === "number" ? data.pending_payment : 0;

    // Raw deals data for filtering
    const dealsRaw = Array.isArray(data?.dealsRaw) ? data.dealsRaw : [];

    const [dateFilter, setDateFilter] = useState("live");

    let displayedPublished = livePublished;
    let displayedActive = liveActive;
    let displayedInactive = liveInactive;
    let displayedPaused = livePaused;
    let displayedExpired = liveExpired;
    let displayedUnderReview = liveUnderReview;
    let displayedPendingPayment = livePendingPayment;

    if (dateFilter !== "live") {
        const range = getDateRangeFromFilter(dateFilter);
        if (range) {
            const { startDate, endDate } = range;
            const filteredRaw = dealsRaw.filter((deal) => {
                const created = new Date(deal.created_at);
                return created >= startDate && created <= endDate;
            });
            displayedPublished = filteredRaw.length;
            displayedActive = filteredRaw.filter(
                (deal) => deal.deal_status && deal.deal_status.toLowerCase().trim() === "active"
            ).length;
            displayedInactive = filteredRaw.filter(
                (deal) => deal.deal_status && deal.deal_status.toLowerCase().trim() === "inactive"
            ).length;
            displayedPaused = filteredRaw.filter(
                (deal) => deal.deal_status && deal.deal_status.toLowerCase().trim() === "paused"
            ).length;
            displayedExpired = filteredRaw.filter(
                (deal) => deal.deal_status && deal.deal_status.toLowerCase().trim() === "expired"
            ).length;
            displayedUnderReview = filteredRaw.filter(
                (deal) => deal.deal_status && deal.deal_status.toLowerCase().trim() === "under_review"
            ).length;
            displayedPendingPayment = filteredRaw.filter(
                (deal) => deal.deal_status && deal.deal_status.toLowerCase().trim() === "pending_payment"
            ).length;
        }
    }

    return (
        <div className="p-4 bg-gray-700 rounded-lg shadow-lg">
            {/* Tile Header */}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-gray-200">Deals</h2>
                <DateFilterDropdown value={dateFilter} onChange={setDateFilter} />
            </div>

            {/* Totals Section */}
            <div className="mb-4 cursor-pointer" onClick={() => navigate("/Deals")}>
                <div className="text-center">
                    <p className="text-5xl font-bold text-gray-200">
                        {displayedPublished.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Total Deals</p>
                </div>
            </div>

            {/* Detailed Stats in 3 rows of 2 figures each */}
            <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div
                        className="text-center cursor-pointer"
                        onClick={() => navigate("/Deals?status=active")}
                    >
                        <p className="text-4xl font-bold text-gray-200">
                            {displayedActive.toLocaleString()}
                        </p>
                        <p className="text-lg text-gray-400">Active</p>
                    </div>
                    <div
                        className="text-center cursor-pointer"
                        onClick={() => navigate("/Deals?status=inactive")}
                    >
                        <p className="text-4xl font-bold text-gray-200">
                            {displayedInactive.toLocaleString()}
                        </p>
                        <p className="text-lg text-gray-400">In-Active/Deactive</p>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div
                        className="text-center cursor-pointer"
                        onClick={() => navigate("/Deals?status=paused")}
                    >
                        <p className="text-4xl font-bold text-gray-200">
                            {displayedPaused.toLocaleString()}
                        </p>
                        <p className="text-lg text-gray-400">Paused</p>
                    </div>
                    <div
                        className="text-center cursor-pointer"
                        onClick={() => navigate("/Deals?status=under_review")}
                    >
                        <p className="text-4xl font-bold text-gray-200">
                            {displayedUnderReview.toLocaleString()}
                        </p>
                        <p className="text-lg text-gray-400">Under Review</p>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div
                        className="text-center cursor-pointer"
                        onClick={() => navigate("/Deals?status=pending_payment")}
                    >
                        <p className="text-4xl font-bold text-gray-200">
                            {displayedPendingPayment.toLocaleString()}
                        </p>
                        <p className="text-lg text-gray-400">Pending Payment</p>
                    </div>
                    <div
                        className="text-center cursor-pointer"
                        onClick={() => navigate("/Deals?status=expired")}
                    >
                        <p className="text-4xl font-bold text-gray-200">
                            {displayedExpired.toLocaleString()}
                        </p>
                        <p className="text-lg text-gray-400">Expired</p>
                    </div>
                </div>
            </div>

            {/* Chart Section - Temporarily commented out */}
            {/*
      <div className="mt-6">
        <Chart options={lineOptions} series={lineSeries} type="line" height={300} />
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4"
          onClick={() => setShowModal(true)}
        >
          Expand Chart
        </button>
      </div>
      */}
        </div>
    );
};

export default DealsTile;
