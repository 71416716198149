import React, { useState } from "react";
import Chart from "react-apexcharts";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import DateFilterDropdown from "./DateFilterDropdown";

const RealizedValueTile = ({ customerData, vendorData }) => {
    const [selectedOption, setSelectedOption] = useState("Customer Value");
    const [dateFilter, setDateFilter] = useState("last_7_days");

    // Safe defaults (added optional growth arrays)
    const safeCustomerData = {
        dealsPurchased:
            typeof customerData?.dealsPurchased === "number"
                ? customerData.dealsPurchased
                : 0,
        savings:
            typeof customerData?.savings === "number" ? customerData.savings : 0,
        trend: {
            dealsPurchased:
                typeof customerData?.trend?.dealsPurchased === "number"
                    ? customerData.trend.dealsPurchased
                    : 0,
            savings:
                typeof customerData?.trend?.savings === "number"
                    ? customerData.trend.savings
                    : 0,
        },
        dealBreakdown: Array.isArray(customerData?.dealBreakdown)
            ? customerData.dealBreakdown
            : [],
        growth: Array.isArray(customerData?.growth) ? customerData.growth : []
    };

    const safeVendorData = {
        couponsRedeemed:
            typeof vendorData?.couponsRedeemed === "number"
                ? vendorData.couponsRedeemed
                : 0,
        revenue:
            typeof vendorData?.revenue === "number" ? vendorData.revenue : 0,
        trend: {
            couponsRedeemed:
                typeof vendorData?.trend?.couponsRedeemed === "number"
                    ? vendorData.trend.couponsRedeemed
                    : 0,
            revenue:
                typeof vendorData?.trend?.revenue === "number"
                    ? vendorData.trend.revenue
                    : 0,
        },
        dealBreakdown: Array.isArray(vendorData?.dealBreakdown)
            ? vendorData.dealBreakdown
            : [],
        growth: Array.isArray(vendorData?.growth) ? vendorData.growth : []
    };

    const data = selectedOption === "Customer Value" ? safeCustomerData : safeVendorData;

    // Assume growth arrays contain objects with { date, dealsPurchased, savings } for customer and { date, couponsRedeemed, revenue } for vendor
    const growthData = data.growth;

    // Compute cumulative growth for the selected option
    const cumulativeGrowth = growthData.reduce((acc, curr, index) => {
        if (index === 0) {
            if (selectedOption === "Customer Value") {
                acc.push({
                    date: curr.date,
                    dealsPurchased: curr.dealsPurchased,
                    savings: curr.savings,
                });
            } else {
                acc.push({
                    date: curr.date,
                    couponsRedeemed: curr.couponsRedeemed,
                    revenue: curr.revenue,
                });
            }
        } else {
            const prev = acc[index - 1];
            if (selectedOption === "Customer Value") {
                acc.push({
                    date: curr.date,
                    dealsPurchased: prev.dealsPurchased + curr.dealsPurchased,
                    savings: prev.savings + curr.savings,
                });
            } else {
                acc.push({
                    date: curr.date,
                    couponsRedeemed: prev.couponsRedeemed + curr.couponsRedeemed,
                    revenue: prev.revenue + curr.revenue,
                });
            }
        }
        return acc;
    }, []);

    const filterDataByDateRange = (dataArray, filter) => {
        const now = new Date();
        let startDate;
        if (filter === "last_7_days") {
            startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
        } else if (filter === "last_30_days") {
            startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
        } else if (filter === "last_6_months") {
            startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
        } else if (filter === "last_1_year") {
            startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        } else if (filter.startsWith("custom_")) {
            const parts = filter.split("_");
            if (parts.length === 3) {
                startDate = new Date(parts[1]);
                const endDate = new Date(parts[2]);
                return dataArray.filter((item) => {
                    const itemDate = new Date(item.date);
                    return itemDate >= startDate && itemDate <= endDate;
                });
            }
        }
        if (!startDate) return dataArray;
        return dataArray.filter((item) => {
            const itemDate = new Date(item.date);
            return itemDate >= startDate && itemDate <= now;
        });
    };

    const filteredGrowth = filterDataByDateRange(cumulativeGrowth, dateFilter);

    const lineOptions = {
        chart: {
            id: "realized-value-line-chart",
            toolbar: { show: false },
            animations: { enabled: true },
            background: "transparent",
        },
        xaxis: {
            categories: filteredGrowth.map((item) => item.date),
            labels: { style: { colors: "#cccccc" } },
        },
        yaxis: {
            labels: { style: { colors: "#cccccc" } },
        },
        stroke: { curve: "smooth" },
        tooltip: {
            theme: "dark",
            x: { format: "yyyy-MM-dd" },
            y: { formatter: (value) => `$${value.toLocaleString()}` },
        },
        grid: { borderColor: "#4a5568" },
    };

    const lineSeries =
        selectedOption === "Customer Value"
            ? [
                {
                    name: "Deals Purchased",
                    data: filteredGrowth.map((item) => item.dealsPurchased),
                },
                {
                    name: "Savings",
                    data: filteredGrowth.map((item) => item.savings),
                },
            ]
            : [
                {
                    name: "Coupons Redeemed",
                    data: filteredGrowth.map((item) => item.couponsRedeemed),
                },
                {
                    name: "Revenue",
                    data: filteredGrowth.map((item) => item.revenue),
                },
            ];

    return (
        <div className="p-4 bg-gray-700 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
            {/* Tile Header */}
            <h2 className="text-lg font-semibold text-gray-200 mb-4">Realized Value</h2>

            {/* Dropdowns */}
            <div className="flex flex-col md:flex-row items-center gap-4 mb-6">
                <div className="flex-1">
                    <select
                        className="w-full bg-gray-700 text-gray-200 text-sm px-3 py-2 rounded-lg focus:outline-none"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                    >
                        <option value="Customer Value">Customer Value</option>
                        <option value="Vendor Value">Vendor Value</option>
                    </select>
                </div>
                <div className="flex-1">
                    <DateFilterDropdown value={dateFilter} onChange={setDateFilter} />
                </div>
            </div>

            {/* Stats Section */}
            <div className="mb-6">
                {selectedOption === "Customer Value" && (
                    <div className="mb-4">
                        <div className="flex items-center gap-2">
                            <p className="text-3xl font-bold text-gray-200">
                                {data.dealsPurchased}
                            </p>
                            {data.trend?.dealsPurchased > 0 ? (
                                <FaArrowUp className="text-green-500" />
                            ) : (
                                <FaArrowDown className="text-red-500" />
                            )}
                            <span className="text-gray-400 text-sm">
                {data.trend?.dealsPurchased > 0 ? "+" : ""}
                                {data.trend?.dealsPurchased}%
              </span>
                        </div>
                        <p className="text-sm text-gray-400">Deals Purchased</p>
                    </div>
                )}

                {selectedOption === "Customer Value" && (
                    <div className="mb-4">
                        <div className="flex items-center gap-2">
                            <p className="text-3xl font-bold text-gray-200">
                                ${data.savings.toLocaleString()}
                            </p>
                            {data.trend?.savings > 0 ? (
                                <FaArrowUp className="text-green-500" />
                            ) : (
                                <FaArrowDown className="text-red-500" />
                            )}
                            <span className="text-gray-400 text-sm">
                {data.trend?.savings > 0 ? "+" : ""}
                                {data.trend?.savings}%
              </span>
                        </div>
                        <p className="text-sm text-gray-400">Savings</p>
                    </div>
                )}

                {selectedOption === "Vendor Value" && (
                    <div className="mb-4">
                        <div className="flex items-center gap-2">
                            <p className="text-3xl font-bold text-gray-200">
                                {data.couponsRedeemed}
                            </p>
                            {data.trend?.couponsRedeemed > 0 ? (
                                <FaArrowUp className="text-green-500" />
                            ) : (
                                <FaArrowDown className="text-red-500" />
                            )}
                            <span className="text-gray-400 text-sm">
                {data.trend?.couponsRedeemed > 0 ? "+" : ""}
                                {data.trend?.couponsRedeemed}%
              </span>
                        </div>
                        <p className="text-sm text-gray-400">Coupons Redeemed</p>
                    </div>
                )}

                {selectedOption === "Vendor Value" && (
                    <div className="mb-4">
                        <div className="flex items-center gap-2">
                            <p className="text-3xl font-bold text-gray-200">
                                ${data.revenue.toLocaleString()}
                            </p>
                            {data.trend?.revenue > 0 ? (
                                <FaArrowUp className="text-green-500" />
                            ) : (
                                <FaArrowDown className="text-red-500" />
                            )}
                            <span className="text-gray-400 text-sm">
                {data.trend?.revenue > 0 ? "+" : ""}
                                {data.trend?.revenue}%
              </span>
                        </div>
                        <p className="text-sm text-gray-400">Revenue</p>
                    </div>
                )}

                <div className="mb-4">
                    <h4 className="text-sm font-semibold text-gray-200 mb-2">
                        Deal Breakdown:
                    </h4>
                    <ul className="text-sm text-gray-400 space-y-1">
                        {(data.dealBreakdown || []).map((deal, idx) => (
                            <li key={idx}>
                                <strong className="text-gray-200">{deal.dealName}</strong> —{" "}
                                {selectedOption === "Customer Value" ? (
                                    <>
                                        Regular: ${deal.regularPrice} | Wah! Deal: ${deal.wahDealPrice} | Savings: ${deal.savings}
                                    </>
                                ) : (
                                    <>
                                        Regular: ${deal.regularPrice} | Wah! Deal: ${deal.wahDealPrice} | Fee Commission: ${deal.feeCommission}
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* Growth Chart */}
            {filteredGrowth.length > 0 ? (
                <div style={{ width: "100%", height: 250 }}>
                    <Chart options={lineOptions} series={lineSeries} type="line" height={250} />
                </div>
            ) : (
                <p className="text-center text-gray-400 mt-6">No growth data available</p>
            )}
        </div>
    );
};

export default RealizedValueTile;
