import React from "react";
import { useNavigate } from "react-router-dom";
import DateFilterDropdown from "./DateFilterDropdown";

const CommunicationTile = ({ data }) => {
    const navigate = useNavigate();
    const totalChats = typeof data?.totalChats === "number" ? data.totalChats : 0;
    const customerChats = typeof data?.customerChats === "number" ? data.customerChats : 0;
    const vendorChats = typeof data?.vendorChats === "number" ? data.vendorChats : 0;

    // Handle navigation based on the figure clicked.
    // For "total" we redirect with no filter; for "vendor" and "customer" we pass a query parameter.
    const handleNavigate = (filter) => {
        if (filter === "total") {
            navigate("/adminChat");
        } else {
            navigate(`/adminChat?chatType=${filter}`);
        }
    };

    return (
        <div className="p-4 bg-gray-700 rounded-lg shadow-lg">
            {/* Tile Header */}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-gray-200">Communication</h2>
                <DateFilterDropdown />
            </div>

            {/* Totals Section */}
            <div className="mb-4 cursor-pointer" onClick={() => handleNavigate("total")}>
                <div className="text-center">
                    <p className="text-5xl font-bold text-gray-200">
                        {totalChats.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Total Chats</p>
                </div>
            </div>

            {/* Breakdown Section */}
            <div className="grid grid-cols-2 gap-4">
                <div
                    className="text-center cursor-pointer"
                    onClick={() => handleNavigate("vendor")}
                >
                    <p className="text-4xl font-bold text-gray-200">
                        {vendorChats.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Vendor Chats</p>
                </div>
                <div
                    className="text-center cursor-pointer"
                    onClick={() => handleNavigate("customer")}
                >
                    <p className="text-4xl font-bold text-gray-200">
                        {customerChats.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Customer Chats</p>
                </div>
            </div>
        </div>
    );
};

export default CommunicationTile;
