import React, { useState, useEffect } from 'react';
import {
    FaAngleDoubleLeft,
    FaAngleLeft,
    FaAngleRight,
    FaAngleDoubleRight,
    FaArrowRight
} from 'react-icons/fa';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const [jumpPage, setJumpPage] = useState(currentPage);

    useEffect(() => {
        setJumpPage(currentPage);
    }, [currentPage]);

    if (totalPages <= 1) return null;

    // Smart page numbers with ellipses for many pages
    const getPageNumbers = (current, total) => {
        const delta = 2;
        const pages = [];
        pages.push(1);

        if (current - delta > 2) {
            pages.push('left-ellipsis');
        } else {
            for (let i = 2; i < Math.max(2, current - delta); i++) {
                pages.push(i);
            }
        }

        for (let i = Math.max(2, current - delta); i <= Math.min(total - 1, current + delta); i++) {
            pages.push(i);
        }

        if (current + delta < total - 1) {
            pages.push('right-ellipsis');
        } else {
            for (let i = Math.min(total - 1, current + delta) + 1; i < total; i++) {
                pages.push(i);
            }
        }

        if (total > 1) {
            pages.push(total);
        }

        return pages;
    };

    const pages = getPageNumbers(currentPage, totalPages);

    const handleJumpSubmit = (e) => {
        e.preventDefault();
        const page = Number(jumpPage);
        if (page >= 1 && page <= totalPages && page !== currentPage) {
            onPageChange(page);
        }
    };

    return (
        <div className="flex justify-center mt-6">
            <div className="flex items-center space-x-2">
                <button
                    onClick={() => onPageChange(1)}
                    disabled={currentPage === 1}
                    title="First Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleDoubleLeft size={18} />
                </button>
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    title="Previous Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleLeft size={18} />
                </button>

                {pages.map((page, idx) =>
                        page === 'left-ellipsis' || page === 'right-ellipsis' ? (
                            <span key={idx} className="px-3 py-2">
              &hellip;
            </span>
                        ) : (
                            <button
                                key={page}
                                onClick={() => onPageChange(page)}
                                className={`px-4 py-2 rounded transition-colors ${
                                    page === currentPage
                                        ? 'bg-blue-500 text-white font-semibold'
                                        : 'bg-gray-200 hover:bg-gray-300'
                                }`}
                            >
                                {page}
                            </button>
                        )
                )}

                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    title="Next Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleRight size={18} />
                </button>
                <button
                    onClick={() => onPageChange(totalPages)}
                    disabled={currentPage === totalPages}
                    title="Last Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleDoubleRight size={18} />
                </button>

                <form onSubmit={handleJumpSubmit} className="flex items-center border border-gray-300 rounded overflow-hidden">
                    <input
                        type="number"
                        min="1"
                        max={totalPages}
                        value={jumpPage}
                        onChange={(e) => setJumpPage(e.target.value)}
                        placeholder="Page"
                        className="w-16 px-2 py-2 text-center outline-none"
                    />
                    <button
                        type="submit"
                        className="px-3 py-2 bg-blue-500 text-white hover:bg-blue-600 transition-colors"
                        title="Go to Page"
                    >
                        <FaArrowRight size={14} />
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Pagination;
