// src/redux/reducers/appConfigReducer.js
import {
    FETCH_APPCONFIGS_SUCCESS,
    FETCH_APPCONFIGS_ERROR,
    UPDATE_APPCONFIG_SUCCESS,
    UPDATE_APPCONFIG_ERROR,
    ADD_APPCONFIG_SUCCESS,
    ADD_APPCONFIG_ERROR,
} from "../types";

const initialState = {
    configs: [],
    error: null,
};

const appConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_APPCONFIGS_SUCCESS:
            return { ...state, configs: action.payload, error: null };
        case FETCH_APPCONFIGS_ERROR:
            return { ...state, configs: [], error: action.payload };
        case UPDATE_APPCONFIG_SUCCESS:
            const updatedConfig = action.payload;
            return {
                ...state,
                configs: state.configs.map((cfg) =>
                    cfg.id === updatedConfig.id ? updatedConfig : cfg
                ),
                error: null,
            };
        case UPDATE_APPCONFIG_ERROR:
            return { ...state, error: action.payload };
        case ADD_APPCONFIG_SUCCESS:
            return { ...state, configs: [action.payload, ...state.configs], error: null };
        case ADD_APPCONFIG_ERROR:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default appConfigReducer;
