import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const EditPlanModal = ({
                           selectedPlan,
                           planData,
                           setPlanData,
                           closeEditModal,
                           handleUpdatePlan,
                           hasEnrolledUsers,
                           isUpdating,
                       }) => {
    if (!selectedPlan) return null;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlanData((prev) => ({ ...prev, [name]: value }));
    };

    // For demonstration, we'll disable certain fields if hasEnrolledUsers is true
    const isReadOnly = hasEnrolledUsers;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg max-w-xl w-full relative">
                <button onClick={closeEditModal} className="absolute top-2 right-2 text-gray-500">
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">Edit Subscription Plan</h2>

                {/* Plan Name */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Plan Name</label>
                    <input
                        type="text"
                        name="plan_name"
                        value={planData.plan_name}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                        disabled={isReadOnly}
                    />
                    {hasEnrolledUsers && (
                        <p className="text-red-500 text-sm">
                            Cannot edit plan name because there are enrolled users.
                        </p>
                    )}
                </div>

                {/* Plan Description */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Plan Description</label>
                    <textarea
                        name="plan_description"
                        value={planData.plan_description}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                        rows={3}
                        disabled={isReadOnly}
                    />
                </div>

                {/* Subscription Fee */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Subscription Fee</label>
                    <input
                        type="number"
                        name="subscription_fee"
                        value={planData.subscription_fee || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                        disabled={isReadOnly}
                    />
                </div>

                {/* Renewal Type */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Renewal Type</label>
                    <input
                        type="text"
                        name="subscription_renewal_type"
                        value={planData.subscription_renewal_type || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                        disabled={isReadOnly}
                    />
                </div>

                {/* IAP Apple ID */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">IAP Apple ID</label>
                    <input
                        type="text"
                        name="IAP_id_apple"
                        value={planData.IAP_id_apple || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                        disabled={isReadOnly}
                    />
                </div>

                {/* IAP Google ID */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">IAP Google ID</label>
                    <input
                        type="text"
                        name="IAP_id_google"
                        value={planData.IAP_id_google || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                        disabled={isReadOnly}
                    />
                </div>

                {/* Plan Status (read-only) */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Status</label>
                    <input
                        type="text"
                        name="status"
                        value={planData.status || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                        disabled
                    />
                    <p className="text-sm text-gray-500">
                        Status changes occur via Activate/Deactivate button on main table.
                    </p>
                </div>

                <button
                    onClick={handleUpdatePlan}
                    className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
                    disabled={isUpdating}
                >
                    {isUpdating ? <FaSpinner className="animate-spin" size={16} /> : "Save Changes"}
                </button>
            </div>
        </div>
    );
};

export default EditPlanModal;
