import React from "react";
import { FaSpinner } from "react-icons/fa";

const DeactivatePlanModal = ({
                                 selectedPlan,
                                 planIdInput,
                                 setPlanIdInput,
                                 handleConfirmDeactivation,
                                 closeDeactivateModal,
                                 isToggling,
                             }) => {
    if (!selectedPlan) return null;

    const isActive = selectedPlan.status?.toLowerCase() === "active";

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg max-w-lg w-full relative">
                <button
                    onClick={closeDeactivateModal}
                    className="absolute top-2 right-2 text-gray-500"
                >
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">
                    {isActive ? "Deactivate Plan" : "Activate Plan"}
                </h2>
                <p>
                    Are you sure you want to{" "}
                    <strong>{isActive ? "deactivate" : "activate"}</strong> this plan:{" "}
                    <strong>{selectedPlan.plan_id}</strong>?
                </p>
                <input
                    type="text"
                    value={planIdInput}
                    onChange={(e) => setPlanIdInput(e.target.value)}
                    placeholder="Enter Plan ID to confirm"
                    className="border border-gray-300 rounded w-full p-2 mt-4 mb-4"
                />
                <div className="flex justify-center space-x-4">
                    <button
                        onClick={closeDeactivateModal}
                        className="bg-gray-300 px-4 py-2 rounded"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleConfirmDeactivation}
                        className={`px-4 py-2 rounded ${
                            isActive ? "bg-red-500 text-white" : "bg-blue-500 text-white"
                        }`}
                        disabled={planIdInput !== selectedPlan.plan_id || isToggling}
                    >
                        {isToggling ? <FaSpinner className="animate-spin" size={16} /> : (isActive ? "Deactivate" : "Activate")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeactivatePlanModal;
