import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import ProtectedRoute from "./auth/ProtectedRoute";
import Layout from "./components/Layout";
import Vendors from "./pages/vendors/Vendors";
import Customers from "./pages/customers/Customers";
import Deals from "./pages/vendors/Deals";
import Categories from "./pages/vendors/Categories";
import Dashboard from "./pages/Dashboard";
import Coupons from "./pages/coupons/Coupons";
import Subscriptions from "./pages/subscriptions/Subscriptions";
import StoreLocations from "./pages/storeLocations/StoreLocations";
import AppConfig from "./pages/appConfig/AppConfig";
import RevenueVendorPage from "./pages/revenue/RevenueVendorPage";
import RevenueCustomerPage from "./pages/revenue/RevenueCustomerPage";
import RevenueWahPage from "./pages/revenue/RevenueWahPage";
import WalletVendorPage from "./pages/wallets/WalletVendorPage";
import WalletCustomerPage from "./pages/wallets/WalletCustomerPage";
import AdminChat from "./pages/chat/AdminChat";
import { AuthProvider } from "./auth/AuthContext";
import SessionManager from "./auth/SessionManager"; // Import SessionManager

function App() {
    return (
        <AuthProvider>
            <Router>
                {/* Place SessionManager here so it's available app-wide */}
                <SessionManager />
                <Routes>
                    {/* Public Route */}
                    <Route path="/" element={<Login />} />

                    {/* Protected Routes using nested routing */}
                    <Route
                        element={
                            <ProtectedRoute>
                                <Layout />
                            </ProtectedRoute>
                        }
                    >
                        <Route path="vendors" element={<Vendors />} />
                        <Route path="customers" element={<Customers />} />
                        <Route path="Deals" element={<Deals />} />
                        <Route path="Categories" element={<Categories />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="coupons" element={<Coupons />} />
                        <Route path="subscriptions" element={<Subscriptions />} />
                        <Route path="storeLocations" element={<StoreLocations />} />
                        <Route path="appConfig" element={<AppConfig />} />
                        <Route path="revenue/vendor" element={<RevenueVendorPage />} />
                        <Route path="revenue/customer" element={<RevenueCustomerPage />} />
                        <Route path="revenue/wah" element={<RevenueWahPage />} />
                        <Route path="wallet/vendor" element={<WalletVendorPage />} />
                        <Route path="wallet/customer" element={<WalletCustomerPage />} />
                        <Route path="adminChat" element={<AdminChat />} />
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;