import { getVendorWalletAdmin, getCustomerWalletAdmin, updateWalletAdmin } from '../../api/api';

import {
    FETCH_VENDOR_WALLETS_SUCCESS,
    FETCH_VENDOR_WALLETS_ERROR,
    FETCH_CUSTOMER_WALLETS_SUCCESS,
    FETCH_CUSTOMER_WALLETS_ERROR,
    UPDATE_WALLET_SUCCESS,
    UPDATE_WALLET_ERROR,
} from '../types';

export const fetchVendorWallets = () => async (dispatch) => {
    try {
        const wallets = await getVendorWalletAdmin();
        dispatch({ type: FETCH_VENDOR_WALLETS_SUCCESS, payload: wallets });
    } catch (error) {
        dispatch({ type: FETCH_VENDOR_WALLETS_ERROR, payload: error.message });
    }
};

export const fetchCustomerWallets = () => async (dispatch) => {
    try {
        const wallets = await getCustomerWalletAdmin();
        dispatch({ type: FETCH_CUSTOMER_WALLETS_SUCCESS, payload: wallets });
    } catch (error) {
        dispatch({ type: FETCH_CUSTOMER_WALLETS_ERROR, payload: error.message });
    }
};

export const updateWalletBalance = (walletId, amount, operation) => async (dispatch) => {
    try {
        const updatedWallet = await updateWalletAdmin(walletId, amount, operation);
        dispatch({ type: UPDATE_WALLET_SUCCESS, payload: updatedWallet });
    } catch (error) {
        dispatch({ type: UPDATE_WALLET_ERROR, payload: error.message });
    }
};
