import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const DeactivateDealModal = ({
                                 selectedDeal,
                                 dealIdInput,
                                 setDealIdInput,
                                 handleConfirmDeactivation,
                                 closeDeactivateModal,
                                 isUpdatingStatus,
                             }) => {
    if (!selectedDeal) return null;

    // Normalize status to lowercase to handle "Active" or "active"
    const isActive = selectedDeal.deal_status?.toLowerCase() === "active";

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg max-w-lg w-full relative">
                <button
                    onClick={closeDeactivateModal}
                    className="absolute top-2 right-2 text-gray-500"
                    disabled={isUpdatingStatus}
                >
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">
                    {isActive ? "Deactivate Deal" : "Activate Deal"}
                </h2>
                <p>
                    Would you like to {isActive ? "deactivate" : "activate"} the deal with Deal ID:{" "}
                    <strong>{selectedDeal.deal_id.split("_")[1]}</strong>?
                </p>
                <input
                    type="text"
                    value={dealIdInput}
                    onChange={(e) => setDealIdInput(e.target.value)}
                    placeholder="Enter Deal ID to confirm"
                    className="border border-gray-300 rounded w-full p-2 mt-4 mb-4"
                    disabled={isUpdatingStatus}
                />
                <div className="flex justify-center space-x-4">
                    <button
                        onClick={closeDeactivateModal}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                        disabled={isUpdatingStatus}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleConfirmDeactivation}
                        className="flex items-center bg-red-500 text-white px-4 py-2 rounded"
                        disabled={dealIdInput !== selectedDeal.deal_id.split("_")[1] || isUpdatingStatus}
                    >
                        {isUpdatingStatus ? (
                            <>
                                <FaSpinner className="animate-spin mr-2" /> Processing...
                            </>
                        ) : (
                            isActive ? "Deactivate" : "Activate"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeactivateDealModal;
