import React, { useState, useMemo, useEffect } from 'react';
import {
    FaChevronRight,
    FaChevronDown,
    FaSpinner,
    FaAngleDoubleLeft,
    FaAngleLeft,
    FaAngleRight,
    FaAngleDoubleRight,
    FaArrowRight,
} from 'react-icons/fa';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const [jumpPage, setJumpPage] = useState(currentPage);

    useEffect(() => {
        setJumpPage(currentPage);
    }, [currentPage]);

    if (totalPages <= 1) return null;

    const getPageNumbers = (current, total) => {
        const delta = 2;
        const pages = [];
        pages.push(1);

        if (current - delta > 2) {
            pages.push("left-ellipsis");
        } else {
            for (let i = 2; i < Math.max(2, current - delta); i++) {
                pages.push(i);
            }
        }

        for (let i = Math.max(2, current - delta); i <= Math.min(total - 1, current + delta); i++) {
            pages.push(i);
        }

        if (current + delta < total - 1) {
            pages.push("right-ellipsis");
        } else {
            for (let i = Math.min(total - 1, current + delta) + 1; i < total; i++) {
                pages.push(i);
            }
        }

        if (total > 1) {
            pages.push(total);
        }

        return pages;
    };

    const pages = getPageNumbers(currentPage, totalPages);

    const handleJumpSubmit = (e) => {
        e.preventDefault();
        const page = Number(jumpPage);
        if (page >= 1 && page <= totalPages && page !== currentPage) {
            onPageChange(page);
        }
    };

    return (
        <div className="flex justify-center mt-6">
            <div className="flex items-center space-x-2">
                <button
                    onClick={() => onPageChange(1)}
                    disabled={currentPage === 1}
                    title="First Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleDoubleLeft size={18} />
                </button>
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    title="Previous Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleLeft size={18} />
                </button>

                {pages.map((page, idx) =>
                    page === "left-ellipsis" || page === "right-ellipsis" ? (
                        <span key={idx} className="px-3 py-2">&hellip;</span>
                    ) : (
                        <button
                            key={page}
                            onClick={() => onPageChange(page)}
                            className={`px-4 py-2 rounded transition-colors ${
                                page === currentPage
                                    ? "bg-blue-500 text-white font-semibold"
                                    : "bg-gray-200 hover:bg-gray-300"
                            }`}
                        >
                            {page}
                        </button>
                    )
                )}

                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    title="Next Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleRight size={18} />
                </button>
                <button
                    onClick={() => onPageChange(totalPages)}
                    disabled={currentPage === totalPages}
                    title="Last Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleDoubleRight size={18} />
                </button>

                <form onSubmit={handleJumpSubmit} className="flex items-center border border-gray-300 rounded overflow-hidden">
                    <input
                        type="number"
                        min="1"
                        max={totalPages}
                        value={jumpPage}
                        onChange={(e) => setJumpPage(e.target.value)}
                        placeholder="Page"
                        className="w-16 px-2 py-2 text-center outline-none"
                    />
                    <button
                        type="submit"
                        className="px-3 py-2 bg-blue-500 text-white hover:bg-blue-600 transition-colors"
                        title="Go to Page"
                    >
                        <FaArrowRight size={14} />
                    </button>
                </form>
            </div>
        </div>
    );
};

const WalletTable = ({ wallets, loading, onEditClick, type }) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const handleRowExpand = (walletId) => {
        setExpandedRows((prev) =>
            prev.includes(walletId) ? prev.filter((id) => id !== walletId) : [...prev, walletId]
        );
    };

    const handleSort = (key) => {
        setSortConfig((prev) =>
            prev.key === key ? { key, direction: prev.direction === 'asc' ? 'desc' : 'asc' } : { key, direction: 'asc' }
        );
    };

    const sortedWallets = useMemo(() => {
        const items = [...wallets];
        if (sortConfig.key) {
            items.sort((a, b) => {
                let aVal = a[sortConfig.key] || '';
                let bVal = b[sortConfig.key] || '';
                if (typeof aVal === 'string') aVal = aVal.toLowerCase();
                if (typeof bVal === 'string') bVal = bVal.toLowerCase();
                if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return items;
    }, [wallets, sortConfig]);

    const currentWallets = useMemo(
        () => sortedWallets.slice((currentPage - 1) * pageSize, currentPage * pageSize),
        [sortedWallets, currentPage]
    );
    const totalPages = Math.ceil(sortedWallets.length / pageSize);

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full text-left">
                <thead className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <tr>
                    <th
                        onClick={() => handleSort('wallet_id')}
                        className="py-3 px-6 cursor-pointer select-none text-left"
                    >
                        Wallet ID {sortConfig.key === 'wallet_id' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                    </th>
                    {type === 'vendor' ? (
                        <th
                            onClick={() => handleSort('vendor_id')}
                            className="py-3 px-6 cursor-pointer select-none text-left"
                        >
                            Vendor ID {sortConfig.key === 'vendor_id' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                    ) : (
                        <>
                            <th
                                onClick={() => handleSort('customer_id')}
                                className="py-3 px-6 cursor-pointer select-none text-left"
                            >
                                Customer ID {sortConfig.key === 'customer_id' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                            <th
                                onClick={() => handleSort('customer_name')}
                                className="py-3 px-6 cursor-pointer select-none text-left"
                            >
                                Customer Name {sortConfig.key === 'customer_name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                        </>
                    )}
                    <th
                        onClick={() => handleSort('wallet_balance')}
                        className="py-3 px-6 cursor-pointer select-none text-left"
                    >
                        Balance {sortConfig.key === 'wallet_balance' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                    </th>
                    <th className="py-3 px-6 text-left">Actions</th>
                </tr>
                </thead>
                <tbody className="text-gray-700 text-sm font-light">
                {loading ? (
                    <tr>
                        <td colSpan={type === 'vendor' ? 4 : 5} className="text-center py-6">
                            <div className="flex items-center justify-center space-x-2">
                                <FaSpinner className="animate-spin" size={24} />
                                <span>Loading wallets, please wait...</span>
                            </div>
                        </td>
                    </tr>
                ) : currentWallets.length > 0 ? (
                    currentWallets.map((wallet) => {
                        const isExpanded = expandedRows.includes(wallet.wallet_id);
                        return (
                            <React.Fragment key={wallet.wallet_id}>
                                <tr className="border-b border-gray-200 hover:bg-gray-100">
                                    <td className="py-3 px-6 text-left text-blue-600 font-medium">
                                        <div className="flex items-center space-x-2">
                                            <button
                                                onClick={() => handleRowExpand(wallet.wallet_id)}
                                                className="focus:outline-none"
                                                title={isExpanded ? 'Collapse details' : 'Expand details'}
                                            >
                                                {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                                            </button>
                                            <span>{wallet.wallet_id}</span>
                                        </div>
                                    </td>
                                    {type === 'vendor' ? (
                                        <td className="py-3 px-6 text-left font-bold">{wallet.vendor_id}</td>
                                    ) : (
                                        <>
                                            <td className="py-3 px-6 text-left font-bold">{wallet.customer_id}</td>
                                            <td className="py-3 px-6 text-left font-bold">
                                                {wallet.customer_name ? wallet.customer_name : "NA"}
                                            </td>
                                        </>
                                    )}
                                    <td className="py-3 px-6 text-left font-bold">${wallet.wallet_balance}</td>
                                    <td className="py-3 px-6 text-left">
                                        <button
                                            onClick={() => onEditClick(wallet)}
                                            className="text-blue-500 font-medium hover:opacity-80 transition-opacity"
                                        >
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                                {isExpanded && (
                                    <tr>
                                        <td colSpan={type === 'vendor' ? 4 : 5}>
                                            <div className="bg-gray-50 p-4 border-t border-gray-200">
                                                <h3 className="text-lg font-semibold mb-2">Transaction History</h3>
                                                {wallet.transactions && wallet.transactions.length > 0 ? (
                                                    <div className="overflow-x-auto">
                                                        <table className="min-w-full text-sm">
                                                            <thead>
                                                            <tr className="bg-gray-200">
                                                                <th className="py-1 px-2 text-left">Transaction ID</th>
                                                                <th className="py-1 px-2 text-left">Type</th>
                                                                <th className="py-1 px-2 text-left">Status</th>
                                                                <th className="py-1 px-2 text-left">Amount</th>
                                                                <th className="py-1 px-2 text-left">Date</th>
                                                                <th className="py-1 px-2 text-left">Description</th>
                                                                <th className="py-1 px-2 text-left">Deal ID</th>
                                                                <th className="py-1 px-2 text-left">Deal Name</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {wallet.transactions.map((tx) => (
                                                                <tr key={tx.transaction_id} className="border-b">
                                                                    <td className="py-1 px-2">{tx.transaction_id}</td>
                                                                    <td className="py-1 px-2">{tx.type}</td>
                                                                    <td className="py-1 px-2">{tx.status}</td>
                                                                    <td className="py-1 px-2">${tx.amount}</td>
                                                                    <td className="py-1 px-2">{new Date(tx.date).toLocaleString()}</td>
                                                                    <td className="py-1 px-2">{tx.description}</td>
                                                                    <td className="py-1 px-2">{tx.deal && tx.deal.deal_id ? tx.deal.deal_id : "NA"}</td>
                                                                    <td className="py-1 px-2">{tx.deal && tx.deal.deal_title ? tx.deal.deal_title : "NA"}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <p className="text-gray-600">No transactions found.</p>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={type === 'vendor' ? 4 : 5} className="text-center py-6">
                            No wallets found.
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            {sortedWallets.length > pageSize && (
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
            )}
        </div>
    );
};

export default WalletTable;
