import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DateFilterDropdown from "./DateFilterDropdown";
import { getDateRangeFromFilter } from "./dateUtils";
import Modal from "./Modal";
// import Chart from "react-apexcharts";  // Chart code is commented out

const WahRevenueTile = ({ data }) => {
    const navigate = useNavigate();

    // Live stats from backend
    const liveVendorCouponsSale =
        typeof data?.vendorCouponsSale === "number" ? data.vendorCouponsSale : 0;
    const liveWahFeeFromCustomers =
        typeof data?.wahFeeFromCustomers === "number" ? data.wahFeeFromCustomers : 0;
    const liveSubscriptions =
        typeof data?.subscriptions === "number" ? data.subscriptions : 0;
    const liveUpsell = typeof data?.upsell === "number" ? data.upsell : 0;
    const liveTotalRevenue =
        liveVendorCouponsSale +
        liveWahFeeFromCustomers +
        liveSubscriptions +
        liveUpsell;

    // Raw time series data for filtering
    const timeSeriesRaw = Array.isArray(data?.timeSeriesData)
        ? data.timeSeriesData
        : [];

    const fillMissingDates = (dataArray, startDate, endDate, defaults) => {
        const dateMap = {};
        dataArray.forEach((item) => {
            dateMap[item.date] = item;
        });
        const result = [];
        let current = new Date(startDate);
        let prevItem = null;
        while (current <= endDate) {
            const dateStr = current.toISOString().slice(0, 10);
            if (dateMap[dateStr]) {
                prevItem = dateMap[dateStr];
                result.push(prevItem);
            } else {
                if (prevItem) {
                    result.push({ ...prevItem, date: dateStr });
                } else {
                    result.push({ date: dateStr, ...defaults });
                }
            }
            current.setDate(current.getDate() + 1);
        }
        return result;
    };

    const filterDataByDateRange = (dataArray, filter, defaults) => {
        const now = new Date();
        if (filter === "live") {
            if (dataArray.length === 0) return dataArray;
            const startDate = new Date(dataArray[0].date);
            return fillMissingDates(dataArray, startDate, now, defaults);
        }
        const range = getDateRangeFromFilter(filter);
        if (!range) return dataArray;
        const { startDate, endDate } = range;
        const filtered = dataArray.filter((item) => {
            const itemDate = new Date(item.date);
            return itemDate >= startDate && itemDate <= endDate;
        });
        return fillMissingDates(filtered, startDate, endDate, defaults);
    };

    // Update defaults to use upsell instead of upsellCommission
    const revenueDefaults = {
        subscriptions: 0,
        vendorCouponsSale: 0,
        wahFeeFromCustomers: 0,
        upsell: 0,
        total: 0,
    };

    const [dateFilter, setDateFilter] = useState("live");

    const filteredTimeSeriesData = filterDataByDateRange(
        timeSeriesRaw,
        dateFilter,
        revenueDefaults
    );

    // Normalize each data entry ensuring missing fields are 0 and recalc total
    const computedFilteredTimeSeriesData = filteredTimeSeriesData.map((item) => {
        const vendorCouponsSale =
            typeof item.vendorCouponsSale === "number" ? item.vendorCouponsSale : 0;
        const wahFeeFromCustomers =
            typeof item.wahFeeFromCustomers === "number" ? item.wahFeeFromCustomers : 0;
        const subscriptions =
            typeof item.subscriptions === "number" ? item.subscriptions : 0;
        const upsell = typeof item.upsell === "number" ? item.upsell : 0;
        return {
            ...item,
            vendorCouponsSale,
            wahFeeFromCustomers,
            subscriptions,
            upsell,
            total: vendorCouponsSale + wahFeeFromCustomers + subscriptions + upsell,
        };
    });

    let displayedVendorCouponsSale = liveVendorCouponsSale;
    let displayedWahFeeFromCustomers = liveWahFeeFromCustomers;
    let displayedSubscriptions = liveSubscriptions;
    let displayedUpsell = liveUpsell;
    let displayedTotalRevenue = liveTotalRevenue;

    if (dateFilter !== "live" && computedFilteredTimeSeriesData.length > 0) {
        const lastEntry =
            computedFilteredTimeSeriesData[computedFilteredTimeSeriesData.length - 1];
        displayedVendorCouponsSale = lastEntry.vendorCouponsSale;
        displayedWahFeeFromCustomers = lastEntry.wahFeeFromCustomers;
        displayedSubscriptions = lastEntry.subscriptions;
        displayedUpsell = lastEntry.upsell;
        displayedTotalRevenue = lastEntry.total;
    }

    return (
        <div className="p-4 bg-gray-700 rounded-lg shadow-lg">
            {/* Tile Header */}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-gray-200">Wah! Revenue</h2>
                <DateFilterDropdown value={dateFilter} onChange={setDateFilter} />
            </div>

            {/* Totals Section */}
            <div
                className="mb-4 cursor-pointer"
                onClick={() => navigate("/revenue/wah")}
            >
                <div className="text-center">
                    <p className="text-4xl font-bold text-gray-200">
                        ${displayedTotalRevenue.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Total Revenue</p>
                </div>
            </div>

            {/* 2x2 Grid of Figures */}
            <div className="mb-6 grid grid-cols-2 gap-4">
                <div
                    className="text-center cursor-pointer"
                    onClick={() => navigate("/revenue/vendor")}
                >
                    <p className="text-3xl font-bold text-gray-200">
                        ${displayedVendorCouponsSale.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Vendor Coupon Sale</p>
                </div>
                <div
                    className="text-center cursor-pointer"
                    onClick={() => navigate("/revenue/customer")}
                >
                    <p className="text-3xl font-bold text-gray-200">
                        ${displayedWahFeeFromCustomers.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Customer Coupon Sale</p>
                </div>
                <div
                    className="text-center cursor-pointer"
                    onClick={() => navigate("/revenue/vendor")}
                >
                    <p className="text-3xl font-bold text-gray-200">
                        ${displayedUpsell.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Vendor Upsell</p>
                </div>
                <div
                    className="text-center cursor-pointer"
                    onClick={() => navigate("/revenue/customer")}
                >
                    <p className="text-3xl font-bold text-gray-200">
                        ${displayedSubscriptions.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Subscription</p>
                </div>
            </div>

            {/* Chart Section - Temporarily commented out */}
            {/*
      <div className="mb-6">
        <div className="mb-4">
          <Chart options={pieOptions} series={pieSeries} type="pie" height={300} />
        </div>
        <div>
          <Chart options={lineOptions} series={lineSeries} type="line" height={300} />
        </div>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4"
          onClick={() => setShowModal(true)}
        >
          Expand Chart
        </button>
      </div>
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <Chart
            options={{
              ...lineOptions,
              xaxis: {
                ...lineOptions.xaxis,
                tickAmount: Math.min(computedFilteredTimeSeriesData.length, 20),
              },
            }}
            series={lineSeries}
            type="line"
            height={500}
          />
        </Modal>
      )}
      */}
        </div>
    );
};

export default WahRevenueTile;
