import React from 'react';
import { FaEdit, FaSpinner } from 'react-icons/fa';

const EditVendorModal = ({
                             vendorData,
                             setVendorData,
                             closeEditModal,
                             handleSaveChanges,
                             handleLogoChange,
                             logoFile,
                             isUpdatingVendor,
                         }) => {
    if (!vendorData) return null;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setVendorData((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg max-w-4xl w-full flex relative">
                <button onClick={closeEditModal} className="absolute top-2 right-2 text-gray-500">
                    ✖
                </button>

                {/* Left Section - Logo and Vendor Info */}
                <div className="flex flex-col items-center pr-6 border-r border-gray-200">
                    <img
                        src={logoFile ? URL.createObjectURL(logoFile) : vendorData.business_logo}
                        alt="Vendor Logo"
                        className="h-32 w-32 rounded-full mb-4 object-cover"
                    />
                    <label className="text-blue-500 cursor-pointer mt-2">
                        <FaEdit className="inline mr-1" />
                        Edit Logo
                        <input type="file" accept="image/*" onChange={handleLogoChange} className="hidden" />
                    </label>
                    <div className="text-blue-600 font-medium mt-4">
                        {vendorData.business_name}
                    </div>
                </div>

                {/* Right Section - Edit Fields */}
                <div className="flex-1 pl-6 overflow-y-auto max-h-96 space-y-4">
                    <div>
                        <label>
                            <strong>Contact Name:</strong>
                        </label>
                        <input
                            type="text"
                            name="contact_name"
                            value={vendorData.contact_name || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>License Number:</strong>
                        </label>
                        <input
                            type="text"
                            name="license_number"
                            value={vendorData.license_number || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Street Address:</strong>
                        </label>
                        <input
                            type="text"
                            name="address"
                            value={vendorData.address || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Business Name:</strong>
                        </label>
                        <input
                            type="text"
                            name="business_name"
                            value={vendorData.business_name || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Email:</strong>
                        </label>
                        <input
                            type="email"
                            name="vendor_email"
                            value={vendorData.vendor_email || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Mobile Phone:</strong>
                        </label>
                        <input
                            type="text"
                            name="mobile_phone"
                            value={vendorData.mobile_phone || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                            maxLength="15"
                        />
                    </div>

                    {/* Social Links */}
                    <div>
                        <label>
                            <strong>Facebook Link:</strong>
                        </label>
                        <input
                            type="url"
                            name="facebook_link"
                            value={vendorData.facebook_link || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Instagram Link:</strong>
                        </label>
                        <input
                            type="url"
                            name="insta_link"
                            value={vendorData.insta_link || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Website Link:</strong>
                        </label>
                        <input
                            type="url"
                            name="website_link"
                            value={vendorData.website_link || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    {/* Save Button */}
                    <div className="flex justify-end mt-4">
                        <button
                            onClick={handleSaveChanges}
                            className="bg-blue-500 text-white px-4 py-2 rounded shadow"
                            disabled={isUpdatingVendor}
                        >
                            {isUpdatingVendor ? (
                                <span className="flex items-center">
                  <FaSpinner className="animate-spin mr-2" /> Saving...
                </span>
                            ) : (
                                "Save Changes"
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditVendorModal;
