import React, { useState } from 'react';

const AddVendorModal = ({
                            categories,
                            newVendorData,
                            handleInputChange,
                            handleImageChange,
                            handleSaveNewVendor,
                            closeAddModal,
                            isAdding, // loading state prop
                        }) => {
    const [errors, setErrors] = useState({});

    // Handler for zip code blur event – uses Zippopotam API to autofill city, state, country
    const handleZipCodeBlur = async (e) => {
        const zip = e.target.value;
        if (zip && zip.length === 5) {
            try {
                const response = await fetch(`http://api.zippopotam.us/us/${zip}`);
                if (response.ok) {
                    const data = await response.json();
                    if (data && data.places && data.places.length > 0) {
                        const place = data.places[0];
                        // Update newVendorData fields using the parent's input handler
                        handleInputChange({ target: { name: 'city', value: place['place name'] } }, true);
                        handleInputChange({ target: { name: 'state', value: place['state'] } }, true);
                        handleInputChange({ target: { name: 'country', value: data.country } }, true);
                    }
                }
            } catch (error) {
                console.error("Error fetching location data:", error);
            }
        }
    };

    const validateFields = () => {
        const newErrors = {};
        // Vendor details validation
        if (!newVendorData.contact_name) newErrors.contact_name = 'Contact Name is required';
        if (!newVendorData.business_name) newErrors.business_name = 'Business Name is required';
        if (!newVendorData.vendor_email) newErrors.vendor_email = 'Email is required';
        if (!newVendorData.mobile_phone) newErrors.mobile_phone = 'Mobile Phone is required';
        if (!newVendorData.category_id) newErrors.category_id = 'Category is required';
        if (!newVendorData.address) newErrors.address = 'Address is required';
        if (!newVendorData.zipcode) newErrors.zipcode = 'Zip Code is required';
        if (!newVendorData.license_number) newErrors.license_number = 'License Number is required';
        if (!newVendorData.business_logo) newErrors.business_logo = 'Business Logo is required';

        // Additional store location info validation
        if (!newVendorData.city) newErrors.city = 'City is required';
        if (!newVendorData.state) newErrors.state = 'State is required';
        if (!newVendorData.country) newErrors.country = 'Country is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = () => {
        if (validateFields()) {
            handleSaveNewVendor();
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg max-w-4xl w-full relative max-h-[90vh] overflow-y-auto">
                <button onClick={closeAddModal} className="absolute top-2 right-2 text-gray-500">
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">Add Vendor</h2>

                <div className="grid grid-cols-2 gap-4">
                    {/* Row 1 */}
                    <div>
                        <label>
                            <strong>
                                Contact Name:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="text"
                            name="contact_name"
                            value={newVendorData.contact_name}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.contact_name && <p className="text-red-500">{errors.contact_name}</p>}
                    </div>
                    <div>
                        <label>
                            <strong>
                                Business Name:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="text"
                            name="business_name"
                            value={newVendorData.business_name}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.business_name && <p className="text-red-500">{errors.business_name}</p>}
                    </div>

                    {/* Row 2 */}
                    <div>
                        <label>
                            <strong>
                                Email:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="email"
                            name="vendor_email"
                            value={newVendorData.vendor_email}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.vendor_email && <p className="text-red-500">{errors.vendor_email}</p>}
                    </div>
                    <div>
                        <label>
                            <strong>
                                Mobile Phone:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="text"
                            name="mobile_phone"
                            value={newVendorData.mobile_phone}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.mobile_phone && <p className="text-red-500">{errors.mobile_phone}</p>}
                    </div>

                    {/* Row 3 */}
                    <div>
                        <label>
                            <strong>
                                Category:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <select
                            name="category_id"
                            value={newVendorData.category_id}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        >
                            <option value="">Select Category</option>
                            {categories.categories &&
                                categories.categories.map((cat) => (
                                    <option key={cat.category_id} value={cat.category_id}>
                                        {cat.name}
                                    </option>
                                ))}
                        </select>
                        {errors.category_id && <p className="text-red-500">{errors.category_id}</p>}
                    </div>
                    <div>
                        <label>
                            <strong>
                                Address:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="text"
                            name="address"
                            value={newVendorData.address}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.address && <p className="text-red-500">{errors.address}</p>}
                    </div>

                    {/* Row 4 */}
                    <div>
                        <label>
                            <strong>
                                Zip Code:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="text"
                            name="zipcode"
                            value={newVendorData.zipcode}
                            onChange={(e) => handleInputChange(e, true)}
                            onBlur={handleZipCodeBlur}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.zipcode && <p className="text-red-500">{errors.zipcode}</p>}
                    </div>
                    <div>
                        <label>
                            <strong>
                                City:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="text"
                            name="city"
                            value={newVendorData.city}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                            readOnly
                        />
                        {errors.city && <p className="text-red-500">{errors.city}</p>}
                    </div>
                    <div>
                        <label>
                            <strong>
                                State:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="text"
                            name="state"
                            value={newVendorData.state}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                            readOnly
                        />
                        {errors.state && <p className="text-red-500">{errors.state}</p>}
                    </div>
                    <div>
                        <label>
                            <strong>
                                Country:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="text"
                            name="country"
                            value={newVendorData.country}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                            readOnly
                        />
                        {errors.country && <p className="text-red-500">{errors.country}</p>}
                    </div>

                    {/* Row 6 */}
                    <div>
                        <label>
                            <strong>
                                License Number:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="text"
                            name="license_number"
                            value={newVendorData.license_number}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.license_number && <p className="text-red-500">{errors.license_number}</p>}
                    </div>
                    <div>
                        <label>
                            <strong>Facebook Link:</strong>
                        </label>
                        <input
                            type="url"
                            name="facebook_link"
                            value={newVendorData.facebook_link}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    {/* Row 7 */}
                    <div>
                        <label>
                            <strong>Instagram Link:</strong>
                        </label>
                        <input
                            type="url"
                            name="insta_link"
                            value={newVendorData.insta_link}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>
                    <div>
                        <label>
                            <strong>Website Link:</strong>
                        </label>
                        <input
                            type="url"
                            name="website_link"
                            value={newVendorData.website_link}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    {/* Row 8 */}
                    <div className="col-span-2">
                        <label>
                            <strong>
                                Business Logo<span className="text-red-500">*</span>:
                            </strong>
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.business_logo && <p className="text-red-500">{errors.business_logo}</p>}
                    </div>
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        onClick={handleSave}
                        disabled={isAdding}
                        className="bg-blue-500 text-white px-4 py-2 rounded shadow flex items-center justify-center"
                    >
                        {isAdding ? (
                            <>
                                <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                                Saving...
                            </>
                        ) : (
                            'Save Vendor'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddVendorModal;
