export function getDateRangeFromFilter(filter) {
    const now = new Date();
    let startDate = null;
    let endDate = now;
    if (filter === "live") {
        return null;
    } else if (filter === "last_7_days") {
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    } else if (filter === "last_30_days") {
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
    } else if (filter === "last_6_months") {
        startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
    } else if (filter === "last_1_year") {
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
    } else if (filter.startsWith("custom_")) {
        const parts = filter.split("_");
        if (parts.length === 3) {
            startDate = new Date(parts[1]);
            endDate = new Date(parts[2]);
        }
    }
    return { startDate, endDate };
}
