// src/redux/reducers/revenueReducer.js
import { FETCH_REVENUE_SUCCESS, FETCH_REVENUE_ERROR } from '../types';

const initialState = {
    revenue: null, // Will store the complete revenue report structure
    error: null,
};

const revenueReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REVENUE_SUCCESS:
            return { ...state, revenue: action.payload, error: null };
        case FETCH_REVENUE_ERROR:
            return { ...state, revenue: null, error: action.payload };
        default:
            return state;
    }
};

export default revenueReducer;
