import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar"; // Ensure you have a Sidebar component

const Layout = () => {
    return (
        <div className="flex h-screen">
            <div className="fixed top-0 left-0 h-full w-64 bg-gray-800 shadow-lg">
                <Sidebar />
            </div>
            <div className="ml-64 flex-grow overflow-y-auto bg-gray-100">
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;