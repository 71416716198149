// storeLocationActions.js

import { getStoreLocationsAdmin, addStoreLocationAdmin, updateStoreLocationAdmin } from '../../api/api';
import {
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_ERROR,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_ERROR,
} from '../types';

// Fetch all locations for a specific vendor
export const fetchLocations = (vendorId) => async (dispatch) => {
  try {
    const locations = await getStoreLocationsAdmin(vendorId);
    dispatch({ type: FETCH_LOCATIONS_SUCCESS, payload: locations });
  } catch (error) {
    dispatch({ type: FETCH_LOCATIONS_ERROR, payload: error.message });
  }
};

// Add a new location
export const addLocation = (locationData) => async (dispatch) => {
  try {
    const newLocation = await addStoreLocationAdmin(locationData);
    dispatch({ type: ADD_LOCATION_SUCCESS, payload: newLocation });
    dispatch(fetchLocations(locationData.vendor_id)); // Refresh the locations list after adding
  } catch (error) {
    dispatch({ type: ADD_LOCATION_ERROR, payload: error.message });
  }
};

// Generic update
export const updateLocationAdmin = (locationId, updates) => async (dispatch) => {
  try {
    await updateStoreLocationAdmin(locationId, updates);
    // Optionally, dispatch a re-fetch or manually update the store in Redux
    // e.g. dispatch(fetchLocations(null));
  } catch (error) {
    console.error('Error updating store location:', error);
  }
};

// Toggle store location status
export const toggleLocationStatus = (locationId, newStatus) => async (dispatch) => {
  try {
    await updateStoreLocationAdmin(locationId, { active: newStatus });
    // Optionally refetch or do inline update
  } catch (error) {
    console.error('Error toggling store location status:', error);
  }
};