import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    // If children are provided, render them; otherwise, render the nested routes (Outlet)
    return children ? children : <Outlet />;
};

export default ProtectedRoute;
