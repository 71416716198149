import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const DeactivateVendorModal = ({
                                   selectedVendor,
                                   vendorIdInput,
                                   setVendorIdInput,
                                   closeDeactivateModal,
                                   handleConfirmDeactivation,
                                   isUpdatingStatus,
                               }) => {
    if (!selectedVendor) return null;

    const isActive = selectedVendor.approved_status === true;
    const vendorIdNumber = selectedVendor.vendor_id.split('_')[1] || selectedVendor.vendor_id;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg max-w-lg w-full relative">
                <button
                    onClick={closeDeactivateModal}
                    className="absolute top-2 right-2 text-gray-500"
                >
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">
                    {isActive ? 'Deactivate Vendor' : 'Activate Vendor'}
                </h2>
                <p>
                    Please enter the vendor ID <strong>{vendorIdNumber}</strong>{' '}
                    {isActive ? 'to deactivate' : 'to activate'}:
                </p>
                <input
                    type="text"
                    value={vendorIdInput}
                    onChange={(e) => setVendorIdInput(e.target.value)}
                    className="border border-gray-300 rounded w-full p-2 mt-2 mb-4"
                />
                <div className="flex justify-center space-x-2">
                    <button
                        onClick={closeDeactivateModal}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                        disabled={isUpdatingStatus}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleConfirmDeactivation}
                        className={`${
                            isActive ? 'bg-red-500' : 'bg-blue-500'
                        } text-white px-4 py-2 rounded flex items-center`}
                        disabled={vendorIdInput !== vendorIdNumber || isUpdatingStatus}
                    >
                        {isUpdatingStatus ? (
                            <>
                                <FaSpinner className="animate-spin mr-2" /> Processing...
                            </>
                        ) : (
                            isActive ? 'Deactivate' : 'Activate'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeactivateVendorModal;
