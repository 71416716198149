// src/redux/actions/revenueActions.js
import { FETCH_REVENUE_SUCCESS, FETCH_REVENUE_ERROR } from '../types';
import { getRevenueAdmin } from '../../api/api';

// Action to fetch revenue reports from the backend edge function
export const fetchRevenue = () => async (dispatch) => {
    try {
        const revenueData = await getRevenueAdmin();
        // revenueData.data should contain { subscriptions, coupons, vendor }
        dispatch({ type: FETCH_REVENUE_SUCCESS, payload: revenueData.data });
    } catch (error) {
        dispatch({ type: FETCH_REVENUE_ERROR, payload: error.message });
    }
};
