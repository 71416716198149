import React, { useState, useMemo } from 'react';
import Pagination from '../../components/Pagination';

// Helper to format a price; if value is missing or not a number, it returns "$0.00"
const formatPrice = (value) => {
    if (value === null || value === undefined) {
        return '$0.00';
    }
    const num = Number(value);
    if (isNaN(num)) {
        return '$0.00';
    }
    return `$${num.toFixed(2)}`;
};

const RevenueVendorTable = ({ data, searchTerm, showWahRevenue = true }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Group deals by vendor (vend_id) and aggregate values.
    const aggregatedData = useMemo(() => {
        const groups = {};
        data.forEach(deal => {
            const key = deal.vend_id;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(deal);
        });

        const aggregated = [];
        for (const vendId in groups) {
            const deals = groups[vendId];

            // For base deals (without upsell_id)
            const baseDeals = deals.filter(deal => !deal.upsell_id);
            const couponCount = baseDeals.reduce((sum, deal) => sum + Number(deal.coupon_count || 0), 0);
            const couponsRedeemed = baseDeals.reduce((sum, deal) => sum + Number(deal.coupons_redeemed || 0), 0);
            const baseNetRevenue = baseDeals.reduce((sum, deal) => {
                const wahDealPrice = Number(deal.wah_deal_price || 0);
                const totalFees = Number(deal.wah_fee || 0) + Number(deal.stripe_fee || 0);
                const diff = Math.max(0, wahDealPrice - totalFees);
                return sum + diff * Number(deal.coupons_redeemed || 0);
            }, 0);
            // For upsell deals:
            const upsellDeals = deals.filter(deal => deal.upsell_id);
            const upsellRevenue = upsellDeals.reduce((sum, deal) => sum + Number(deal.upsell_revenue || 0), 0);
            const vendorNetRevenue = baseNetRevenue + upsellRevenue;

            aggregated.push({
                vend_id: vendId,
                coupon_count: couponCount,
                coupons_redeemed: couponsRedeemed,
                vendor_net_revenue: vendorNetRevenue,
                deals: deals,
                vendor_name: deals[0].vendor_name // assume same vendor_name for all deals
            });
        }

        // Filter aggregated data based on searchTerm.
        let filtered = aggregated;
        if (searchTerm) {
            const lowerSearch = searchTerm.toLowerCase();
            filtered = aggregated.filter(item =>
                (item.vend_id && item.vend_id.toLowerCase().includes(lowerSearch)) ||
                (item.vendor_name && item.vendor_name.toLowerCase().includes(lowerSearch)) ||
                item.deals.some(deal =>
                    (deal.deal_id && deal.deal_id.toLowerCase().includes(lowerSearch)) ||
                    (deal.upsell_id && deal.upsell_id.toLowerCase().includes(lowerSearch))
                )
            );
        }

        // Sort aggregated data.
        if (sortConfig.key) {
            filtered.sort((a, b) => {
                let aVal = a[sortConfig.key];
                let bVal = b[sortConfig.key];
                if (typeof aVal === 'string') {
                    aVal = aVal.toLowerCase();
                    bVal = bVal.toLowerCase();
                }
                if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return filtered;
    }, [data, searchTerm, sortConfig]);

    const totalPages = Math.ceil(aggregatedData.length / itemsPerPage);
    const paginatedData = aggregatedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleSort = (key) => {
        setSortConfig(prev => {
            if (prev.key === key) {
                return { key, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
            }
            return { key, direction: 'asc' };
        });
    };

    // Modal state for vendor details.
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);

    const openModal = (vendor) => {
        setSelectedVendor(vendor);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedVendor(null);
        setModalOpen(false);
    };

    // Helper to compute vendor net revenue for an individual deal.
    const computeDealVendorNetRevenue = (deal) => {
        if (deal.upsell_id) {
            return Number(deal.upsell_revenue || 0);
        } else {
            const wahDealPrice = Number(deal.wah_deal_price || 0);
            const totalFees = Number(deal.wah_fee || 0) + Number(deal.stripe_fee || 0);
            const diff = Math.max(0, wahDealPrice - totalFees);
            return diff * Number(deal.coupons_redeemed || 0);
        }
    };

    return (
        <div className="border rounded shadow">
            <div className="overflow-x-auto">
                <table className="min-w-full text-left">
                    <thead>
                    <tr className="bg-gray-100 text-gray-700 uppercase text-sm">
                        <th onClick={() => handleSort('vend_id')} className="py-2 px-4 cursor-pointer">
                            Vend ID {sortConfig.key === 'vend_id' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('coupon_count')} className="py-2 px-4 cursor-pointer">
                            Coupon Count {sortConfig.key === 'coupon_count' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('coupons_redeemed')} className="py-2 px-4 cursor-pointer">
                            Coupons Redeemed {sortConfig.key === 'coupons_redeemed' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('vendor_net_revenue')} className="py-2 px-4 cursor-pointer">
                            Vendor Net Revenue {sortConfig.key === 'vendor_net_revenue' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="py-2 px-4">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedData.map((vendor) => (
                        <tr key={vendor.vend_id} className="border-b hover:bg-gray-50">
                            <td className="py-2 px-4">
                                {vendor.vend_id} {vendor.vendor_name && (<span className="text-gray-500 text-xs">({vendor.vendor_name})</span>)}
                            </td>
                            <td className="py-2 px-4">{vendor.coupon_count}</td>
                            <td className="py-2 px-4">{vendor.coupons_redeemed}</td>
                            <td className="py-2 px-4">
                                ${Number(vendor.vendor_net_revenue).toFixed(2)}
                            </td>
                            <td className="py-2 px-4">
                                <button
                                    onClick={() => openModal(vendor)}
                                    className="bg-blue-500 text-white px-2 py-1 rounded text-xs"
                                >
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />

            {/* Modal for vendor details */}
            {modalOpen && selectedVendor && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-lg max-h-full overflow-auto">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-bold">
                                Vendor Details: {selectedVendor.vendor_name
                                ? `${selectedVendor.vendor_name} (${selectedVendor.vend_id})`
                                : selectedVendor.vend_id}
                            </h2>
                            <button onClick={closeModal} className="text-gray-600 hover:text-gray-800">
                                Close
                            </button>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Aggregated Totals</h3>
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                <div>
                                    <span className="text-gray-500 uppercase text-xs">Coupon Count</span>
                                    <div className="text-xl font-bold">{selectedVendor.coupon_count}</div>
                                </div>
                                <div>
                                    <span className="text-gray-500 uppercase text-xs">Coupons Redeemed</span>
                                    <div className="text-xl font-bold">{selectedVendor.coupons_redeemed}</div>
                                </div>
                                <div>
                                    <span className="text-gray-500 uppercase text-xs">Vendor Net Revenue</span>
                                    <div className="text-xl font-bold">
                                        ${Number(selectedVendor.vendor_net_revenue).toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6">
                            {selectedVendor.deals && (() => {
                                const baseDeals = selectedVendor.deals.filter(deal => !deal.upsell_id);
                                const upsellDeals = selectedVendor.deals.filter(deal => deal.upsell_id);
                                return (
                                    <>
                                        {baseDeals.length > 0 && (
                                            <div>
                                                <h3 className="text-lg font-semibold mb-2">Base Deal Details</h3>
                                                <table className="min-w-full text-left">
                                                    <thead>
                                                    <tr className="bg-gray-100 text-gray-700 uppercase text-sm">
                                                        <th className="py-2 px-4">Deal ID</th>
                                                        <th className="py-2 px-4">Regular Price</th>
                                                        <th className="py-2 px-4">Wah! Deal Price</th>
                                                        <th className="py-2 px-4">Wah! Fee</th>
                                                        <th className="py-2 px-4">Stripe Fee</th>
                                                        <th className="py-2 px-4">Coupon Count</th>
                                                        <th className="py-2 px-4">Coupons Redeemed</th>
                                                        <th className="py-2 px-4">Vendor Net Revenue</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {baseDeals.map((deal, idx) => (
                                                        <tr key={idx} className="border-b hover:bg-gray-50">
                                                            <td className="py-2 px-4">{deal.deal_id}</td>
                                                            <td className="py-2 px-4">{formatPrice(deal.regular_price)}</td>
                                                            <td className="py-2 px-4">{formatPrice(deal.wah_deal_price)}</td>
                                                            <td className="py-2 px-4">{formatPrice(deal.wah_fee)}</td>
                                                            <td className="py-2 px-4">{formatPrice(deal.stripe_fee)}</td>
                                                            <td className="py-2 px-4">
                                                                {deal.coupon_count !== null && deal.coupon_count !== undefined ? deal.coupon_count : '0'}
                                                            </td>
                                                            <td className="py-2 px-4">
                                                                {deal.coupons_redeemed !== null && deal.coupons_redeemed !== undefined ? deal.coupons_redeemed : '0'}
                                                            </td>
                                                            <td className="py-2 px-4">
                                                                ${Number(computeDealVendorNetRevenue(deal)).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {upsellDeals.length > 0 && (
                                            <div className="mt-4">
                                                <h3 className="text-lg font-semibold mb-2">Upsell Deal Details</h3>
                                                <table className="min-w-full text-left">
                                                    <thead>
                                                    <tr className="bg-gray-100 text-gray-700 uppercase text-sm">
                                                        <th className="py-2 px-4">Deal ID</th>
                                                        <th className="py-2 px-4">Upsell ID</th>
                                                        <th className="py-2 px-4">Regular Price</th>
                                                        <th className="py-2 px-4">Wah! Deal Price</th>
                                                        <th className="py-2 px-4">Wah! Fee</th>
                                                        <th className="py-2 px-4">Stripe Fee</th>
                                                        <th className="py-2 px-4">Coupons Redeemed</th>
                                                        <th className="py-2 px-4">Vendor Net Revenue</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {upsellDeals.map((deal, idx) => (
                                                        <tr key={idx} className="border-b hover:bg-gray-50">
                                                            <td className="py-2 px-4">{deal.deal_id}</td>
                                                            <td className="py-2 px-4">{deal.upsell_id}</td>
                                                            <td className="py-2 px-4">{formatPrice(deal.regular_price)}</td>
                                                            <td className="py-2 px-4">{formatPrice(deal.wah_deal_price)}</td>
                                                            <td className="py-2 px-4">{formatPrice(deal.wah_fee)}</td>
                                                            <td className="py-2 px-4">{formatPrice(deal.stripe_fee)}</td>
                                                            <td className="py-2 px-4">
                                                                {deal.coupons_redeemed !== null && deal.coupons_redeemed !== undefined ? deal.coupons_redeemed : '0'}
                                                            </td>
                                                            <td className="py-2 px-4">
                                                                ${Number(computeDealVendorNetRevenue(deal)).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </>
                                );
                            })()}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RevenueVendorTable;
