import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchVendors, updateVendor, addVendor } from '../../redux/actions/vendorActions';
import { uploadImageToSupabase } from '../../utils/uploadImages';
import { getCategoriesAdmin } from '../../api/api';

import VendorsSearchBar from './Vendors/VendorsSearchBar';
import VendorsTable from './Vendors/VendorsTable';
import AddVendorModal from './Vendors/AddVendorModal';
import EditVendorModal from './Vendors/EditVendorModal';
import DeactivateVendorModal from './Vendors/DeactivateVendorModal';

const Vendors = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { vendors, error } = useSelector((state) => state.vendors || {});

  // Parse query parameter "filterStatus" from URL
  const queryParams = new URLSearchParams(location.search);
  const queryFilterStatus = queryParams.get('filterStatus') || 'both';

  // Set initial filter state from query parameter
  const [filterStatus, setFilterStatus] = useState(queryFilterStatus);
  const [searchTerm, setSearchTerm] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorIdInput, setVendorIdInput] = useState('');

  // Notification banner
  const [notification, setNotification] = useState(null);

  // Form data for editing vendor
  const [vendorData, setVendorData] = useState({});
  const [logoFile, setLogoFile] = useState(null);

  // Form data for adding vendor
  const [newVendorData, setNewVendorData] = useState({
    contact_name: '',
    user_name: '',
    business_name: '',
    vendor_email: '',
    mobile_phone: '',
    category_id: '',
    address: '',
    zipcode: '',
    license_number: '',
    business_id: '',
    business_logo: '',
    facebook_link: '',
    insta_link: '',
    website_link: '',
    city: '',
    state: '',
    country: '',
  });
  const [imageFile, setImageFile] = useState(null);

  // Categories
  const [categories, setCategories] = useState([]);

  // Loading states for activation/deactivation, adding vendor, and updating vendor details
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [isAddingVendor, setIsAddingVendor] = useState(false);
  const [isUpdatingVendor, setIsUpdatingVendor] = useState(false);

  // NEW: state to determine if vendors have been fetched
  const [vendorsFetched, setVendorsFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchVendors());
      setVendorsFetched(true);
    };
    fetchData();
    fetchCategories();
  }, [dispatch]);

  const fetchCategories = async () => {
    try {
      const fetchedCategories = await getCategoriesAdmin();
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // ---------------
  // ADD VENDOR
  // ---------------
  const handleAddClick = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
    setNewVendorData({
      contact_name: '',
      user_name: '',
      business_name: '',
      vendor_email: '',
      mobile_phone: '',
      category_id: '',
      address: '',
      zipcode: '',
      license_number: '',
      business_id: '',
      business_logo: '',
      facebook_link: '',
      insta_link: '',
      website_link: '',
      city: '',
      state: '',
      country: '',
    });
    setImageFile(null);
  };

  const handleSaveNewVendor = async () => {
    setIsAddingVendor(true);
    try {
      let vendorDataToSend = { ...newVendorData };
      if (imageFile) {
        const publicUrl = await uploadImageToSupabase(imageFile);
        vendorDataToSend.business_logo = publicUrl;
      }
      // Hardcode required fields
      vendorDataToSend.user_name = 'vendor';
      vendorDataToSend.password_hash = 'password';

      // Build storeLocation object using common vendor fields plus the extra ones
      vendorDataToSend.storeLocation = {
        store_manager: newVendorData.contact_name,
        mobile_phone: newVendorData.mobile_phone,
        store_email: newVendorData.vendor_email,
        address: newVendorData.address,
        zipcode: newVendorData.zipcode,
        city: newVendorData.city,
        state: newVendorData.state,
        country: newVendorData.country,
        active: true,
      };

      await dispatch(addVendor(vendorDataToSend));
      dispatch(fetchVendors());
      setNotification('New vendor added successfully');
      closeAddModal();
    } catch (error) {
      console.error('Error adding vendor:', error);
      setNotification('Error adding vendor. Please try again.');
    }
    setIsAddingVendor(false);
  };

  // ---------------
  // EDIT VENDOR
  // ---------------
  const handleEditClick = (vendor) => {
    setSelectedVendor(vendor);
    setVendorData({ ...vendor });
    setShowEditModal(true);
    setLogoFile(null);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setSelectedVendor(null);
    setVendorData({});
    setLogoFile(null);
  };

  const handleSaveChanges = async () => {
    setIsUpdatingVendor(true);
    try {
      let updatedLogo = vendorData.business_logo;
      if (logoFile) {
        updatedLogo = await uploadImageToSupabase(logoFile);
      }
      const { business_logo, approved_status, created_at, updated_at, Deals, StoreLocations, vendor_subscription_status, ...dataToUpdate } = vendorData;
      dataToUpdate.business_logo = updatedLogo;
      await dispatch(updateVendor(selectedVendor.vendor_id, dataToUpdate, null, 'UpdateVendor'));
      setNotification('Vendor details updated successfully');
      closeEditModal();
    } catch (error) {
      console.error('Error updating vendor:', error);
      setNotification('Error updating vendor. Please try again.');
    }
    setIsUpdatingVendor(false);
  };

  // ---------------
  // ACTIVATE/DEACTIVATE
  // ---------------
  const handleStatusToggle = (vendor) => {
    const vendorHasActiveDeals = vendor.Deals?.some((deal) => deal.deal_status === 'active');
    if (vendorHasActiveDeals && vendor.approved_status) {
      setNotification('Please deactivate all active deals before deactivating the vendor.');
      return;
    }
    setSelectedVendor(vendor);
    setShowDeactivateModal(true);
  };

  const handleConfirmDeactivation = async () => {
    setIsUpdatingStatus(true);
    const isCurrentlyActive = selectedVendor.approved_status;
    await dispatch(updateVendor(selectedVendor.vendor_id, {}, isCurrentlyActive, 'changeStatus'));
    setNotification(`Vendor ${isCurrentlyActive ? 'deactivated' : 'activated'} successfully`);
    setIsUpdatingStatus(false);
    closeDeactivateModal();
  };

  const closeDeactivateModal = () => {
    setShowDeactivateModal(false);
    setVendorIdInput('');
    setSelectedVendor(null);
  };

  // ---------------
  // INPUT HANDLERS
  // ---------------
  const handleInputChange = (e, isNewVendor = false) => {
    const { name, value } = e.target;
    if (isNewVendor) {
      setNewVendorData((prev) => ({ ...prev, [name]: value }));
    } else {
      setVendorData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) setLogoFile(file);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setNewVendorData((prevData) => ({ ...prevData, business_logo: file }));
    }
  };

  const handleErrorClose = () => {
    setNotification(null);
  };

  const filteredVendors = vendors?.filter((vendor) => {
    const matchesSearchTerm =
        vendor.vendor_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (vendor.business_name || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (vendor.contact_name || "").toLowerCase().includes(searchTerm.toLowerCase());

    const matchesStatus =
        filterStatus === "both" ||
        (filterStatus === "active" && vendor.approved_status) ||
        (filterStatus === "inactive" && !vendor.approved_status);

    return matchesSearchTerm && matchesStatus;
  });

  return (
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-4">Vendors</h1>

        {notification && (
            <div className="fixed top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded shadow-lg flex items-center">
              <span>{notification}</span>
              <button className="ml-4" onClick={handleErrorClose}>
                ✖
              </button>
            </div>
        )}

        <VendorsSearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onAddVendorClick={handleAddClick}
        />

        <VendorsTable
            filteredVendors={filteredVendors}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            handleEditClick={handleEditClick}
            handleStatusToggle={handleStatusToggle}
            loading={!vendorsFetched}
        />

        {showDeactivateModal && selectedVendor && (
            <DeactivateVendorModal
                selectedVendor={selectedVendor}
                vendorIdInput={vendorIdInput}
                setVendorIdInput={setVendorIdInput}
                closeDeactivateModal={closeDeactivateModal}
                handleConfirmDeactivation={handleConfirmDeactivation}
                isUpdatingStatus={isUpdatingStatus}
            />
        )}

        {showAddModal && (
            <AddVendorModal
                categories={categories}
                newVendorData={newVendorData}
                handleInputChange={handleInputChange}
                handleImageChange={handleImageChange}
                handleSaveNewVendor={handleSaveNewVendor}
                closeAddModal={closeAddModal}
                isAdding={isAddingVendor}
            />
        )}

        {showEditModal && selectedVendor && (
            <EditVendorModal
                vendorData={vendorData}
                setVendorData={setVendorData}
                closeEditModal={closeEditModal}
                handleSaveChanges={handleSaveChanges}
                handleLogoChange={handleLogoChange}
                logoFile={logoFile}
                isUpdatingVendor={isUpdatingVendor}
            />
        )}
      </div>
  );
};

export default Vendors;
