// src/redux/actions/customerActions.js
import {
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_ERROR,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_ERROR,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_ERROR,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_ERROR,
} from '../types';

import {
    getCustomersAdmin,
    addCustomerAdmin,
    updateCustomerAdmin,
    deleteCustomerAdmin,
} from '../../api/api';

// Fetch customers
export const fetchCustomers = () => async (dispatch) => {
    try {
        const customers = await getCustomersAdmin();
        dispatch({ type: FETCH_CUSTOMERS_SUCCESS, payload: customers });
    } catch (error) {
        dispatch({ type: FETCH_CUSTOMERS_ERROR, payload: error.message });
    }
};

// Add customer
export const addCustomer = (customerData) => async (dispatch) => {
    try {
        const response = await addCustomerAdmin(customerData);
        dispatch({ type: ADD_CUSTOMER_SUCCESS, payload: response });
        // Refresh the list
        dispatch(fetchCustomers());
    } catch (error) {
        dispatch({ type: ADD_CUSTOMER_ERROR, payload: error.message });
    }
};

// Update customer
export const updateCustomer = (customerId, updates) => async (dispatch) => {
    try {
        const updatedCustomer = await updateCustomerAdmin(customerId, updates);
        dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: updatedCustomer });
        // Refresh customers list
        dispatch(fetchCustomers());
    } catch (error) {
        dispatch({ type: UPDATE_CUSTOMER_ERROR, payload: error.message });
    }
};

// Delete customer
export const deleteCustomer = (userId) => async (dispatch) => {
    try {
        await deleteCustomerAdmin(userId);
        dispatch({ type: DELETE_CUSTOMER_SUCCESS, payload: userId });
        dispatch(fetchCustomers());
    } catch (error) {
        dispatch({ type: DELETE_CUSTOMER_ERROR, payload: error.message });
    }
};
