// src/auth/SessionManager.js
import  { useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

const SessionManager = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { logout, isAuthenticated } = useAuth();
    const idleTimer = useRef(null);
    const sessionTimer = useRef(null);

    const idleTimeout = 5 * 60 * 1000; // 5 minutes in milliseconds
    const sessionTimeout = 30 * 60 * 1000; // 30 minutes in milliseconds

    // Handle logout with a useCallback to prevent unnecessary re-renders
    const handleLogout = useCallback(() => {
        // Only log out if currently authenticated
        if (isAuthenticated) {
            logout();
            // Only navigate if not already on login page
            if (location.pathname !== "/") {
                navigate("/", { replace: true });
            }
        }
    }, [logout, navigate, location.pathname, isAuthenticated]);

    // Reset idle timer function wrapped in useCallback
    const resetIdleTimer = useCallback(() => {
        if (idleTimer.current) {
            clearTimeout(idleTimer.current);
        }

        // Only set idle timer if authenticated
        if (isAuthenticated) {
            idleTimer.current = setTimeout(() => {
                alert("Session terminated due to inactivity.");
                handleLogout();
            }, idleTimeout);
        }
    }, [idleTimeout, handleLogout, isAuthenticated]);

    // User activity handler wrapped in useCallback
    const handleUserActivity = useCallback(() => {
        resetIdleTimer();
    }, [resetIdleTimer]);

    // Initialize timers with dependencies properly tracked
    useEffect(() => {
        // Only set timers if authenticated
        if (isAuthenticated) {
            // Clear any existing timers first
            if (sessionTimer.current) clearTimeout(sessionTimer.current);
            if (idleTimer.current) clearTimeout(idleTimer.current);

            // Start overall session timer
            sessionTimer.current = setTimeout(() => {
                alert("Session expired. Redirecting to login.");
                handleLogout();
            }, sessionTimeout);

            // Start idle timer
            resetIdleTimer();

            // Set up event listeners for user activity
            const events = [
                'mousemove',
                'mousedown',
                'keydown',
                'touchstart',
                'scroll',
                'click'
            ];

            events.forEach(event =>
                window.addEventListener(event, handleUserActivity, true)
            );

            // Cleanup function
            return () => {
                if (idleTimer.current) clearTimeout(idleTimer.current);
                if (sessionTimer.current) clearTimeout(sessionTimer.current);

                events.forEach(event =>
                    window.removeEventListener(event, handleUserActivity, true)
                );
            };
        }
    }, [isAuthenticated, handleLogout, resetIdleTimer, handleUserActivity, sessionTimeout]);

    // Debug logging (remove in production)
    useEffect(() => {
        console.log("SessionManager mounted, auth status:", isAuthenticated);
        return () => console.log("SessionManager unmounted");
    }, [isAuthenticated]);

    return null; // No UI is rendered
};

export default SessionManager;