// src/redux/reducers/couponReducer.js
import {
    FETCH_COUPONS_SUCCESS,
    FETCH_COUPONS_ERROR,
    REGENERATE_COUPON_SUCCESS,
    REGENERATE_COUPON_ERROR,
} from "../types";

const initialState = {
    coupons: [],
    totalCount: 0,
    error: null,
};

const couponReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COUPONS_SUCCESS:
            return {
                ...state,
                coupons: action.payload.coupons,
                totalCount: action.payload.totalCount,
                error: null,
            };
        case FETCH_COUPONS_ERROR:
            return {
                ...state,
                coupons: [],
                totalCount: 0,
                error: action.payload,
            };
        case REGENERATE_COUPON_SUCCESS:
            // Update the coupon in the state with the new QR code value
            const updatedCoupons = state.coupons.map((coupon) => {
                if (coupon.coupon_id === action.payload.couponId) {
                    return { ...coupon, qr_code: action.payload.newQrCode, updated_at: new Date().toISOString() };
                }
                return coupon;
            });
            return {
                ...state,
                coupons: updatedCoupons,
                error: null,
            };
        case REGENERATE_COUPON_ERROR:
            return {
                ...state,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export default couponReducer;
