import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DateFilterDropdown from "./DateFilterDropdown";
import { getDateRangeFromFilter } from "./dateUtils";
// import Chart from "react-apexcharts";  // Charts are commented out
// import Modal from "./Modal";

const CouponsTile = ({ data }) => {
    const navigate = useNavigate();

    // Live stats from backend
    const livePublished = typeof data?.published === "number" ? data.published : 0;
    const liveActive = typeof data?.active === "number" ? data.active : 0;
    const liveRedeemed = typeof data?.redeemed === "number" ? data.redeemed : 0;
    const liveCancelled = typeof data?.cancelled === "number" ? data.cancelled : 0;

    // Raw data for filtering
    const couponsRaw = Array.isArray(data?.couponsRaw) ? data.couponsRaw : [];

    const [dateFilter, setDateFilter] = useState("live");

    // For stats, if not "live", recalc from raw coupons data
    let displayedPublished = livePublished;
    let displayedActive = liveActive;
    let displayedRedeemed = liveRedeemed;
    let displayedCancelled = liveCancelled;

    if (dateFilter !== "live") {
        const range = getDateRangeFromFilter(dateFilter);
        if (range) {
            const { startDate, endDate } = range;
            const filteredRaw = couponsRaw.filter((coupon) => {
                const created = new Date(coupon.created_at);
                return created >= startDate && created <= endDate;
            });
            displayedPublished = filteredRaw.length;
            displayedActive = filteredRaw.filter(
                (c) =>
                    c.status &&
                    (c.status.toLowerCase().trim() === "active" ||
                        c.status.toLowerCase().trim() === "generated")
            ).length;
            displayedRedeemed = filteredRaw.filter(
                (c) => c.status && c.status.toLowerCase().trim() === "redeemed"
            ).length;
            displayedCancelled = filteredRaw.filter(
                (c) => c.status && c.status.toLowerCase().trim() === "cancelled"
            ).length;
        }
    }

    return (
        <div className="p-4 bg-gray-700 rounded-lg shadow-lg">
            {/* Tile Header */}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-gray-200">Coupons</h2>
                <DateFilterDropdown value={dateFilter} onChange={setDateFilter} />
            </div>

            {/* Totals Section */}
            <div className="mb-4 cursor-pointer" onClick={() => navigate("/Coupons")}>
                <div className="text-center">
                    <p className="text-5xl font-bold text-gray-200">
                        {displayedPublished.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Total Coupons</p>
                </div>
            </div>
            <div className="mb-6 grid grid-cols-3 gap-6">
                <div
                    className="text-center cursor-pointer"
                    onClick={() => navigate("/Coupons?status=active")}
                >
                    <p className="text-4xl font-bold text-gray-200">
                        {displayedActive.toLocaleString()}
                    </p>
                    <p className="text-base text-gray-400">
                        <span className="block">Active/</span>
                        <span className="block">Generated</span>
                    </p>
                </div>
                <div
                    className="text-center cursor-pointer"
                    onClick={() => navigate("/Coupons?status=redeemed")}
                >
                    <p className="text-4xl font-bold text-gray-200">
                        {displayedRedeemed.toLocaleString()}
                    </p>
                    <p className="text-base text-gray-400">Redeemed</p>
                </div>
                <div
                    className="text-center cursor-pointer"
                    onClick={() => navigate("/Coupons?status=cancelled")}
                >
                    <p className="text-4xl font-bold text-gray-200">
                        {displayedCancelled.toLocaleString()}
                    </p>
                    <p className="text-base text-gray-400">Cancelled</p>
                </div>
            </div>

            {/* Chart Section - Temporarily commented out */}
            {/*
      <div className="mt-6">
        <Chart options={lineOptions} series={lineSeries} type="line" height={300} />
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4"
          onClick={() => setShowModal(true)}
        >
          Expand Chart
        </button>
      </div>
      */}
        </div>
    );
};

export default CouponsTile;
