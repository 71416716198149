import React, { useState, useMemo } from 'react';
import { FaSpinner } from 'react-icons/fa';

const EditWalletModal = ({ wallet, onClose, onSave }) => {
    const [amount, setAmount] = useState("");
    const [operation, setOperation] = useState("credit");
    const [isLoading, setIsLoading] = useState(false);

    const parsedAmount = parseFloat(amount) || 0;
    const newBalance = useMemo(() => {
        if (operation === "credit") {
            return wallet.wallet_balance + parsedAmount;
        } else {
            return wallet.wallet_balance - parsedAmount;
        }
    }, [wallet.wallet_balance, parsedAmount, operation]);

    const handleSave = async () => {
        if (!amount || isNaN(parsedAmount) || parsedAmount <= 0) {
            alert("Please enter a valid positive number for the amount.");
            return;
        }
        setIsLoading(true);
        try {
            await onSave(wallet.wallet_id, parsedAmount, operation);
        } catch (error) {
            console.error("Error updating wallet:", error);
        }
        setIsLoading(false);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative">
                <button onClick={onClose} className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-xl">
                    &times;
                </button>
                <h2 className="text-2xl font-bold mb-6 text-center">Edit Wallet Balance</h2>
                <div className="mb-4">
                    <label className="block font-semibold mb-1">Wallet ID:</label>
                    <p className="text-gray-700">{wallet.wallet_id}</p>
                </div>
                {wallet.vendor_id ? (
                    <div className="mb-4">
                        <label className="block font-semibold mb-1">Vendor ID:</label>
                        <p className="text-gray-700">{wallet.vendor_id}</p>
                    </div>
                ) : (
                    <div className="mb-4">
                        <label className="block font-semibold mb-1">Customer ID:</label>
                        <p className="text-gray-700">{wallet.customer_id}</p>
                    </div>
                )}
                <div className="mb-4">
                    <label className="block font-semibold mb-1">Current Balance:</label>
                    <p className="text-gray-700">${wallet.wallet_balance.toFixed(2)}</p>
                </div>
                <div className="mb-4">
                    <label className="block font-semibold mb-1">Operation:</label>
                    <div className="flex space-x-4">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="operation"
                                value="credit"
                                checked={operation === 'credit'}
                                onChange={() => setOperation('credit')}
                                className="mr-2"
                            />
                            <span className="text-green-500 font-bold">Credit</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="operation"
                                value="debit"
                                checked={operation === 'debit'}
                                onChange={() => setOperation('debit')}
                                className="mr-2"
                            />
                            <span className="text-red-500 font-bold">Debit</span>
                        </label>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block font-semibold mb-1">Amount:</label>
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                        placeholder="Enter amount"
                    />
                </div>
                <div className="mb-4">
                    <label className="block font-semibold mb-1">New Balance:</label>
                    <p className="text-gray-700">${newBalance.toFixed(2)}</p>
                </div>
                <button
                    onClick={handleSave}
                    disabled={isLoading}
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded transition-colors flex items-center justify-center"
                >
                    {isLoading && <FaSpinner className="animate-spin mr-2" />}
                    Save Changes
                </button>
            </div>
        </div>
    );
};

export default EditWalletModal;
