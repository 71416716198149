import React from "react";

const CouponSearchBar = ({ searchTerm, setSearchTerm, statusFilter, setStatusFilter }) => {
    return (
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 space-y-2 md:space-y-0">
            <div className="flex space-x-2 flex-grow">
                <input
                    type="text"
                    placeholder="Search by Coupon ID, Customer ID, Deal Title, etc."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                />
                <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    className="border border-gray-300 rounded px-4 py-2"
                >
                    <option value="">All Statuses</option>
                    <option value="generated">Generated</option>
                    <option value="redeemed">Redeemed</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="expired">Expired</option>
                    <option value="active">Active</option>
                </select>
            </div>
        </div>
    );
};

export default CouponSearchBar;
