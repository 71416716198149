import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChatList from "./ChatList";
import ChatRoom from "./ChatRoom";
import NewChatModal from "./NewChatModal";
import { supabase } from "../../supabaseClient";

const ADMIN_ID = "admin_487949";

const AdminChat = () => {
    const [selectedChat, setSelectedChat] = useState(null);
    const [activeTab, setActiveTab] = useState("my"); // 'my' for admin chats, 'all' for all chats
    const [modalOpen, setModalOpen] = useState(false);
    const [preselectedParticipant, setPreselectedParticipant] = useState(null);
    const [sidebarWidth, setSidebarWidth] = useState(300);
    const resizerRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const resizer = resizerRef.current;
        let startX, startWidth;

        const handleMouseDown = (e) => {
            startX = e.clientX;
            startWidth = sidebarWidth;
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        };

        const handleMouseMove = (e) => {
            const newWidth = startWidth + (e.clientX - startX);
            if (newWidth > 200 && newWidth < 600) {
                setSidebarWidth(newWidth);
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };

        if (resizer) {
            resizer.addEventListener("mousedown", handleMouseDown);
        }
        return () => {
            if (resizer) {
                resizer.removeEventListener("mousedown", handleMouseDown);
            }
        };
    }, [sidebarWidth]);

    const handleChatCreated = (newChat) => {
        setSelectedChat(newChat);
    };

    // Function to fetch a chat session by chatId
    const fetchChatSession = async (chatId) => {
        try {
            const { data } = await supabase
                .from("ChatSession")
                .select("*")
                .eq("chat_id", chatId)
                .single();
            if (data) {
                setSelectedChat(data);
            }
        } catch (err) {
            console.error("Error fetching chat session:", err);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const chatId = queryParams.get("chatId");
        const participantId = queryParams.get("participantId");
        const participantName = queryParams.get("participantName");
        const participantType = queryParams.get("participantType");
        if (chatId) {
            fetchChatSession(chatId);
        } else if (participantId && participantType) {
            setPreselectedParticipant({
                id: participantId,
                name: participantName,
                type: participantType,
            });
            setModalOpen(true);
        }
    }, [location.search]);

    return (
        <div className="h-screen flex flex-col bg-gray-100">
            {/* Header */}
            <div className="flex items-center justify-between p-4 bg-white shadow">
                <h1 className="text-2xl font-bold text-gray-800">Admin Chat</h1>
                <button
                    onClick={() => setModalOpen(true)}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
                >
                    New Chat
                </button>
            </div>

            {/* Tabs */}
            <div className="flex border-b border-gray-300">
                <button
                    onClick={() => setActiveTab("my")}
                    className={`flex-1 text-center py-2 transition-colors duration-200 ${
                        activeTab === "my"
                            ? "border-b-4 border-blue-500 text-blue-500"
                            : "text-gray-600"
                    }`}
                >
                    My Chats
                </button>
                <button
                    onClick={() => setActiveTab("all")}
                    className={`flex-1 text-center py-2 transition-colors duration-200 ${
                        activeTab === "all"
                            ? "border-b-4 border-blue-500 text-blue-500"
                            : "text-gray-600"
                    }`}
                >
                    All Chats
                </button>
            </div>

            <div className="flex flex-1 overflow-hidden">
                {/* Chat List Sidebar with Resizable Divider */}
                <div className="relative" style={{ width: sidebarWidth }}>
                    <ChatList
                        onSelectChat={setSelectedChat}
                        selectedChatId={selectedChat ? selectedChat.chat_id : null}
                        filterByAdmin={activeTab === "my"}
                        adminId={ADMIN_ID}
                    />
                    <div
                        ref={resizerRef}
                        className="absolute right-0 top-0 h-full w-2 cursor-col-resize bg-gray-300"
                    />
                </div>

                {/* Chat Room */}
                <div className="flex-1">
                    {selectedChat ? (
                        <ChatRoom chat={selectedChat} adminId={ADMIN_ID} />
                    ) : (
                        <div className="h-full flex items-center justify-center bg-gray-50">
                            <p className="text-xl text-gray-600">Select a chat to start messaging</p>
                        </div>
                    )}
                </div>
            </div>

            {/* New Chat Modal */}
            {modalOpen && (
                <NewChatModal
                    onClose={() => {
                        setModalOpen(false);
                        setPreselectedParticipant(null);
                        // Clear query params if needed
                        navigate("/adminChat", { replace: true });
                    }}
                    onChatCreated={handleChatCreated}
                    adminId={ADMIN_ID}
                    preselectedParticipant={preselectedParticipant}
                />
            )}
        </div>
    );
};

export default AdminChat;
