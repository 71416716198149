// ChatList.jsx
import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';

const ChatListItem = ({ chat, adminId, participantName, isActive, onSelect }) => {
    // Determine the other participant's ID.
    const otherId = chat.participant_1 === adminId ? chat.participant_2 : chat.participant_1;

    return (
        <div
            onClick={() => onSelect(chat)}
            className={`flex items-center justify-between p-2 mb-2 rounded cursor-pointer transition-colors duration-150 
        ${isActive
                ? 'bg-blue-100 text-gray-900 border border-blue-300'
                : 'bg-gray-50 text-gray-800 border border-transparent hover:bg-blue-50'
            }`}
        >
            <div>
                <p className="font-semibold text-sm">{participantName || otherId}</p>
                <p className="text-xs text-gray-500">{otherId}</p>
            </div>
            {chat.unread_count > 0 && (
                <div className="flex items-center justify-center w-5 h-5 bg-red-500 text-white text-xs rounded-full">
                    {chat.unread_count > 9 ? '9+' : chat.unread_count}
                </div>
            )}
        </div>
    );
};

const ChatList = ({ onSelectChat, selectedChatId, filterByAdmin, adminId }) => {
    const [chatList, setChatList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    // Cache for participant names: key is the participant id, value is the name.
    const [participantNameCache, setParticipantNameCache] = useState({});

    useEffect(() => {
        fetchChatList();

        // Subscribe to realtime updates for ChatSession table.
        const subscription = supabase
            .channel('public:ChatSession')
            .on(
                'postgres_changes',
                { event: 'UPDATE', schema: 'public', table: 'ChatSession' },
                () => {
                    fetchChatList();
                }
            )
            .subscribe();

        return () => {
            supabase.removeChannel(subscription);
        };
    }, [filterByAdmin]);

    const fetchChatList = async () => {
        try {
            let query = supabase.from('ChatSession').select('*');
            if (filterByAdmin) {
                // Only fetch chats where the admin is a participant.
                query = query.or(`participant_1.eq.${adminId},participant_2.eq.${adminId}`);
            }
            const { data, error } = await query;
            if (error) {
                console.error("Error fetching chats:", error);
                return;
            }
            if (data) {
                setChatList(data);
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };

    // Helper function: fetch the participant's name by ID.
    const fetchParticipantName = async (id) => {
        try {
            // First, try fetching from Vendors table.
            const { data: vendorData } = await supabase
                .from('Vendors')
                .select('business_name')
                .eq('vendor_id', id)
                .single();
            if (vendorData && vendorData.business_name) {
                return vendorData.business_name;
            }
            // If not found in Vendors, try the Customers table.
            const { data: customerData } = await supabase
                .from('Customers')
                .select('first_name, last_name')
                .eq('customer_id', id)
                .single();
            if (customerData) {
                return `${customerData.first_name} ${customerData.last_name}`;
            }
            return id;
        } catch (err) {
            console.error("Error fetching participant name for id:", id, err);
            return id;
        }
    };

    // When the chat list changes, update the participant name cache.
    useEffect(() => {
        const idsToFetch = new Set();
        chatList.forEach(chat => {
            const otherId = chat.participant_1 === adminId ? chat.participant_2 : chat.participant_1;
            if (!participantNameCache[otherId]) {
                idsToFetch.add(otherId);
            }
        });
        if (idsToFetch.size > 0) {
            Promise.all(
                Array.from(idsToFetch).map(id =>
                    fetchParticipantName(id).then(name => ({ id, name }))
                )
            ).then(results => {
                setParticipantNameCache(prevCache => {
                    const newCache = { ...prevCache };
                    results.forEach(({ id, name }) => {
                        newCache[id] = name;
                    });
                    return newCache;
                });
            });
        }
    }, [chatList, adminId, participantNameCache]);

    // Sort chats by last_message_time descending.
    const sortedChats = [...chatList].sort((a, b) => {
        const timeA = a.last_message_time ? new Date(a.last_message_time) : new Date(0);
        const timeB = b.last_message_time ? new Date(b.last_message_time) : new Date(0);
        return timeB - timeA;
    });

    // Filter chats based on the search term.
    const filteredChats = sortedChats.filter(chat => {
        const otherId = chat.participant_1 === adminId ? chat.participant_2 : chat.participant_1;
        const otherName = participantNameCache[otherId] || otherId;
        return (
            otherId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            otherName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (chat.last_message && chat.last_message.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });

    return (
        <div className="p-2">
            <input
                type="text"
                placeholder="Search chats..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-2 mb-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-300 transition text-sm"
            />
            {filteredChats.length === 0 ? (
                <p className="text-gray-500 text-sm">No chats found.</p>
            ) : (
                filteredChats.map(chat => {
                    const isActive = selectedChatId === chat.chat_id;
                    const otherId = chat.participant_1 === adminId ? chat.participant_2 : chat.participant_1;
                    const participantName = participantNameCache[otherId] || otherId;
                    return (
                        <ChatListItem
                            key={chat.chat_id}
                            chat={chat}
                            adminId={adminId}
                            participantName={participantName}
                            isActive={isActive}
                            onSelect={onSelectChat}
                        />
                    );
                })
            )}
        </div>
    );
};

export default ChatList;
