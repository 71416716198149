import React, { useState, useMemo } from "react";
import {
    FaChevronRight,
    FaChevronDown,
    FaCommentDots,
    FaSpinner,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import Pagination from "../../components/Pagination";
import CustomerRealizedValueModal from "./CustomerRealizedValueModal";

const CustomersTable = ({ filteredCustomers, handleEditClick, handleDeleteClick, loading }) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const adminId = "admin_487949";

    // New state for the realized value modal
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCustomerForModal, setSelectedCustomerForModal] = useState(null);

    const handleRowExpand = (customerId) => {
        setExpandedRows((prev) =>
            prev.includes(customerId)
                ? prev.filter((id) => id !== customerId)
                : [...prev, customerId]
        );
    };

    const handleSort = (key) => {
        setSortConfig((prev) => {
            if (prev.key === key) {
                return { key, direction: prev.direction === "asc" ? "desc" : "asc" };
            }
            return { key, direction: "asc" };
        });
    };

    const sortedCustomers = useMemo(() => {
        if (!filteredCustomers) return [];
        const { key, direction } = sortConfig;
        const items = [...filteredCustomers];
        if (key) {
            items.sort((a, b) => {
                let aVal = a[key];
                let bVal = b[key];
                if (typeof aVal === "string") aVal = aVal.toLowerCase();
                if (typeof bVal === "string") bVal = bVal.toLowerCase();
                if (aVal < bVal) return direction === "asc" ? -1 : 1;
                if (aVal > bVal) return direction === "asc" ? 1 : -1;
                return 0;
            });
        }
        return items;
    }, [filteredCustomers, sortConfig]);

    const currentCustomers = useMemo(
        () => sortedCustomers.slice((currentPage - 1) * 10, currentPage * 10),
        [sortedCustomers, currentPage]
    );

    const totalPages = Math.ceil(sortedCustomers.length / 10);

    const handleChat = async (customer) => {
        const participantId = customer.customer_id;
        try {
            const { data } = await supabase
                .from("ChatSession")
                .select("*")
                .eq("participant_1", adminId)
                .eq("participant_2", participantId)
                .single();
            if (data) {
                navigate(`/adminChat?chatId=${data.chat_id}`);
            } else {
                const participantName = customer.full_name || "";
                navigate(
                    `/adminChat?participantId=${participantId}&participantName=${encodeURIComponent(
                        participantName
                    )}&participantType=customer`
                );
            }
        } catch (err) {
            console.error("Error checking chat session:", err);
        }
    };

    // Handler for clicking the Customer Realized Value cell (opens modal)
    const handleRealizedValueClick = (customer) => {
        setSelectedCustomerForModal(customer);
        setModalOpen(true);
    };

    // Handler for clicking Coupon Count cell to navigate to Coupons page
    const handleCouponCountClick = (customer) => {
        navigate(`/coupons?customerId=${customer.customer_id}`);
    };

    // Handler for clicking Wallet Balance cell to navigate to Wallet page
    const handleWalletBalanceClick = (customer) => {
        navigate(`/wallet/customer?customerId=${customer.customer_id}`);
    };

    // Handler for clicking Subscription Status cell to navigate to subscriptions page with customerId param
    const handleSubscriptionStatusClick = (customer) => {
        navigate(`/subscriptions?customerId=${customer.customer_id}`);
    };

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full text-left">
                <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th
                        onClick={() => handleSort("customer_id")}
                        className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                    >
                        Customer ID {sortConfig.key === "customer_id" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                    </th>
                    <th
                        onClick={() => handleSort("full_name")}
                        className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                    >
                        Customer Name {sortConfig.key === "full_name" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                    </th>
                    <th
                        onClick={() => handleSort("account_status")}
                        className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                    >
                        Account Status {sortConfig.key === "account_status" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                    </th>
                    <th
                        onClick={() => handleSort("subscription_status")}
                        className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                    >
                        Subscription Status {sortConfig.key === "subscription_status" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                    </th>
                    <th className="py-3 px-6 font-semibold text-center select-none">
                        Total Coupon Count
                    </th>
                    <th className="py-3 px-6 font-semibold text-center select-none">
                        Wallet Balance
                    </th>
                    <th
                        onClick={() => handleSort("customer_net_savings")}
                        className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                    >
                        Customer Value Realized {sortConfig.key === "customer_net_savings" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                    </th>
                    <th
                        onClick={() => handleSort("wah_revenue_generated")}
                        className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                    >
                        Wah! Revenue (Coupons Sale) {sortConfig.key === "wah_revenue_generated" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                    </th>
                    <th className="py-3 px-6 font-semibold text-left">Actions</th>
                </tr>
                </thead>
                <tbody className="text-gray-700 text-sm font-light">
                {loading ? (
                    <tr>
                        <td colSpan={9} className="text-center py-6">
                            <div className="flex items-center justify-center space-x-2">
                                <FaSpinner className="animate-spin" size={24} />
                                <span>Loading customers, please wait...</span>
                            </div>
                        </td>
                    </tr>
                ) : currentCustomers.length > 0 ? (
                    currentCustomers.map((customer) => {
                        const isExpanded = expandedRows.includes(customer.customer_id);
                        return (
                            <React.Fragment key={customer.customer_id}>
                                <tr className="border-b border-gray-200 hover:bg-gray-100">
                                    <td className="py-3 px-6 font-medium text-blue-600 text-center">
                                        <div className="flex items-center justify-center space-x-2">
                                            <button
                                                onClick={() => handleRowExpand(customer.customer_id)}
                                                className="focus:outline-none"
                                                title={isExpanded ? "Collapse details" : "Expand details"}
                                            >
                                                {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                                            </button>
                                            <span>{customer.customer_id}</span>
                                        </div>
                                    </td>
                                    <td className="py-3 px-6 text-center">{customer.full_name || "N/A"}</td>
                                    <td className="py-3 px-6 text-center">{customer.account_status || "N/A"}</td>
                                    <td className="py-3 px-6 text-center">
                                        {customer.subscription_status == null ? (
                                            "NO"
                                        ) : (
                                            <span
                                                onClick={() => handleSubscriptionStatusClick(customer)}
                                                className="cursor-pointer text-blue-600 hover:underline"
                                            >
                                                    {customer.subscription_status}
                                                </span>
                                        )}
                                    </td>
                                    <td
                                        className="py-3 px-6 text-center cursor-pointer text-blue-600 hover:underline"
                                        onClick={() => handleCouponCountClick(customer)}
                                    >
                                        {customer.total_coupon_count || 0}
                                    </td>
                                    <td
                                        className="py-3 px-6 text-center cursor-pointer text-blue-600 hover:underline"
                                        onClick={() => handleWalletBalanceClick(customer)}
                                    >
                                        ${Number(customer.wallet_balance).toFixed(2)}
                                    </td>
                                    <td
                                        className="py-3 px-6 text-center cursor-pointer text-blue-600 hover:underline"
                                        onClick={() => handleRealizedValueClick(customer)}
                                    >
                                        {isNaN(customer.customer_net_savings)
                                            ? "N/A"
                                            : `$${Number(customer.customer_net_savings).toFixed(2)}`}
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        {isNaN(customer.wah_revenue_generated)
                                            ? "N/A"
                                            : `$${Number(customer.wah_revenue_generated).toFixed(2)}`}
                                    </td>
                                    <td className="py-3 px-6">
                                        <div className="flex items-center space-x-4">
                                            <button
                                                onClick={() => handleEditClick(customer)}
                                                className="text-blue-500 font-medium"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() => handleDeleteClick(customer)}
                                                className="bg-red-500 text-white font-medium px-3 py-2 rounded hover:opacity-80 transition-opacity"
                                            >
                                                Delete
                                            </button>
                                            <button
                                                onClick={() => handleChat(customer)}
                                                className="font-medium px-2 py-2 rounded transition-opacity hover:opacity-80 bg-purple-500 text-white flex items-center justify-center w-12 h-full"
                                                title="Chat"
                                            >
                                                <FaCommentDots size={20} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                {isExpanded && (
                                    <tr>
                                        <td colSpan={9}>
                                            <div className="bg-white border border-gray-200 rounded-md shadow-sm p-6">
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                    <div className="space-y-2">
                                                        <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                                            Details
                                                        </h3>
                                                        <p>
                                                            <strong>Email:</strong> {customer.email_id || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Phone:</strong> {customer.mobile_phone || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Zipcode:</strong> {customer.zipcode || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Created At:</strong>{" "}
                                                            {customer.created_at
                                                                ? new Date(customer.created_at).toLocaleString()
                                                                : "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Subscription Status:</strong>{" "}
                                                            {customer.subscription_status || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Coupons Generated:</strong>{" "}
                                                            {customer.coupons_generated || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Coupons Redeemed:</strong>{" "}
                                                            {customer.coupons_redeemed || "N/A"}
                                                        </p>
                                                    </div>
                                                    <div className="space-y-2">
                                                        <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                                            More Info
                                                        </h3>
                                                        <p>
                                                            <strong>Address:</strong> {customer.address || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Street:</strong> {customer.street || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>City:</strong> {customer.city || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>State:</strong> {customer.state || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Country:</strong> {customer.country || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Referral ID:</strong> {customer.referral_id || "N/A"}
                                                        </p>
                                                        <p>
                                                            <strong>Sign-on Bonus:</strong> {customer.signon_bonus || "N/A"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={9} className="text-center py-6">
                            <span>No customers found.</span>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            {sortedCustomers.length > 10 && (
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
            )}
            {modalOpen && selectedCustomerForModal && (
                <CustomerRealizedValueModal
                    customer={selectedCustomerForModal}
                    onClose={() => {
                        setModalOpen(false);
                        setSelectedCustomerForModal(null);
                    }}
                />
            )}
        </div>
    );
};

export default CustomersTable;
