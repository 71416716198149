import React, { useState, useMemo } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import Pagination from '../../components/Pagination';

const RevenueSubscriptionsTable = ({ subscriptions, searchTerm, showWahRevenue = true, type = 'monthly' }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const data = type === 'monthly' ? (subscriptions.monthly || []) : (subscriptions.yearly || []);

    const processData = (data) => {
        let filtered = data.filter((item) => {
            return (
                item.subscription_plan_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.customer_id.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
        if (sortConfig.key) {
            filtered.sort((a, b) => {
                let aVal = a[sortConfig.key];
                let bVal = b[sortConfig.key];
                if (typeof aVal === 'string') {
                    aVal = aVal.toLowerCase();
                    bVal = bVal.toLowerCase();
                }
                if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return filtered;
    };

    const processedData = useMemo(() => processData(data), [data, searchTerm, sortConfig]);

    const totalPages = Math.ceil(processedData.length / itemsPerPage);
    const paginatedData = processedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleSort = (key) => {
        setSortConfig((prev) => {
            if (prev.key === key) {
                return { key, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
            }
            return { key, direction: 'asc' };
        });
    };

    return (
        <div className="border rounded shadow">
            <div className="overflow-x-auto">
                <table className="min-w-full text-left">
                    <thead>
                    <tr className="bg-gray-100 text-gray-700 uppercase text-sm">
                        <th onClick={() => handleSort('subscription_plan_id')} className="py-2 px-4 cursor-pointer">
                            Subscription Plan ID {sortConfig.key === 'subscription_plan_id' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('customer_id')} className="py-2 px-4 cursor-pointer">
                            Customer ID {sortConfig.key === 'customer_id' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="py-2 px-4">Renewal Type</th>
                        <th onClick={() => handleSort('plan_amount')} className="py-2 px-4 cursor-pointer">
                            Plan Amount
                        </th>
                        <th onClick={() => handleSort('apple_fee')} className="py-2 px-4 cursor-pointer">
                            Apple Fee
                        </th>
                        <th onClick={() => handleSort('expiry_date')} className="py-2 px-4 cursor-pointer">
                            Expiry Date
                        </th>
                        <th onClick={() => handleSort('renewal_date')} className="py-2 px-4 cursor-pointer">
                            Renewal Date
                        </th>
                        {showWahRevenue && (
                            <th onClick={() => handleSort('wah_revenue')} className="py-2 px-4 cursor-pointer">
                                Wah Revenue {sortConfig.key === 'wah_revenue' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                        )}
                        <th className="py-2 px-4">Customer Net Savings</th>
                        <th className="py-2 px-4">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedData.map((item) => (
                        <tr key={item.subscription_plan_id} className="border-b hover:bg-gray-50">
                            <td className="py-2 px-4">{item.subscription_plan_id}</td>
                            <td className="py-2 px-4">{item.customer_id}</td>
                            <td className="py-2 px-4">{item.renewal_type}</td>
                            <td className="py-2 px-4">${Number(item.plan_amount).toFixed(2)}</td>
                            <td className="py-2 px-4">
                                {(Number(item.apple_fee) * 100 / Number(item.plan_amount)).toFixed(0)}%
                            </td>
                            <td className="py-2 px-4">{new Date(item.expiry_date).toLocaleDateString()}</td>
                            <td className="py-2 px-4">{new Date(item.renewal_date).toLocaleDateString()}</td>
                            {showWahRevenue && (
                                <td className="py-2 px-4">${Number(item.wah_revenue).toFixed(2)}</td>
                            )}
                            <td className="py-2 px-4">{item.customer_net_savings}</td>
                            <td className="py-2 px-4">
                                <button className="bg-blue-500 text-white px-2 py-1 rounded text-xs">
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
        </div>
    );
};

export default RevenueSubscriptionsTable;
