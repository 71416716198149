import React from 'react';

const RevenueSearchBar = ({ searchTerm, setSearchTerm, filterSource }) => {
    return (
        <div className="mb-4">
            <input
                type="text"
                placeholder={`Search ${filterSource}...`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-2 border rounded"
            />
        </div>
    );
};

export default RevenueSearchBar;
