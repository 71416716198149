import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaSpinner } from 'react-icons/fa';

const EditDealModal = ({
                           selectedDeal,
                           dealData,
                           setDealData,
                           currentImageIndex,
                           setCurrentImageIndex,
                           closeEditModal,
                           uploadImagesAndUpdateDeal,
                           handleRemoveImageEdit,
                           handleImageChange,
                           handleInputChange,
                           handleDescriptionChangeEdit,
                           handleTermsChangeEdit,
                           isUpdatingDeal,
                       }) => {
    if (!selectedDeal) return null;

    // Handler for changing the multiple-selected store locations
    const handleLocationChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setDealData((prevData) => ({
            ...prevData,
            location_id: selectedOptions,
        }));
    };

    // Handlers for enable_feedback and enable_scheduling dropdowns
    const handleEnableFeedbackChange = (e) => {
        const val = e.target.value === 'true';
        setDealData((prevData) => ({
            ...prevData,
            enable_feedback: val,
        }));
    };

    const handleEnableSchedulingChange = (e) => {
        const val = e.target.value === 'true';
        setDealData((prevData) => ({
            ...prevData,
            enable_scheduling: val,
        }));
    };

    const nextImage = () => {
        if (dealData.deal_images.length > 0) {
            setCurrentImageIndex((prevIndex) =>
                prevIndex < dealData.deal_images.length - 1 ? prevIndex + 1 : 0
            );
        }
    };

    const prevImage = () => {
        if (dealData.deal_images.length > 0) {
            setCurrentImageIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : dealData.deal_images.length - 1
            );
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg max-w-4xl w-full flex relative">
                <button onClick={closeEditModal} className="absolute top-2 right-2 text-gray-500">
                    ✖
                </button>

                {/* Left Section - Edit Deal Images */}
                <div className="flex flex-col items-center pr-6 border-r border-gray-200">
                    <h3 className="text-lg font-bold mb-2">Edit Deal Images</h3>
                    {dealData.deal_images && dealData.deal_images.length > 0 ? (
                        <div className="relative flex flex-col items-center">
                            <img
                                src={dealData.deal_images[currentImageIndex]}
                                alt="Deal"
                                className="h-64 w-full object-cover rounded mb-4"
                            />
                            <button
                                onClick={prevImage}
                                className="absolute top-1/2 left-2 text-white bg-black bg-opacity-50 rounded-full p-2"
                            >
                                {'<'}
                            </button>
                            <button
                                onClick={nextImage}
                                className="absolute top-1/2 right-2 text-white bg-black bg-opacity-50 rounded-full p-2"
                            >
                                {'>'}
                            </button>
                            <div className="flex justify-center mt-2 space-x-1">
                                {dealData.deal_images.map((_, index) => (
                                    <span
                                        key={index}
                                        className={`h-2 w-2 rounded-full ${
                                            index === currentImageIndex ? 'bg-yellow-500' : 'bg-gray-300'
                                        }`}
                                    />
                                ))}
                            </div>
                            <button
                                onClick={() => handleRemoveImageEdit(currentImageIndex)}
                                className="bg-red-500 text-white px-2 py-1 rounded mt-2"
                            >
                                Remove Image
                            </button>
                        </div>
                    ) : (
                        <p>No images available</p>
                    )}
                    <input
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, true)}
                        className="mt-4"
                    />
                </div>

                {/* Right Section - Deal Details */}
                <div className="flex-1 pl-6 overflow-y-auto max-h-96 space-y-4">
                    <div>
                        <label>
                            <strong>Title:</strong>
                        </label>
                        <input
                            type="text"
                            name="deal_title"
                            value={dealData.deal_title}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Description:</strong>
                        </label>
                        <ReactQuill
                            value={dealData.deal_description}
                            onChange={handleDescriptionChangeEdit}
                            className="bg-white"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Terms and Conditions:</strong>
                        </label>
                        <ReactQuill
                            value={dealData.terms_and_conditions}
                            onChange={handleTermsChangeEdit}
                            className="bg-white"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Available From:</strong>
                        </label>
                        <input
                            type="date"
                            name="available_from"
                            value={dealData.available_from?.split('T')[0] || ''}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Available To:</strong>
                        </label>
                        <input
                            type="date"
                            name="available_to"
                            value={dealData.available_to?.split('T')[0] || ''}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Category:</strong>
                        </label>
                        <input
                            type="text"
                            name="category"
                            value={dealData.category_info.name || ''}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Original Price:</strong>
                        </label>
                        <input
                            type="number"
                            name="regular_price"
                            value={dealData.regular_price}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Discount:</strong>
                        </label>
                        <input
                            type="number"
                            name="discount_value"
                            value={dealData.discount_value}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Net Price:</strong>
                        </label>
                        <input
                            type="number"
                            name="wah_price"
                            value={dealData.wah_price}
                            readOnly
                            className="border border-gray-300 rounded w-full p-2 bg-gray-100 cursor-not-allowed"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Total Coupon Count:</strong>
                        </label>
                        <input
                            type="number"
                            name="coupon_count"
                            value={dealData.coupon_count}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Remaining Coupon Count:</strong>
                        </label>
                        <input
                            type="number"
                            name="coupon_remaining_count"
                            value={dealData.coupon_remaining_count}
                            readOnly
                            className="border border-gray-300 rounded w-full p-2 bg-gray-100 cursor-not-allowed"
                        />
                    </div>

                    <div>
                        <label className="block text-lg font-semibold text-gray-800 mb-2">
                            Store Locations
                        </label>

                        {/* Selected Locations List */}
                        <div className="bg-gray-100 p-3 rounded-md shadow-sm space-y-2">
                            {dealData.store_locations && dealData.store_locations.length > 0 ? (
                                dealData.store_locations.map((loc) => (
                                    <div
                                        key={loc.location_id}
                                        className="flex justify-between items-center bg-white p-3 rounded-md shadow border"
                                    >
                                        <div>
                                            <p className="text-gray-700 text-sm">
                                                <strong>ID:</strong> {loc.location_id}
                                            </p>
                                            <p className="text-gray-700 text-sm">
                                                <strong>Address:</strong> {loc.address}
                                            </p>
                                        </div>
                                        <button
                                            onClick={() =>
                                                setDealData((prevData) => ({
                                                    ...prevData,
                                                    store_locations: prevData.store_locations.filter(
                                                        (location) => location.location_id !== loc.location_id
                                                    ),
                                                }))
                                            }
                                            className="text-red-500 hover:text-red-700 transition font-semibold"
                                        >
                                            ✖
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-600 text-sm">No store locations selected.</p>
                            )}
                        </div>

                        {/* Add Location Dropdown */}
                        <div className="mt-3 flex items-center space-x-3">
                            <button
                                className="bg-blue-500 text-white px-3 py-2 rounded-md flex items-center hover:bg-blue-600 transition"
                            >
                                <span className="mr-2">+</span> Add Store Location
                            </button>
                            <select
                                onChange={(e) => {
                                    const selectedLocation = dealData.available_locations.find(
                                        (loc) => loc.location_id === e.target.value
                                    );
                                    if (selectedLocation) {
                                        setDealData((prevData) => ({
                                            ...prevData,
                                            store_locations: [...prevData.store_locations, selectedLocation],
                                        }));
                                    }
                                }}
                                className="border border-gray-300 rounded px-3 py-2"
                            >
                                <option value="">Select Location</option>
                                {dealData.available_locations &&
                                    dealData.available_locations.map((loc) => (
                                        <option key={loc.location_id} value={loc.location_id}>
                                            {loc.location_id} - {loc.address}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>

                    {/* Category Dropdown */}
                    <div>
                        <label>
                            <strong>Category:</strong>
                        </label>
                        <select
                            name="category_id"
                            value={dealData.category_id}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        >
                            <option value={dealData.category_id}>
                                {dealData.category_info?.name || 'Unknown Category'}
                            </option>
                        </select>
                    </div>

                    {/* Discount Type Dropdown */}
                    <div>
                        <label>
                            <strong>Discount Type:</strong>
                        </label>
                        <select
                            name="discount_type_id"
                            value={dealData.discount_type_id}
                            onChange={(e) => handleInputChange(e, true)}
                            className="border border-gray-300 rounded w-full p-2"
                        >
                            <option value={dealData.discount_type_id}>
                                {dealData.discount_type_name || 'Unknown Discount Type'}
                            </option>
                        </select>
                    </div>

                    {/* Enable Feedback Dropdown */}
                    <div>
                        <label>
                            <strong>Enable Feedback:</strong>
                        </label>
                        <select
                            name="enable_feedback"
                            value={dealData.enable_feedback ? 'true' : 'false'}
                            onChange={handleEnableFeedbackChange}
                            className="border border-gray-300 rounded w-full p-2"
                        >
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </select>
                    </div>

                    {/* Enable Scheduling Dropdown */}
                    <div>
                        <label>
                            <strong>Enable Scheduling:</strong>
                        </label>
                        <select
                            name="enable_scheduling"
                            value={dealData.enable_scheduling ? 'true' : 'false'}
                            onChange={handleEnableSchedulingChange}
                            className="border border-gray-300 rounded w-full p-2"
                        >
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </select>
                    </div>

                    <div>
                        <label>
                            <strong>Category Name:</strong>
                        </label>
                        <input
                            type="text"
                            name="category_name"
                            value={dealData.category_info?.name || ''}
                            disabled
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Created At:</strong>
                        </label>
                        <input
                            type="text"
                            name="created_at"
                            value={dealData.created_at?.split('T')[0] || ''}
                            disabled
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <div>
                        <label>
                            <strong>Updated At:</strong>
                        </label>
                        <input
                            type="text"
                            name="updated_at"
                            value={dealData.updated_at?.split('T')[0] || ''}
                            disabled
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>

                    <button
                        onClick={uploadImagesAndUpdateDeal}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        disabled={isUpdatingDeal}
                    >
                        {isUpdatingDeal ? (
                            <span className="flex items-center">
                                <FaSpinner className="animate-spin mr-2" /> Saving...
                            </span>
                        ) : (
                            "Save Changes"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditDealModal;
