import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DateFilterDropdown from "./DateFilterDropdown";
import { getDateRangeFromFilter } from "./dateUtils";
// import Chart from "react-apexcharts";  // Charts are temporarily commented out
// import Modal from "./Modal";

const CustomersTile = ({ data }) => {
    // Live stats from backend
    const liveRegistered = data.registered || 0;
    const liveSubscribed = data.subscribed || 0;
    const liveUnsubscribed = data.unsubscribed || 0;

    // Raw customers data for filtering
    const customersRaw = Array.isArray(data?.customersRaw) ? data.customersRaw : [];

    // Growth array for the chart (from backend)
    const cumulativeGrowth = data.growth ? data.growth : [];

    // Helper: fill missing dates in a time series
    const fillMissingDates = (dataArray, startDate, endDate, defaults) => {
        const dateMap = {};
        dataArray.forEach((item) => {
            dateMap[item.date] = item;
        });
        const result = [];
        let current = new Date(startDate);
        let prevItem = null;
        while (current <= endDate) {
            const dateStr = current.toISOString().slice(0, 10);
            if (dateMap[dateStr]) {
                prevItem = dateMap[dateStr];
                result.push(prevItem);
            } else {
                if (prevItem) {
                    result.push({ ...prevItem, date: dateStr });
                } else {
                    result.push({ date: dateStr, ...defaults });
                }
            }
            current.setDate(current.getDate() + 1);
        }
        return result;
    };

    const filterDataByDateRange = (dataArray, filter, defaults) => {
        const now = new Date();
        if (filter === "live") {
            if (dataArray.length === 0) return dataArray;
            const startDate = new Date(dataArray[0].date);
            return fillMissingDates(dataArray, startDate, now, defaults);
        }
        const range = getDateRangeFromFilter(filter);
        if (!range) return dataArray;
        const { startDate, endDate } = range;
        const filtered = dataArray.filter((item) => {
            const itemDate = new Date(item.date);
            return itemDate >= startDate && itemDate <= endDate;
        });
        return fillMissingDates(filtered, startDate, endDate, defaults);
    };

    const customersDefaults = { registered: 0, subscribed: 0 };

    const [dateFilter, setDateFilter] = useState("live");

    const filteredGrowth = filterDataByDateRange(
        cumulativeGrowth,
        dateFilter,
        customersDefaults
    );

    // For stats, if filter is not live, recalc from raw data
    let displayedRegistered = liveRegistered;
    let displayedSubscribed = liveSubscribed;
    let displayedUnsubscribed = liveUnsubscribed;

    if (dateFilter !== "live") {
        const range = getDateRangeFromFilter(dateFilter);
        if (range) {
            const { startDate, endDate } = range;
            const filteredRaw = customersRaw.filter((cust) => {
                const created = new Date(cust.created_at);
                return created >= startDate && created <= endDate;
            });
            displayedRegistered = filteredRaw.length;
            displayedSubscribed = filteredRaw.filter((c) => c.subscription_status).length;
            displayedUnsubscribed = filteredRaw.filter((c) => !c.subscription_status).length;
        }
    }

    const navigate = useNavigate();

    return (
        <div className="p-4 bg-gray-700 rounded-lg shadow-lg">
            {/* Tile Header */}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-gray-200">Customers</h2>
                <DateFilterDropdown value={dateFilter} onChange={setDateFilter} />
            </div>

            {/* Totals Section */}
            <div
                className="mb-4 cursor-pointer"
                onClick={() => navigate("/customers")}
            >
                <div className="text-center">
                    <p className="text-5xl font-bold text-gray-200">
                        {displayedRegistered.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Total Customers</p>
                </div>
            </div>
            <div className="mb-6 grid grid-cols-2 gap-4">
                <div
                    className="text-center cursor-pointer"
                    onClick={() =>
                        navigate("/customers", { state: { subscriptionFilter: "subscribed" } })
                    }
                >
                    <p className="text-4xl font-bold text-gray-200">
                        {displayedSubscribed.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Subscribed</p>
                </div>
                <div
                    className="text-center cursor-pointer"
                    onClick={() =>
                        navigate("/customers", { state: { subscriptionFilter: "unsubscribed" } })
                    }
                >
                    <p className="text-4xl font-bold text-gray-200">
                        {displayedUnsubscribed.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">PPU</p>
                </div>
            </div>

            {/* Chart Section - Temporarily commented out */}
            {/*
      <div className="mt-6">
        <Chart options={lineOptions} series={lineSeries} type="line" height={250} />
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4"
          onClick={() => setShowModal(true)}
        >
          Expand Chart
        </button>
      </div>
      */}
        </div>
    );
};

export default CustomersTile;
