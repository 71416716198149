import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addAppConfig } from "../../redux/actions/appConfigActions";

// Default fields including new ones
const defaultConfigFields = {
    app_version: "",
    maintenance_mode: false,
    maintenance_message: "",
    force_update: false,
    forceupdatemessage: "",
    vendor_free_max_deal_limit: "",
    vendor_paid_max_deal_limit: "",
    vendor_commision: "",
    customer_commision: "",
    // TC/PP fields
    wah_deal_tc: "",
    vendor_tc: "",
    customer_tc: "",
    vendor_pp: "",
    customer_pp: "",
    // Additional fields
    subscribed_customer_commision: "",
    stripe_charges: "",
    upsell_commision: "",
    isSubscription_enabled: false,
};

const AddAppConfigModal = ({ closeAddModal, existingConfig = {} }) => {
    const dispatch = useDispatch();
    const [newConfig, setNewConfig] = useState(defaultConfigFields);
    const [notification, setNotification] = useState(null);
    const [adding, setAdding] = useState(false);

    // Simulate PDF upload and return URL (replace with real API call if needed)
    const uploadPDF = async (file) => {
        const uploadBaseUrl = process.env.REACT_APP_UPLOAD_URL; // e.g. "https://jcjqmhkgkjfmvfmlgzen.supabase.co/storage/v1/object/public/wah_policies"
        return `${uploadBaseUrl}/${file.name}`;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setNewConfig((prev) => ({ ...prev, [name]: newValue }));
    };

    const handleFileChange = async (e, fieldName) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const url = await uploadPDF(file);
                setNewConfig((prev) => ({ ...prev, [fieldName]: url }));
            } catch (err) {
                console.error("Error uploading file:", err);
            }
        }
    };

    // Global: Populate all PDF fields with existing configuration values
    const handleUseExisting = () => {
        if (existingConfig && Object.keys(existingConfig).length > 0) {
            setNewConfig((prev) => ({
                ...prev,
                wah_deal_tc: existingConfig.wah_deal_tc || "",
                vendor_tc: existingConfig.vendor_tc || "",
                customer_tc: existingConfig.customer_tc || "",
                vendor_pp: existingConfig.vendor_pp || "",
                customer_pp: existingConfig.customer_pp || "",
            }));
        } else {
            setNotification("No existing configuration available to use.");
        }
    };

    // Individual: Populate a single PDF field with the existing configuration value if available.
    const handleUseExistingIndividual = (fieldName, label) => {
        if (existingConfig && existingConfig[fieldName]) {
            setNewConfig((prev) => ({ ...prev, [fieldName]: existingConfig[fieldName] }));
        } else {
            setNotification(`No existing configuration available for ${label}.`);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate required fields; in this example only app_version is required.
        if (!newConfig.app_version) {
            setNotification("Please fill out required field: app_version");
            return;
        }
        setAdding(true);
        try {
            await dispatch(addAppConfig(newConfig));
            setNotification("New configuration added successfully.");
            closeAddModal();
        } catch (err) {
            setNotification("Error adding configuration.");
        }
        setAdding(false);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-lg max-h-[90vh] overflow-y-auto relative">
                <button onClick={closeAddModal} className="absolute top-2 right-2 text-gray-500">
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">Add New Configuration</h2>
                {notification && (
                    <div className="mb-4 p-2 bg-green-100 text-green-700 rounded">{notification}</div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="space-y-6">
                        {/* TC/PP Upload Section */}
                        <div className="border p-4 rounded">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold">
                                    Terms &amp; Conditions and Privacy Policies
                                </h3>
                                <button
                                    type="button"
                                    onClick={handleUseExisting}
                                    className="bg-gray-200 text-gray-800 px-2 py-1 rounded"
                                >
                                    Use Existing PDFs
                                </button>
                            </div>
                            <div className="space-y-4">
                                {/* Wah Deal T&C */}
                                <div>
                                    <label className="block text-gray-700">Wah Deal T&amp;C</label>
                                    {newConfig.wah_deal_tc && newConfig.wah_deal_tc.startsWith("http") ? (
                                        <a
                                            href={newConfig.wah_deal_tc}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline block mb-2"
                                        >
                                            View Current PDF
                                        </a>
                                    ) : newConfig.wah_deal_tc ? (
                                        <p className="mb-2">{newConfig.wah_deal_tc}</p>
                                    ) : null}
                                    <div className="flex space-x-2">
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            onChange={(e) => handleFileChange(e, "wah_deal_tc")}
                                            className="w-full"
                                        />
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleUseExistingIndividual("wah_deal_tc", "Wah Deal T&C")
                                            }
                                            className="bg-gray-200 text-gray-800 px-2 py-1 rounded whitespace-nowrap"
                                        >
                                            Use Existing
                                        </button>
                                    </div>
                                </div>
                                {/* Vendor T&C */}
                                <div>
                                    <label className="block text-gray-700">Vendor T&amp;C</label>
                                    {newConfig.vendor_tc && newConfig.vendor_tc.startsWith("http") ? (
                                        <a
                                            href={newConfig.vendor_tc}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline block mb-2"
                                        >
                                            View Current PDF
                                        </a>
                                    ) : newConfig.vendor_tc ? (
                                        <p className="mb-2">{newConfig.vendor_tc}</p>
                                    ) : null}
                                    <div className="flex space-x-2">
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            onChange={(e) => handleFileChange(e, "vendor_tc")}
                                            className="w-full"
                                        />
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleUseExistingIndividual("vendor_tc", "Vendor T&C")
                                            }
                                            className="bg-gray-200 text-gray-800 px-2 py-1 rounded whitespace-nowrap"
                                        >
                                            Use Existing
                                        </button>
                                    </div>
                                </div>
                                {/* Customer T&C */}
                                <div>
                                    <label className="block text-gray-700">Customer T&amp;C</label>
                                    {newConfig.customer_tc && newConfig.customer_tc.startsWith("http") ? (
                                        <a
                                            href={newConfig.customer_tc}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline block mb-2"
                                        >
                                            View Current PDF
                                        </a>
                                    ) : newConfig.customer_tc ? (
                                        <p className="mb-2">{newConfig.customer_tc}</p>
                                    ) : null}
                                    <div className="flex space-x-2">
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            onChange={(e) => handleFileChange(e, "customer_tc")}
                                            className="w-full"
                                        />
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleUseExistingIndividual("customer_tc", "Customer T&C")
                                            }
                                            className="bg-gray-200 text-gray-800 px-2 py-1 rounded whitespace-nowrap"
                                        >
                                            Use Existing
                                        </button>
                                    </div>
                                </div>
                                {/* Vendor Privacy Policy */}
                                <div>
                                    <label className="block text-gray-700">Vendor Privacy Policy</label>
                                    {newConfig.vendor_pp && newConfig.vendor_pp.startsWith("http") ? (
                                        <a
                                            href={newConfig.vendor_pp}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline block mb-2"
                                        >
                                            View Current PDF
                                        </a>
                                    ) : newConfig.vendor_pp ? (
                                        <p className="mb-2">{newConfig.vendor_pp}</p>
                                    ) : null}
                                    <div className="flex space-x-2">
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            onChange={(e) => handleFileChange(e, "vendor_pp")}
                                            className="w-full"
                                        />
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleUseExistingIndividual("vendor_pp", "Vendor Privacy Policy")
                                            }
                                            className="bg-gray-200 text-gray-800 px-2 py-1 rounded whitespace-nowrap"
                                        >
                                            Use Existing
                                        </button>
                                    </div>
                                </div>
                                {/* Customer Privacy Policy */}
                                <div>
                                    <label className="block text-gray-700">Customer Privacy Policy</label>
                                    {newConfig.customer_pp && newConfig.customer_pp.startsWith("http") ? (
                                        <a
                                            href={newConfig.customer_pp}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 underline block mb-2"
                                        >
                                            View Current PDF
                                        </a>
                                    ) : newConfig.customer_pp ? (
                                        <p className="mb-2">{newConfig.customer_pp}</p>
                                    ) : null}
                                    <div className="flex space-x-2">
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            onChange={(e) => handleFileChange(e, "customer_pp")}
                                            className="w-full"
                                        />
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleUseExistingIndividual("customer_pp", "Customer Privacy Policy")
                                            }
                                            className="bg-gray-200 text-gray-800 px-2 py-1 rounded whitespace-nowrap"
                                        >
                                            Use Existing
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Basic Fields */}
                        <div>
                            <label className="block text-gray-700">App Version</label>
                            <input
                                type="text"
                                name="app_version"
                                value={newConfig.app_version}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                                required
                            />
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                name="maintenance_mode"
                                checked={newConfig.maintenance_mode}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <label className="text-gray-700">Maintenance Mode</label>
                        </div>
                        <div>
                            <label className="block text-gray-700">Maintenance Message</label>
                            <textarea
                                name="maintenance_message"
                                value={newConfig.maintenance_message}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                                rows="2"
                            ></textarea>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                name="force_update"
                                checked={newConfig.force_update}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <label className="text-gray-700">Force Update</label>
                        </div>
                        <div>
                            <label className="block text-gray-700">Force Update Message</label>
                            <textarea
                                name="forceupdatemessage"
                                value={newConfig.forceupdatemessage}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                                rows="2"
                            ></textarea>
                        </div>
                        <div>
                            <label className="block text-gray-700">Vendor Free Max Deal Limit</label>
                            <input
                                type="text"
                                name="vendor_free_max_deal_limit"
                                value={newConfig.vendor_free_max_deal_limit}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Vendor Paid Max Deal Limit</label>
                            <input
                                type="text"
                                name="vendor_paid_max_deal_limit"
                                value={newConfig.vendor_paid_max_deal_limit}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Vendor Commission</label>
                            <input
                                type="number"
                                step="0.01"
                                name="vendor_commision"
                                value={newConfig.vendor_commision}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Customer Commission</label>
                            <input
                                type="number"
                                step="0.01"
                                name="customer_commision"
                                value={newConfig.customer_commision}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        {/* Additional Fields */}
                        <div>
                            <label className="block text-gray-700">Subscribed Customer Commission</label>
                            <input
                                type="number"
                                step="0.01"
                                name="subscribed_customer_commision"
                                value={newConfig.subscribed_customer_commision}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Stripe Charges</label>
                            <input
                                type="number"
                                step="0.01"
                                name="stripe_charges"
                                value={newConfig.stripe_charges}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Upsell Commission</label>
                            <input
                                type="number"
                                step="0.01"
                                name="upsell_commision"
                                value={newConfig.upsell_commision}
                                onChange={handleChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                name="isSubscription_enabled"
                                checked={newConfig.isSubscription_enabled}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <label className="text-gray-700">Subscription Enabled</label>
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={closeAddModal}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={adding}
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                            >
                                {adding ? "Adding..." : "Add Configuration"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddAppConfigModal;
