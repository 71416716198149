// src/redux/actions/couponActions.js
import { getCouponsAdmin, regenerateCouponAdmin } from '../../api/api';
import {
    FETCH_COUPONS_SUCCESS,
    FETCH_COUPONS_ERROR,
    REGENERATE_COUPON_SUCCESS,
    REGENERATE_COUPON_ERROR,
} from "../types";

export const fetchCoupons = () => async (dispatch) => {
    try {
        const { data: coupons, totalCount } = await getCouponsAdmin();
        dispatch({
            type: FETCH_COUPONS_SUCCESS,
            payload: { coupons, totalCount },
        });
    } catch (error) {
        dispatch({ type: FETCH_COUPONS_ERROR, payload: error.message });
    }
};

export const regenerateCoupon = (couponId) => async (dispatch) => {
    try {
        const { new_qr_code } = await regenerateCouponAdmin(couponId);
        dispatch({
            type: REGENERATE_COUPON_SUCCESS,
            payload: { couponId, newQrCode: new_qr_code },
        });
    } catch (error) {
        dispatch({
            type: REGENERATE_COUPON_ERROR,
            payload: { couponId, error: error.message },
        });
    }
};
