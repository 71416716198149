import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
    fetchCustomers,
    updateCustomer,
    deleteCustomer,
} from "../../redux/actions/customerActions";
import CustomersSearchBar from "./CustomersSearchBar";
import CustomersTable from "./CustomersTable";
import EditCustomerModal from "./EditCustomerModal";
import DeleteCustomerModal from "./DeleteCustomerModal";

const Customers = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { customers, error } = useSelector((state) => state.customers || {});

    // Notification state.
    const [notification, setNotification] = useState(null);

    // Search term state.
    const [searchTerm, setSearchTerm] = useState("");

    // Subscription filter state: "all", "subscribed", "unsubscribed"
    const [subscriptionFilter, setSubscriptionFilter] = useState("all");

    // If navigation provided a filter state, update the subscription filter.
    useEffect(() => {
        if (location.state && location.state.subscriptionFilter) {
            setSubscriptionFilter(location.state.subscriptionFilter);
        }
    }, [location.state]);

    // Modal states.
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // States for editing and deleting a customer.
    const [customerData, setCustomerData] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [deleteConfirmInput, setDeleteConfirmInput] = useState("");

    // NEW: state to determine if customers have been fetched
    const [customersFetched, setCustomersFetched] = useState(false);

    // NEW: state for update loading indicator
    const [isUpdatingCustomer, setIsUpdatingCustomer] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchCustomers());
            setCustomersFetched(true);
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (notification) {
            const timer = setTimeout(() => setNotification(null), 3000);
            return () => clearTimeout(timer);
        }
    }, [notification]);

    // -----------------------
    // EDIT CUSTOMER
    // -----------------------
    const handleEditClick = (customer) => {
        setSelectedCustomer(customer);
        setCustomerData({ ...customer });
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
        setCustomerData({});
        setSelectedCustomer(null);
    };

    const handleSaveChanges = async () => {
        if (!selectedCustomer) return;
        setIsUpdatingCustomer(true);
        try {
            const { ...updates } = customerData;
            await dispatch(updateCustomer(selectedCustomer.customer_id, updates));
            setNotification("Customer details updated successfully");
            closeEditModal();
        } catch (error) {
            console.error("Error updating customer:", error);
            setNotification("Error updating customer. Please try again.");
        }
        setIsUpdatingCustomer(false);
    };

    // -----------------------
    // DELETE CUSTOMER
    // -----------------------
    const handleDeleteClick = (customer) => {
        setSelectedCustomer(customer);
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteConfirmInput("");
        setSelectedCustomer(null);
    };

    const handleConfirmDelete = () => {
        if (!selectedCustomer) return;
        const customerIdToDelete = selectedCustomer.customer_id;
        if (!customerIdToDelete) {
            setNotification("No customer ID found to delete this customer.");
            closeDeleteModal();
            return;
        }
        dispatch(deleteCustomer(customerIdToDelete));
        setNotification("Customer deleted successfully");
        closeDeleteModal();
    };

    // ---------------
    // SEARCH / FILTER
    // ---------------
    const filteredCustomers = customers?.filter((customer) => {
        const term = searchTerm.toLowerCase();
        const matchesText =
            (customer.full_name || "").toLowerCase().includes(term) ||
            customer.customer_id.toLowerCase().includes(term) ||
            (customer.email_id || "").toLowerCase().includes(term) ||
            (customer.mobile_phone || "").toLowerCase().includes(term);

        let matchesSubscription = true;
        const status = customer.subscription_status ? customer.subscription_status.trim().toLowerCase() : "";
        if (subscriptionFilter === "subscribed") {
            matchesSubscription = status === "subscribed";
        } else if (subscriptionFilter === "unsubscribed") {
            matchesSubscription = status === "unsubscribed" || status === "";
        }
        return matchesText && matchesSubscription;
    });

    const handleErrorClose = () => {
        setNotification(null);
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Customers</h1>

            {/* Notification Banner */}
            {notification && (
                <div className="fixed top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded shadow-lg flex items-center">
                    <span>{notification}</span>
                    <button className="ml-4" onClick={handleErrorClose}>
                        ✖
                    </button>
                </div>
            )}

            {/* Search Bar */}
            <CustomersSearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                subscriptionFilter={subscriptionFilter}
                setSubscriptionFilter={setSubscriptionFilter}
            />

            {/* Customers Table */}
            <CustomersTable
                filteredCustomers={filteredCustomers}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                loading={!customersFetched}
            />

            {/* Edit Customer Modal */}
            {showEditModal && selectedCustomer && (
                <EditCustomerModal
                    customerData={customerData}
                    setCustomerData={setCustomerData}
                    closeEditModal={closeEditModal}
                    handleSaveChanges={handleSaveChanges}
                    isUpdatingCustomer={isUpdatingCustomer}
                />
            )}

            {/* Delete Customer Modal */}
            {showDeleteModal && selectedCustomer && (
                <DeleteCustomerModal
                    selectedCustomer={selectedCustomer}
                    deleteConfirmInput={deleteConfirmInput}
                    setDeleteConfirmInput={setDeleteConfirmInput}
                    closeDeleteModal={closeDeleteModal}
                    handleConfirmDelete={handleConfirmDelete}
                />
            )}
        </div>
    );
};

export default Customers;
