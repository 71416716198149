import axios from 'axios';

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_BUCKET = 'business_logos';
const SUPABASE_KEY = process.env.REACT_APP_UPLOAD_KEY

export const uploadImageToSupabase = async (file) => {
  try {
    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random().toString(36).slice(2)}-${Date.now()}.${fileExt}`;
    const filePath = `${SUPABASE_BUCKET}/${fileName}`;

    const formData = new FormData();
    formData.append('file', file);

    const { data, error } = await axios.post(
      `${SUPABASE_URL}storage/v1/object/${SUPABASE_BUCKET}/${fileName}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${SUPABASE_KEY}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (error) {
      throw new Error(error.message);
    }

    const publicUrl = `${SUPABASE_URL}/storage/v1/object/public/${filePath}`;
    return publicUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};
