import React, { useState } from 'react';

const AddLocationModal = ({
                              vendors,
                              newLocationData,
                              setNewLocationData,
                              closeAddModal,
                              handleAddLocation,
                              isAdding, // loading state for adding location
                          }) => {
    // State for errors
    const [errors, setErrors] = useState({});

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewLocationData((prev) => ({ ...prev, [name]: value }));
    };

    // Function to fetch location details from zippopotam and auto-fill fields
    const handleZipcodeBlur = async () => {
        if (newLocationData.zipcode) {
            // Use country if it's a two-letter code, otherwise default to 'us'
            const countryCode =
                newLocationData.country && newLocationData.country.length === 2
                    ? newLocationData.country.toLowerCase()
                    : 'us';
            try {
                const response = await fetch(`http://api.zippopotam.us/${countryCode}/${newLocationData.zipcode}`);
                if (!response.ok) {
                    // If no results, you could optionally set an error message here
                    return;
                }
                const data = await response.json();
                if (data.places && data.places.length > 0) {
                    const place = data.places[0];
                    setNewLocationData((prev) => ({
                        ...prev,
                        city: place['place name'] || prev.city,
                        state: place['state'] || prev.state,
                        country: data['country abbreviation'] || prev.country,
                    }));
                }
            } catch (error) {
                console.error('Error fetching zip data:', error);
            }
        }
    };

    // Validate required fields
    const validateFields = () => {
        const newErrors = {};

        if (!newLocationData.vendor_id) {
            newErrors.vendor_id = 'Vendor is required';
        }
        if (!newLocationData.store_manager) {
            newErrors.store_manager = 'Store Manager is required';
        }
        if (!newLocationData.mobile_phone) {
            newErrors.mobile_phone = 'Mobile Phone is required';
        }
        if (!newLocationData.store_email) {
            newErrors.store_email = 'Store Email is required';
        }
        if (!newLocationData.address) {
            newErrors.address = 'Address is required';
        }
        if (!newLocationData.street) {
            newErrors.street = 'Street is required';
        }
        if (!newLocationData.city) {
            newErrors.city = 'City is required';
        }
        if (!newLocationData.state) {
            newErrors.state = 'State is required';
        }
        if (!newLocationData.country) {
            newErrors.country = 'Country is required';
        }
        if (!newLocationData.zipcode) {
            newErrors.zipcode = 'Zipcode is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Local handler to validate, then call parent’s handleAddLocation
    const handleSave = () => {
        if (validateFields()) {
            handleAddLocation();
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            {/* Scrollable container with max height */}
            <div className="bg-white p-6 rounded-lg max-w-lg w-full relative max-h-[80vh] overflow-y-auto">
                <button onClick={closeAddModal} className="absolute top-2 right-2 text-gray-500">
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">Add New Store Location</h2>

                {/* Vendor dropdown */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">
                        Vendor <span className="text-red-500">*</span>
                    </label>
                    <select
                        name="vendor_id"
                        value={newLocationData.vendor_id || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    >
                        <option value="">Select vendor</option>
                        {vendors?.map((v) => (
                            <option key={v.vendor_id} value={v.vendor_id}>
                                {v.business_name} ({v.vendor_id})
                            </option>
                        ))}
                    </select>
                    {errors.vendor_id && <p className="text-red-500">{errors.vendor_id}</p>}
                </div>

                {/* Store Manager */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">
                        Store Manager <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="store_manager"
                        value={newLocationData.store_manager || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                    {errors.store_manager && <p className="text-red-500">{errors.store_manager}</p>}
                </div>

                {/* Mobile Phone */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">
                        Mobile Phone <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="mobile_phone"
                        value={newLocationData.mobile_phone || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                    {errors.mobile_phone && <p className="text-red-500">{errors.mobile_phone}</p>}
                </div>

                {/* Store Email */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">
                        Store Email <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="email"
                        name="store_email"
                        value={newLocationData.store_email || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                    {errors.store_email && <p className="text-red-500">{errors.store_email}</p>}
                </div>

                {/* Address */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">
                        Address <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="address"
                        value={newLocationData.address || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                    {errors.address && <p className="text-red-500">{errors.address}</p>}
                </div>

                {/* Street */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">
                        Street <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="street"
                        value={newLocationData.street || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                    {errors.street && <p className="text-red-500">{errors.street}</p>}
                </div>

                {/* City */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">
                        City <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="city"
                        value={newLocationData.city || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                        readOnly
                    />
                    {errors.city && <p className="text-red-500">{errors.city}</p>}
                </div>

                {/* State */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">
                        State <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="state"
                        value={newLocationData.state || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                        readOnly
                    />
                    {errors.state && <p className="text-red-500">{errors.state}</p>}
                </div>

                {/* Country */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">
                        Country <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="country"
                        value={newLocationData.country || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                        readOnly
                    />
                    {errors.country && <p className="text-red-500">{errors.country}</p>}
                </div>

                {/* Zipcode */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">
                        Zipcode <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="number"
                        name="zipcode"
                        value={newLocationData.zipcode || ''}
                        onChange={handleChange}
                        onBlur={handleZipcodeBlur}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                    {errors.zipcode && <p className="text-red-500">{errors.zipcode}</p>}
                </div>

                {/* Save button with spinner */}
                <button
                    onClick={handleSave}
                    disabled={isAdding}
                    className="bg-blue-500 text-white px-4 py-2 rounded mt-2 flex items-center justify-center"
                >
                    {isAdding ? (
                        <>
                            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                ></path>
                            </svg>
                            Adding...
                        </>
                    ) : (
                        'Add Store'
                    )}
                </button>
            </div>
        </div>
    );
};

export default AddLocationModal;