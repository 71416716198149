import React, { useState, useMemo, useEffect } from "react";
import {
    FaChevronRight,
    FaChevronDown,
    FaCommentDots,
    FaSpinner,
    FaAngleDoubleLeft,
    FaAngleLeft,
    FaAngleRight,
    FaAngleDoubleRight,
    FaArrowRight,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../../supabaseClient";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const [jumpPage, setJumpPage] = useState(currentPage);

    useEffect(() => {
        setJumpPage(currentPage);
    }, [currentPage]);

    if (totalPages <= 1) return null;

    const getPageNumbers = (current, total) => {
        const delta = 2;
        const pages = [];
        pages.push(1);

        if (current - delta > 2) {
            pages.push("left-ellipsis");
        } else {
            for (let i = 2; i < Math.max(2, current - delta); i++) {
                pages.push(i);
            }
        }

        for (let i = Math.max(2, current - delta); i <= Math.min(total - 1, current + delta); i++) {
            pages.push(i);
        }

        if (current + delta < total - 1) {
            pages.push("right-ellipsis");
        } else {
            for (let i = Math.min(total - 1, current + delta) + 1; i < total; i++) {
                pages.push(i);
            }
        }

        if (total > 1) {
            pages.push(total);
        }

        return pages;
    };

    const pages = getPageNumbers(currentPage, totalPages);

    const handleJumpSubmit = (e) => {
        e.preventDefault();
        const page = Number(jumpPage);
        if (page >= 1 && page <= totalPages && page !== currentPage) {
            onPageChange(page);
        }
    };

    return (
        <div className="flex justify-center mt-6">
            <div className="flex items-center space-x-2">
                <button
                    onClick={() => onPageChange(1)}
                    disabled={currentPage === 1}
                    title="First Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleDoubleLeft size={18} />
                </button>
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    title="Previous Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleLeft size={18} />
                </button>
                {pages.map((page, idx) =>
                        page === "left-ellipsis" || page === "right-ellipsis" ? (
                            <span key={idx} className="px-3 py-2">
              &hellip;
            </span>
                        ) : (
                            <button
                                key={page}
                                onClick={() => onPageChange(page)}
                                className={`px-4 py-2 rounded transition-colors ${
                                    page === currentPage
                                        ? "bg-blue-500 text-white font-semibold"
                                        : "bg-gray-200 hover:bg-gray-300"
                                }`}
                            >
                                {page}
                            </button>
                        )
                )}
                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    title="Next Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleRight size={18} />
                </button>
                <button
                    onClick={() => onPageChange(totalPages)}
                    disabled={currentPage === totalPages}
                    title="Last Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleDoubleRight size={18} />
                </button>
                <form onSubmit={handleJumpSubmit} className="flex items-center border border-gray-300 rounded overflow-hidden">
                    <input
                        type="number"
                        min="1"
                        max={totalPages}
                        value={jumpPage}
                        onChange={(e) => setJumpPage(e.target.value)}
                        placeholder="Page"
                        className="w-16 px-2 py-2 text-center outline-none"
                    />
                    <button
                        type="submit"
                        className="px-3 py-2 bg-blue-500 text-white hover:bg-blue-600 transition-colors"
                        title="Go to Page"
                    >
                        <FaArrowRight size={14} />
                    </button>
                </form>
            </div>
        </div>
    );
};

const VendorsTable = ({
                          filteredVendors,
                          filterStatus,
                          setFilterStatus,
                          handleEditClick,
                          handleStatusToggle,
                          loading,
                          modalOpen = false,
                      }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const navigate = useNavigate();
    const adminId = "admin_487949";

    // Helper to format numbers as currency.
    const formatCurrency = (num) => `$${Number(num).toFixed(2)}`;

    const handleSort = (key) => {
        setSortConfig((prev) => {
            if (prev.key === key) {
                return { key, direction: prev.direction === "asc" ? "desc" : "asc" };
            }
            return { key, direction: "asc" };
        });
        setCurrentPage(1);
    };

    const vendorsToDisplay = useMemo(() => {
        if (!filteredVendors) return [];
        if (filterStatus === "active") {
            return filteredVendors.filter((vendor) => vendor.approved_status);
        } else if (filterStatus === "inactive") {
            return filteredVendors.filter((vendor) => !vendor.approved_status);
        }
        return filteredVendors;
    }, [filteredVendors, filterStatus]);

    const sortedVendors = useMemo(() => {
        if (!vendorsToDisplay) return [];
        const { key, direction } = sortConfig;
        const items = [...vendorsToDisplay];
        if (key) {
            items.sort((a, b) => {
                let aVal, bVal;
                switch (key) {
                    case "vendorId":
                        aVal = a.vendor_id.toLowerCase();
                        bVal = b.vendor_id.toLowerCase();
                        break;
                    case "businessName":
                        aVal = a.business_name.toLowerCase();
                        bVal = b.business_name.toLowerCase();
                        break;
                    case "contactName":
                        aVal = (a.contact_name || "").toLowerCase();
                        bVal = (b.contact_name || "").toLowerCase();
                        break;
                    case "status":
                        aVal = a.approved_status ? "active" : "inactive";
                        bVal = b.approved_status ? "active" : "inactive";
                        break;
                    default:
                        return 0;
                }
                if (aVal < bVal) return direction === "asc" ? -1 : 1;
                if (aVal > bVal) return direction === "asc" ? 1 : -1;
                return 0;
            });
        }
        return items;
    }, [vendorsToDisplay, sortConfig]);

    const currentVendors = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize;
        return sortedVendors.slice(startIndex, startIndex + pageSize);
    }, [sortedVendors, currentPage]);

    const totalPages = Math.ceil(sortedVendors.length / pageSize);

    // Navigation helpers for clickable metric cells:
    const goToDeals = (vendorId) => {
        navigate(`/deals?vendorId=${vendorId}`);
    };
    const goToCoupons = (vendorId) => {
        navigate(`/coupons?vendorId=${vendorId}`);
    };
    const goToRedeemedCoupons = (vendorId) => {
        navigate(`/coupons?vendorId=${vendorId}&status=redeemed`);
    };
    const goToWallet = (vendorId) => {
        navigate(`/wallet/vendor?vendorId=${vendorId}`);
    };

    const handleChat = async (vendor) => {
        const participantId = vendor.vendor_id;
        try {
            const { data } = await supabase
                .from("ChatSession")
                .select("*")
                .eq("participant_1", adminId)
                .eq("participant_2", participantId)
                .single();
            if (data) {
                navigate(`/adminChat?chatId=${data.chat_id}`);
            } else {
                const participantName = vendor.business_name;
                navigate(
                    `/adminChat?participantId=${vendor.vendor_id}&participantName=${encodeURIComponent(
                        participantName
                    )}&participantType=vendor`
                );
            }
        } catch (err) {
            console.error("Error checking chat session:", err);
        }
    };

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden relative">
            <div className="overflow-x-auto">
                <table className="min-w-max text-left">
                    <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th
                            onClick={() => handleSort("vendorId")}
                            className={`py-3 px-6 font-semibold text-left cursor-pointer select-none sticky left-0 w-32 bg-gray-200 ${modalOpen ? "opacity-50" : ""} z-0`}
                        >
                            <span className="font-bold">Vendor ID</span>{" "}
                            {sortConfig.key === "vendorId" && (
                                <span className="ml-1">{sortConfig.direction === "asc" ? "▲" : "▼"}</span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort("businessName")}
                            className={`py-3 px-6 font-semibold text-left cursor-pointer select-none sticky left-32 w-48 bg-gray-200 ${modalOpen ? "opacity-50" : ""} z-0`}
                        >
                            <span className="font-bold">Business Name</span>{" "}
                            {sortConfig.key === "businessName" && (
                                <span className="ml-1">{sortConfig.direction === "asc" ? "▲" : "▼"}</span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort("contactName")}
                            className="py-3 px-6 font-semibold text-left cursor-pointer select-none"
                        >
                            <span className="font-bold">Contact Name</span>{" "}
                            {sortConfig.key === "contactName" && (
                                <span className="ml-1">{sortConfig.direction === "asc" ? "▲" : "▼"}</span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort("status")}
                            className="py-3 px-6 font-semibold text-left cursor-pointer select-none"
                        >
                            Status{" "}
                            {sortConfig.key === "status" && (
                                <span className="ml-1">{sortConfig.direction === "asc" ? "▲" : "▼"}</span>
                            )}
                        </th>
                        <th className="py-3 px-6 font-semibold text-left">
                            <span className="font-bold">Total Deals</span>
                        </th>
                        <th className="py-3 px-6 font-semibold text-left">
                            <span className="font-bold">Total Coupons Published</span>
                        </th>
                        <th className="py-3 px-6 font-semibold text-left">
                            <span className="font-bold">Total Coupons Redeemed</span>
                        </th>
                        <th className="py-3 px-6 font-semibold text-left">
                            <span className="font-bold">Total Coupons Generated</span>
                        </th>
                        <th className="py-3 px-6 font-semibold text-left">
                            <span className="font-bold">Wallet Balance</span>
                        </th>
                        <th className="py-3 px-6 font-semibold text-left">
                            <span className="font-bold">Vendor Revenue Forecast</span>
                        </th>
                        <th className="py-3 px-6 font-semibold text-left">
                            <span className="font-bold">Vendor Revenue Realized</span>
                        </th>
                        <th className="py-3 px-6 font-semibold text-left">
                            <span className="font-bold">Wah! Coupon Revenue Publish</span>
                        </th>
                        <th className="py-3 px-6 font-semibold text-left">
                            <span className="font-bold">Wah! Coupon Revenue Upsell</span>
                        </th>
                        <th className="py-3 px-6 font-semibold text-left">
                            <span className="font-bold">Wah! Revenue</span>
                        </th>
                        <th
                            className={`py-3 px-6 font-semibold text-left sticky right-0 bg-gray-200 ${modalOpen ? "opacity-50" : ""} z-0`}
                        >
                            Actions
                        </th>
                    </tr>
                    </thead>
                    <tbody className="text-gray-700 text-sm font-light">
                    {loading ? (
                        <tr>
                            <td colSpan={15} className="py-6 text-left pl-6">
                                <div className="flex items-center justify-start space-x-2">
                                    <FaSpinner className="animate-spin inline-block" size={24} />
                                    <span>Loading vendors, please wait...</span>
                                </div>
                            </td>
                        </tr>
                    ) : currentVendors.length > 0 ? (
                        currentVendors.map((vendor) => {
                            const rowBg = vendor.approved_status ? "bg-green-50" : "bg-red-50";
                            return (
                                <tr key={vendor.vendor_id} className={`border-b border-gray-200 ${rowBg} hover:bg-gray-100`}>
                                    <td className={`py-3 px-6 text-blue-600 font-bold sticky left-0 w-32 ${rowBg} ${modalOpen ? "opacity-50" : ""} z-0`}>
                                        {vendor.vendor_id.split("_")[1] || vendor.vendor_id}
                                    </td>
                                    <td className={`py-3 px-6 font-bold sticky left-32 w-48 ${rowBg} ${modalOpen ? "opacity-50" : ""} z-0`}>
                                        {vendor.business_name}
                                    </td>
                                    <td className="py-3 px-6 font-bold">{vendor.contact_name}</td>
                                    <td className="py-3 px-6">
                                        {vendor.approved_status ? (
                                            <span className="bg-green-200 text-green-800 px-2 py-1 rounded">
                          Active
                        </span>
                                        ) : (
                                            <span className="bg-red-200 text-red-800 px-2 py-1 rounded">
                          Pending approval
                        </span>
                                        )}
                                    </td>
                                    {/* Clickable metric columns */}
                                    <td className="py-3 px-6 font-bold">
                      <span onClick={() => goToDeals(vendor.vendor_id)} className="text-blue-500 underline cursor-pointer">
                        {vendor.totalDeals}
                      </span>
                                    </td>
                                    <td className="py-3 px-6 font-bold">{vendor.totalCouponsPublished}</td>
                                    <td className="py-3 px-6 font-bold">
                      <span onClick={() => goToRedeemedCoupons(vendor.vendor_id)} className="text-blue-500 underline cursor-pointer">
                        {vendor.totalCouponsRedeemed}
                      </span>
                                    </td>
                                    <td className="py-3 px-6 font-bold">{vendor.totalCouponsGenerated}</td>
                                    <td className="py-3 px-6 font-bold">
                      <span onClick={() => goToWallet(vendor.vendor_id)} className="text-blue-500 underline cursor-pointer">
                        {formatCurrency(vendor.walletBalance)}
                      </span>
                                    </td>
                                    {/* Revenue columns */}
                                    <td className="py-3 px-6 font-bold">
                                        <span>{formatCurrency(vendor.vendorRevenueForecast)}</span>
                                    </td>
                                    <td className="py-3 px-6 font-bold">
                                        <span>{formatCurrency(vendor.vendorRevenueRealized)}</span>
                                    </td>
                                    <td className="py-3 px-6 font-bold">
                      <span onClick={() => goToDeals(vendor.vendor_id)} className="text-blue-500 underline cursor-pointer">
                        {formatCurrency(vendor.wahCouponRevenuePublish)}
                      </span>
                                    </td>
                                    <td className="py-3 px-6 font-bold">
                      <span onClick={() => goToDeals(vendor.vendor_id)} className="text-blue-500 underline cursor-pointer">
                        {formatCurrency(vendor.wahCouponRevenueUpsell)}
                      </span>
                                    </td>
                                    <td className="py-3 px-6 font-bold">
                      <span onClick={() => goToDeals(vendor.vendor_id)} className="text-blue-500 underline cursor-pointer">
                        {formatCurrency(vendor.wahRevenue)}
                      </span>
                                    </td>
                                    <td className={`py-3 px-6 sticky right-0 ${rowBg} ${modalOpen ? "opacity-50" : ""} z-0`}>
                                        <div className="flex items-center space-x-6">
                                            <button onClick={() => handleEditClick(vendor)} className="text-blue-500 font-medium">
                                                Edit
                                            </button>
                                            <button
                                                onClick={() => handleStatusToggle(vendor)}
                                                className={`font-medium px-4 py-2 rounded w-28 text-center ${
                                                    vendor.approved_status ? "bg-red-500 text-white" : "bg-blue-500 text-white"
                                                } hover:opacity-80 transition-opacity`}
                                            >
                                                {vendor.approved_status ? "Deactivate" : "Activate"}
                                            </button>
                                            <button
                                                onClick={() => handleChat(vendor)}
                                                className="font-medium px-2 py-2 rounded transition-opacity hover:opacity-80 bg-purple-500 text-white flex items-center justify-center w-12 h-full"
                                                title="Chat"
                                            >
                                                <FaCommentDots size={20} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={15} className="text-left py-6 pl-6">
                                <span>No vendors found.</span>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {totalPages > 1 && (
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
            )}
        </div>
    );
};

export default VendorsTable;
