import React, { useState, useMemo, useEffect } from 'react';
import {
    FaChevronRight,
    FaChevronDown,
    FaSpinner,
    FaAngleDoubleLeft,
    FaAngleLeft,
    FaAngleRight,
    FaAngleDoubleRight,
    FaArrowRight
} from 'react-icons/fa';
import parse from 'html-react-parser';
import { Link, useNavigate } from 'react-router-dom';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const [jumpPage, setJumpPage] = useState(currentPage);

    useEffect(() => {
        setJumpPage(currentPage);
    }, [currentPage]);

    if (totalPages <= 1) return null;

    const getPageNumbers = (current, total) => {
        const delta = 2;
        const pages = [];
        pages.push(1);

        if (current - delta > 2) {
            pages.push('left-ellipsis');
        } else {
            for (let i = 2; i < Math.max(2, current - delta); i++) {
                pages.push(i);
            }
        }

        for (let i = Math.max(2, current - delta); i <= Math.min(total - 1, current + delta); i++) {
            pages.push(i);
        }

        if (current + delta < total - 1) {
            pages.push('right-ellipsis');
        } else {
            for (let i = Math.min(total - 1, current + delta) + 1; i < total; i++) {
                pages.push(i);
            }
        }

        if (total > 1) {
            pages.push(total);
        }

        return pages;
    };

    const pages = getPageNumbers(currentPage, totalPages);

    const handleJumpSubmit = (e) => {
        e.preventDefault();
        const page = Number(jumpPage);
        if (page >= 1 && page <= totalPages && page !== currentPage) {
            onPageChange(page);
        }
    };

    return (
        <div className="flex justify-center mt-6">
            <div className="flex items-center space-x-2">
                <button
                    onClick={() => onPageChange(1)}
                    disabled={currentPage === 1}
                    title="First Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleDoubleLeft size={18} />
                </button>
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    title="Previous Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleLeft size={18} />
                </button>
                {pages.map((page, idx) =>
                    page === 'left-ellipsis' || page === 'right-ellipsis' ? (
                        <span key={idx} className="px-3 py-2">&hellip;</span>
                    ) : (
                        <button
                            key={page}
                            onClick={() => onPageChange(page)}
                            className={`px-4 py-2 rounded transition-colors ${
                                page === currentPage
                                    ? 'bg-blue-500 text-white font-semibold'
                                    : 'bg-gray-200 hover:bg-gray-300'
                            }`}
                        >
                            {page}
                        </button>
                    )
                )}
                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    title="Next Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleRight size={18} />
                </button>
                <button
                    onClick={() => onPageChange(totalPages)}
                    disabled={currentPage === totalPages}
                    title="Last Page"
                    className="px-3 py-2 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 rounded transition-colors"
                >
                    <FaAngleDoubleRight size={18} />
                </button>
                <form onSubmit={handleJumpSubmit} className="flex items-center border border-gray-300 rounded overflow-hidden">
                    <input
                        type="number"
                        min="1"
                        max={totalPages}
                        value={jumpPage}
                        onChange={(e) => setJumpPage(e.target.value)}
                        placeholder="Page"
                        className="w-16 px-2 py-2 text-center outline-none"
                    />
                    <button
                        type="submit"
                        className="px-3 py-2 bg-blue-500 text-white hover:bg-blue-600 transition-colors"
                        title="Go to Page"
                    >
                        <FaArrowRight size={14} />
                    </button>
                </form>
            </div>
        </div>
    );
};

const formatCurrency = (num) => `$${Number(num).toFixed(2)}`;

const getStatusBadge = (status) => {
    const lowerStatus = status?.toLowerCase() || '';
    switch (lowerStatus) {
        case 'active':
            return (
                <span className="bg-green-200 text-green-800 px-2 py-1 rounded">
                    Active
                </span>
            );
        case 'pending_payment':
            return (
                <span className="bg-yellow-200 text-yellow-800 px-2 py-1 rounded inline-block text-center whitespace-nowrap">
                    Pending Payment
                </span>
            );
        case 'under_review':
            return (
                <span className="bg-orange-200 text-orange-800 px-2 py-1 rounded inline-block text-center whitespace-nowrap">
                    Under Review
                </span>
            );
        case 'expired':
        case 'inactive':
            return (
                <span className="bg-red-200 text-red-800 px-2 py-1 rounded">
                    {lowerStatus.charAt(0).toUpperCase() + lowerStatus.slice(1)}
                </span>
            );
        default:
            return (
                <span className="bg-red-200 text-red-800 px-2 py-1 rounded">
                    Inactive
                </span>
            );
    }
};

const getRowBgClass = (status) => {
    const lowerStatus = status?.toLowerCase();
    if (lowerStatus === 'active') return 'bg-green-50';
    if (lowerStatus === 'pending_payment') return 'bg-yellow-50';
    if (lowerStatus === 'under_review') return 'bg-orange-50';
    return 'bg-red-50';
};

const DealsTable = ({ filteredDeals, handleEditClick, handleStatusToggle, loading, modalOpen = false }) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const navigate = useNavigate();

    const handleRowExpand = (dealId) => {
        setExpandedRows((prev) =>
            prev.includes(dealId) ? prev.filter((id) => id !== dealId) : [...prev, dealId]
        );
    };

    const handleSort = (key) => {
        setSortConfig((prev) => {
            if (prev.key === key) {
                return { key, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
            }
            return { key, direction: 'asc' };
        });
        setCurrentPage(1);
    };

    const sortedDeals = useMemo(() => {
        if (!filteredDeals) return [];
        const { key, direction } = sortConfig;
        const items = [...filteredDeals];
        if (key) {
            items.sort((a, b) => {
                let aVal, bVal;
                switch (key) {
                    case 'dealId':
                        aVal = a.deal_id?.toLowerCase() || '';
                        bVal = b.deal_id?.toLowerCase() || '';
                        break;
                    case 'vendorId':
                        aVal = a.vendor_id?.toLowerCase() || '';
                        bVal = b.vendor_id?.toLowerCase() || '';
                        break;
                    case 'dealTitle':
                        aVal = a.deal_title?.toLowerCase() || '';
                        bVal = b.deal_title?.toLowerCase() || '';
                        break;
                    case 'dealStatus':
                        aVal = a.deal_status?.toLowerCase() || '';
                        bVal = b.deal_status?.toLowerCase() || '';
                        break;
                    case 'wahPrice':
                        aVal = Number(a.wah_price) || 0;
                        bVal = Number(b.wah_price) || 0;
                        break;
                    default:
                        return 0;
                }
                if (aVal < bVal) return direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return items;
    }, [filteredDeals, sortConfig]);

    const currentDeals = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize;
        return sortedDeals.slice(startIndex, startIndex + pageSize);
    }, [sortedDeals, currentPage]);

    const totalPages = Math.ceil(sortedDeals.length / pageSize);

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
                <table className="min-w-full text-left">
                    <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th
                            onClick={() => handleSort('dealId')}
                            className={`py-3 px-6 font-semibold text-center cursor-pointer select-none sticky left-0 bg-gray-200 ${modalOpen ? 'opacity-50' : ''} z-0`}
                        >
                            Deal ID {sortConfig.key === 'dealId' && (<span className="ml-1">{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>)}
                        </th>
                        <th
                            onClick={() => handleSort('vendorId')}
                            className={`py-3 px-6 font-semibold text-center cursor-pointer select-none sticky left-[120px] bg-gray-200 ${modalOpen ? 'opacity-50' : ''} z-0`}
                        >
                            Vendor ID {sortConfig.key === 'vendorId' && (<span className="ml-1">{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>)}
                        </th>
                        <th
                            onClick={() => handleSort('dealTitle')}
                            className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                        >
                            Deal Title {sortConfig.key === 'dealTitle' && (<span className="ml-1">{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>)}
                        </th>
                        <th
                            onClick={() => handleSort('dealStatus')}
                            className="py-3 px-6 font-semibold text-center cursor-pointer select-none"
                        >
                            Deal Status {sortConfig.key === 'dealStatus' && (<span className="ml-1">{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>)}
                        </th>
                        <th className="py-3 px-6 font-semibold text-center">
                            Wah! Deal Price
                        </th>
                        <th className="py-3 px-6 font-semibold text-center">
                            Total Coupons Published
                        </th>
                        <th className="py-3 px-6 font-semibold text-center">
                            Total Coupons Generated
                        </th>
                        <th className="py-3 px-6 font-semibold text-center">
                            Total Coupons Redeemed
                        </th>
                        <th className="py-3 px-6 font-semibold text-center">
                            Wah! Fee
                        </th>
                        <th className="py-3 px-6 font-semibold text-center">
                            Upsell Revenue
                        </th>
                        <th className="py-3 px-6 font-semibold text-center">
                            Vendor Revenue Forecast
                        </th>
                        <th className="py-3 px-6 font-semibold text-center">
                            Vendor Revenue Realized
                        </th>
                        <th className="py-3 px-6 font-semibold text-center">
                            Wah! Coupon Revenue Publish
                        </th>
                        <th className="py-3 px-6 font-semibold text-center">
                            Wah! Coupon Upsell Revenue
                        </th>
                        <th className="py-3 px-6 font-semibold text-center">
                            Wah! Revenue
                        </th>
                        <th
                            className={`py-3 px-6 font-semibold text-center sticky right-0 bg-gray-200 ${modalOpen ? 'opacity-50' : ''} z-0`}
                        >
                            Actions
                        </th>
                    </tr>
                    </thead>
                    <tbody className="text-gray-700 text-sm font-light">
                    {loading ? (
                        <tr>
                            <td colSpan={15} className="text-center py-6">
                                <div className="flex items-center justify-center space-x-2">
                                    <FaSpinner className="animate-spin inline-block" size={24} />
                                    <span>Loading deals, please wait...</span>
                                </div>
                            </td>
                        </tr>
                    ) : currentDeals.length > 0 ? (
                        currentDeals.map((deal) => (
                            <React.Fragment key={deal.deal_id}>
                                <tr className={`border-b border-gray-200 ${getRowBgClass(deal.deal_status)} hover:bg-gray-100`}>
                                    <td className={`py-3 px-6 text-center sticky left-0 ${getRowBgClass(deal.deal_status)} ${modalOpen ? 'opacity-50' : ''} z-0`}>
                                        <div className="flex items-center justify-center space-x-2">
                                            <button
                                                onClick={() => handleRowExpand(deal.deal_id)}
                                                className="focus:outline-none text-blue-600"
                                                title={expandedRows.includes(deal.deal_id) ? 'Collapse details' : 'Expand details'}
                                            >
                                                {expandedRows.includes(deal.deal_id) ? <FaChevronDown /> : <FaChevronRight />}
                                            </button>
                                            <span className="font-bold text-blue-600">
                                                    {deal.deal_id?.split('_')[1] || deal.deal_id}
                                                </span>
                                        </div>
                                    </td>
                                    <td className={`py-3 px-6 text-center sticky left-[120px] ${getRowBgClass(deal.deal_status)} ${modalOpen ? 'opacity-50' : ''} z-0`}>
                                        <span className="font-bold">{deal.vendor_id?.split('_')[1] || deal.vendor_id}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{deal.deal_title}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        {getStatusBadge(deal.deal_status)}
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{formatCurrency(deal.wah_price)}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{deal.coupon_count || 0}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{deal.coupon_summary?.generated || 0}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{deal.totalCouponsRedeemed || 0}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{formatCurrency(deal.wahFee)}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{formatCurrency(deal.upsellRevenue)}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{formatCurrency(deal.vendorRevenueForecast)}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{formatCurrency(deal.vendorRevenueRealized)}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{formatCurrency(deal.wahCouponRevenuePublish)}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{formatCurrency(deal.wahCouponUpsellRevenue)}</span>
                                    </td>
                                    <td className="py-3 px-6 text-center">
                                        <span className="font-bold">{formatCurrency(deal.wahRevenue)}</span>
                                    </td>
                                    <td className={`py-3 px-6 text-center sticky right-0 ${getRowBgClass(deal.deal_status)} ${modalOpen ? 'opacity-50' : ''} z-0`}>
                                        <div className="flex items-center justify-center space-x-2">
                                            <button onClick={() => handleEditClick(deal)} className="text-blue-500 font-medium hover:opacity-80 transition-opacity">
                                                View/Edit
                                            </button>
                                            <button
                                                onClick={() => handleStatusToggle(deal)}
                                                className={`font-medium px-4 py-2 rounded transition-opacity hover:opacity-80 w-28 text-center ${
                                                    ['under_review', 'inactive', 'paused'].includes(deal.deal_status?.toLowerCase())
                                                        ? 'bg-blue-500 text-white'
                                                        : 'bg-red-500 text-white'
                                                }`}
                                            >
                                                {['under_review', 'inactive', 'paused'].includes(deal.deal_status?.toLowerCase())
                                                    ? 'Activate'
                                                    : 'Deactivate'}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                {expandedRows.includes(deal.deal_id) && (
                                    <tr>
                                        <td colSpan={15}>
                                            <div className="bg-white border border-gray-200 rounded-md shadow-sm p-6">
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                    <div className="space-y-6">
                                                        <div>
                                                            <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                                                Deal Description
                                                            </h3>
                                                            <div className="text-gray-700 text-sm leading-5">
                                                                {deal.deal_description ? parse(deal.deal_description) : <p>No Description</p>}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                                                Terms &amp; Conditions
                                                            </h3>
                                                            <div className="text-gray-700 text-sm leading-5">
                                                                {deal.terms_and_conditions ? parse(deal.terms_and_conditions) : <p>No Terms &amp; Conditions</p>}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                                                Coupon Information
                                                            </h3>
                                                            <p className="text-gray-700 text-sm mb-1">
                                                                <strong>Coupon Count:</strong>{' '}
                                                                <Link to={`/coupons?dealId=${deal.deal_id}`} className="text-blue-500 hover:underline">
                                                                    {deal.coupon_count}
                                                                </Link>
                                                            </p>
                                                            <p className="text-gray-700 text-sm mb-1">
                                                                <strong>Remaining:</strong> {deal.coupon_remaining_count}
                                                            </p>
                                                            <p className="text-gray-700 text-sm mb-1">
                                                                <strong>Generated:</strong> {deal.coupon_summary?.generated || 0}
                                                            </p>
                                                            <p className="text-gray-700 text-sm mb-1">
                                                                <strong>Active:</strong> {deal.coupon_summary?.active || 0}
                                                            </p>
                                                            <p className="text-gray-700 text-sm mb-1">
                                                                <strong>Redeemed:</strong> {deal.coupon_summary?.redeemed || 0}
                                                            </p>
                                                            <p className="text-gray-700 text-sm mb-1">
                                                                <strong>Expired:</strong> {deal.coupon_summary?.expired || 0}
                                                            </p>
                                                            <p className="text-gray-700 text-sm mb-1">
                                                                <strong>Cancelled:</strong> {deal.coupon_summary?.cancelled || 0}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                                                Category &amp; Discount
                                                            </h3>
                                                            <p className="text-gray-700 text-sm mb-1">
                                                                <strong>Category:</strong> {deal.category_info?.name || 'No Category'}
                                                            </p>
                                                            <p className="text-gray-700 text-sm">
                                                                <strong>Discount Type:</strong> {deal.discount_type_name || 'N/A'}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                                                Deal Duration
                                                            </h3>
                                                            <p className="text-gray-700 text-sm mb-1">
                                                                <strong>From:</strong> {deal.available_from ? new Date(deal.available_from).toLocaleDateString() : 'N/A'}
                                                            </p>
                                                            <p className="text-gray-700 text-sm mb-1">
                                                                <strong>To:</strong> {deal.available_to ? new Date(deal.available_to).toLocaleDateString() : 'N/A'}
                                                            </p>
                                                            <p className="text-gray-700 text-sm">
                                                                <strong>Coupon Expiry:</strong> {deal.coupon_expiry ? new Date(deal.coupon_expiry).toLocaleDateString() : 'N/A'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-6">
                                                        <div>
                                                            <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                                                Active Store Locations
                                                            </h3>
                                                            {deal.store_locations && deal.store_locations.length > 0 ? (
                                                                <div className="max-h-64 overflow-auto flex flex-col space-y-4">
                                                                    {deal.store_locations.filter((location) => location.active).map((location) => (
                                                                        <div
                                                                            key={location.location_id}
                                                                            className="bg-gray-50 border border-gray-200 rounded-md shadow-sm p-4 space-y-1"
                                                                        >
                                                                            <p className="text-sm text-gray-700">
                                                                                <strong>Address:</strong> {location.address}, {location.city}, {location.state}, {location.country}, {location.zipcode}
                                                                            </p>
                                                                            <p className="text-sm text-gray-700">
                                                                                <strong>Store Manager:</strong> {location.store_manager}
                                                                            </p>
                                                                            <p className="text-sm text-gray-700">
                                                                                <strong>Phone:</strong> {location.mobile_phone}
                                                                            </p>
                                                                            <p className="text-sm text-gray-700">
                                                                                <strong>Email:</strong>{' '}
                                                                                <a href={`mailto:${location.store_email}`} className="text-blue-500 hover:underline">
                                                                                    {location.store_email}
                                                                                </a>
                                                                            </p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <p className="text-gray-700 text-sm">
                                                                    No Active Store Locations Available
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                                                Deal Images
                                                            </h3>
                                                            {deal.deal_images && deal.deal_images.length > 0 ? (
                                                                <div className="flex flex-wrap gap-4">
                                                                    {deal.deal_images.map((imgUrl, idx) => (
                                                                        <img
                                                                            key={idx}
                                                                            src={imgUrl}
                                                                            alt={`deal-image-${idx}`}
                                                                            className="w-24 h-24 object-cover border rounded"
                                                                        />
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <p className="text-gray-700 text-sm">
                                                                    No Images Available
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={15} className="text-center py-6">
                                <span>No deals found.</span>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {sortedDeals.length > pageSize && (
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
            )}
        </div>
    );
};

export default DealsTable;
