// src/pages/WalletCustomerPage.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WalletSearchBar from './WalletSearchBar';
import WalletTable from './WalletTable';
import EditWalletModal from './EditWalletModal';
import { fetchCustomerWallets, updateWalletBalance } from '../../redux/actions/walletActions';

const WalletCustomerPage = () => {
    const dispatch = useDispatch();
    const { customerWallets, error } = useSelector((state) => state.wallets);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [walletsFetched, setWalletsFetched] = useState(false);

    // Read query parameter "customerId" from URL and set search term if present
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const customerIdParam = params.get("customerId");
        if (customerIdParam) {
            setSearchTerm(customerIdParam);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchCustomerWallets());
            setWalletsFetched(true);
        };
        fetchData();
    }, [dispatch]);

    const handleEditClick = (wallet) => {
        setSelectedWallet(wallet);
        setShowEditModal(true);
    };

    const handleUpdateWallet = async (walletId, updatedBalance, operation) => {
        if (!walletId) return;
        await dispatch(updateWalletBalance(walletId, updatedBalance, operation));
        setShowEditModal(false);
        setSelectedWallet(null);
        dispatch(fetchCustomerWallets());
    };

    const filteredWallets = customerWallets.filter((wallet) => {
        const term = searchTerm.toLowerCase();
        return (
            wallet.wallet_id.toLowerCase().includes(term) ||
            (wallet.customer_id && wallet.customer_id.toLowerCase().includes(term))
        );
    });

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Customer Wallets</h1>
            {error && (
                <div className="bg-red-500 text-white px-4 py-2 rounded mb-4">
                    {error}
                </div>
            )}
            <WalletSearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                placeholder="Search by Wallet ID or Customer ID"
            />
            <WalletTable
                wallets={filteredWallets}
                loading={!walletsFetched}
                onEditClick={handleEditClick}
                type="customer"
            />
            {showEditModal && selectedWallet && (
                <EditWalletModal
                    wallet={selectedWallet}
                    onClose={() => setShowEditModal(false)}
                    onSave={handleUpdateWallet}
                />
            )}
        </div>
    );
};

export default WalletCustomerPage;
