import React, { useState, useRef, useEffect } from "react";

const DateFilterDropdown = ({ value, onChange }) => {
    const [isCustom, setIsCustom] = useState(false);
    const [customDates, setCustomDates] = useState({ startDate: "", endDate: "" });
    const [tempCustomOption, setTempCustomOption] = useState(null);
    const customRangeRef = useRef(null);

    const dateOptions = [
        { label: "Live", value: "live" },
        { label: "Last 7 Days", value: "last_7_days" },
        { label: "Last 30 Days", value: "last_30_days" },
        { label: "Last 6 Months", value: "last_6_months" },
        { label: "Last 1 Year", value: "last_1_year" },
        ...(tempCustomOption ? [tempCustomOption] : []),
        { label: "Custom", value: "custom" },
    ];

    const handleDateChange = (selectedValue) => {
        if (selectedValue === "custom") {
            setIsCustom(true);
        } else if (tempCustomOption && selectedValue === tempCustomOption.value) {
            onChange(selectedValue);
            setIsCustom(false);
        } else {
            onChange(selectedValue);
            setIsCustom(false);
        }
    };

    const handleCustomDateSubmit = () => {
        if (customDates.startDate && customDates.endDate) {
            const customLabel = `${customDates.startDate} - ${customDates.endDate}`;
            const customValue = `custom_${customDates.startDate}_${customDates.endDate}`;
            setTempCustomOption({ label: customLabel, value: customValue });
            onChange(customValue);
            setIsCustom(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (customRangeRef.current && !customRangeRef.current.contains(event.target)) {
                setIsCustom(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="relative">
            <select
                className="bg-gray-700 text-gray-200 text-sm px-3 py-2 rounded-lg focus:outline-none"
                onChange={(e) => handleDateChange(e.target.value)}
                value={value}
            >
                {dateOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>

            {isCustom && (
                <div
                    className="absolute top-12 bg-gray-800 p-4 rounded-lg shadow-lg z-50"
                    ref={customRangeRef}
                    style={{ zIndex: 9999 }}
                >
                    <label className="block text-gray-400 text-sm mb-2">
                        Start Date
                    </label>
                    <input
                        type="date"
                        className="bg-gray-700 text-gray-200 px-3 py-2 rounded-lg mb-4 w-full"
                        value={customDates.startDate}
                        onChange={(e) =>
                            setCustomDates((prev) => ({ ...prev, startDate: e.target.value }))
                        }
                    />
                    <label className="block text-gray-400 text-sm mb-2">
                        End Date
                    </label>
                    <input
                        type="date"
                        className="bg-gray-700 text-gray-200 px-3 py-2 rounded-lg mb-4 w-full"
                        value={customDates.endDate}
                        onChange={(e) =>
                            setCustomDates((prev) => ({ ...prev, endDate: e.target.value }))
                        }
                    />
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                        onClick={handleCustomDateSubmit}
                    >
                        Apply
                    </button>
                </div>
            )}
        </div>
    );
};

export default DateFilterDropdown;
