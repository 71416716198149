import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const TotalsCard = ({ data, title, config = {}, loading }) => {
    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center p-4">
                <FaSpinner className="animate-spin text-2xl text-blue-500" />
                <p className="mt-2">Loading totals...</p>
            </div>
        );
    }

    if (!data || data.length === 0) return null;

    // Default keys that should be shown as plain numbers (e.g. coupon_count).
    const defaultNoCurrency = [
        'coupon_count',
        'coupons_redeemed',
        'vendor_count',
        'coupons_generated'
    ];
    config.noCurrency = config.noCurrency || defaultNoCurrency;

    // Determine raw numeric keys from the first item (excluding any keys in config.exclude).
    const excludeKeys = config.exclude || [];
    const rawKeys = Object.keys(data[0]).filter(
        (key) => typeof data[0][key] === 'number' && !excludeKeys.includes(key)
    );

    // Merge raw keys with any custom aggregator keys in config.custom.
    const customKeys = config.custom ? Object.keys(config.custom) : [];
    let numericKeys = Array.from(new Set([...rawKeys, ...customKeys]));

    // If a fields array is provided, ensure those keys are included so they appear in the final display.
    if (config.fields && Array.isArray(config.fields)) {
        const fieldKeys = config.fields.map(f => f.key);
        numericKeys = Array.from(new Set([...numericKeys, ...fieldKeys]));
    }

    // Remove certain unwanted raw keys if they are not in custom.
    // For example, we might remove 'wah_deal_price', 'wah_revenue', etc. from raw sums.
    numericKeys = numericKeys.filter(key => {
        if (config.custom && config.custom[key]) {
            // If it's a custom aggregator, keep it
            return true;
        }
        // Otherwise, remove raw fields you definitely don't want auto-summed
        return key !== 'wah_deal_price' && key !== 'wah_revenue' && key !== 'vendor_revenue_forecast';
    });

    // Calculate totals:
    //  - If there's a custom aggregator, use it
    //  - Otherwise, sum up the raw numeric values from data
    const totals = numericKeys.reduce((acc, key) => {
        if (config.custom && typeof config.custom[key] === 'function') {
            acc[key] = config.custom[key](data);
        } else {
            acc[key] = data.reduce((sum, item) => sum + Number(item[key] || 0), 0);
        }
        return acc;
    }, {});

    // Helper to format each total for display.
    const formatTotal = (key, value) => {
        const numValue = (typeof value === 'number' && !isNaN(value)) ? value : 0;
        if (config.noCurrency && config.noCurrency.includes(key)) {
            // Show as plain integer
            return numValue.toFixed(0);
        }
        // Otherwise, show as currency
        return `$${numValue.toFixed(2)}`;
    };

    // Decide which fields to display (and in which order):
    //  - If config.fields is provided, use that order & labels
    //  - Otherwise, fallback to numericKeys with a default label
    const displayFields = config.fields
        ? config.fields
        : numericKeys.map(key => ({ key, label: key.replace(/_/g, ' ') }));

    return (
        <div className="bg-white shadow rounded p-4 mb-6">
            <h3 className="text-lg font-semibold mb-2">{title}</h3>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {displayFields.map(field => (
                    <div key={field.key} className="flex flex-col">
                        <span className="text-gray-500 uppercase text-xs">
                            {field.label}
                        </span>
                        <span className="text-xl font-bold">
                            {formatTotal(field.key, totals[field.key])}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TotalsCard;
