import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddLocationModal from '../../../storeLocations/AddLocationModal';
import { FaSpinner } from 'react-icons/fa';

const AddDealModal = ({
                          vendors,
                          categories,
                          locations,
                          addDealData,
                          setAddDealData,
                          currentImageIndex,
                          setCurrentImageIndex,
                          closeAddModal,
                          handleVendorChange,
                          handleAddLocation,
                          uploadImagesAndAddDeal,
                          handleRemoveImage,
                          handleImageChange,
                          handleInputChange,
                          handleDescriptionChange,
                          handleTermsChange,
                          imageFiles,
                          handleRefreshLocations,
                      }) => {
    const [errors, setErrors] = useState({});
    const [showAddLocationModal, setShowAddLocationModal] = useState(false);
    const [newLocationData, setNewLocationData] = useState({});
    const [isSaving, setIsSaving] = useState(false);

    // Ensure discount type is hardcoded in the deal data
    if (!addDealData.discount_type_id) {
        setAddDealData((prev) => ({ ...prev, discount_type_id: "dType_028140" }));
    }

    // Create a consistent array for store locations
    const storeLocations =
        locations && locations.locations
            ? locations.locations
            : Array.isArray(locations)
                ? locations
                : [];

    // Toggle store location selection (multi-select)
    const handleToggleLocation = (locId) => {
        setAddDealData((prev) => {
            const { store_location_ids } = prev;
            if (store_location_ids.includes(locId)) {
                return { ...prev, store_location_ids: store_location_ids.filter((id) => id !== locId) };
            } else {
                return { ...prev, store_location_ids: [...store_location_ids, locId] };
            }
        });
    };

    // Refresh store locations from parent
    const handleRefresh = () => {
        if (addDealData.vendor_id) {
            handleRefreshLocations(addDealData.vendor_id);
        }
    };

    // Validate form fields
    const validateFields = () => {
        const newErrors = {};
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (!addDealData.vendor_id) newErrors.vendor_id = 'Vendor is required';
        if (!addDealData.category_id) newErrors.category_id = 'Category is required';
        if (!addDealData.store_location_ids || addDealData.store_location_ids.length === 0)
            newErrors.store_location_ids = 'Please select at least one store location';
        if (!addDealData.deal_title) newErrors.deal_title = 'Deal Title is required';
        if (!addDealData.deal_description || addDealData.deal_description.trim() === '')
            newErrors.deal_description = 'Description is required';
        if (!addDealData.available_from) newErrors.available_from = 'Available From date is required';
        if (!addDealData.available_to) newErrors.available_to = 'Available To date is required';
        if (addDealData.available_from && new Date(addDealData.available_from) < today)
            newErrors.available_from = 'Available From date cannot be in the past';
        if (addDealData.available_to && new Date(addDealData.available_to) < today)
            newErrors.available_to = 'Available To date cannot be in the past';
        if (!addDealData.terms_and_conditions || addDealData.terms_and_conditions.trim() === '')
            newErrors.terms_and_conditions = 'Terms & Conditions are required';
        if (!addDealData.regular_price || addDealData.regular_price <= 0)
            newErrors.regular_price = 'Original Price is required and must be positive';
        if (!addDealData.discount_value || addDealData.discount_value < 0 || addDealData.discount_value > 100)
            newErrors.discount_value = 'Discount is required and must be between 0 and 100';
        const regularPrice = parseFloat(addDealData.regular_price) || 0;
        const discountPercentage = parseFloat(addDealData.discount_value) || 0;
        const calculatedNet = regularPrice - (regularPrice * discountPercentage / 100);
        if (calculatedNet < 0) newErrors.wah_price = 'Net Price must be positive';
        if (!addDealData.coupon_count) newErrors.coupon_count = 'Total Coupon Count is required';
        if (!addDealData.deal_images || addDealData.deal_images.length === 0)
            newErrors.deal_images = 'At least one image is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Save deal and show spinner during saving
    const handleSaveDeal = async () => {
        if (validateFields()) {
            setIsSaving(true);
            try {
                await uploadImagesAndAddDeal();
            } catch (error) {
                // Error handling is done in uploadImagesAndAddDeal
            }
            setIsSaving(false);
        }
    };

    // Carousel controls for deal images
    const goToPreviousImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : addDealData.deal_images.length - 1
        );
    };

    const goToNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex < addDealData.deal_images.length - 1 ? prevIndex + 1 : 0
        );
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg max-w-6xl w-full flex relative">
                <button onClick={closeAddModal} className="absolute top-2 right-2 text-gray-500">
                    ✖
                </button>
                {/* LEFT SECTION: Deal Images */}
                <div className="flex flex-col items-center pr-6 border-r border-gray-200">
                    <h3 className="text-lg font-bold mb-2">
                        Add Deal Images <span className="text-red-500">*</span>
                    </h3>
                    {addDealData.deal_images && addDealData.deal_images.length > 0 ? (
                        <div className="relative">
                            <img
                                src={addDealData.deal_images[currentImageIndex]}
                                alt="Deal Preview"
                                className="h-64 w-full object-cover rounded mb-4"
                            />
                            <button
                                onClick={goToPreviousImage}
                                className="absolute top-1/2 left-2 text-white bg-black bg-opacity-50 rounded-full p-2"
                            >
                                {'<'}
                            </button>
                            <button
                                onClick={goToNextImage}
                                className="absolute top-1/2 right-2 text-white bg-black bg-opacity-50 rounded-full p-2"
                            >
                                {'>'}
                            </button>
                            <div className="flex justify-center mt-2 space-x-1">
                                {addDealData.deal_images.map((_, index) => (
                                    <span
                                        key={index}
                                        className={`h-2 w-2 rounded-full ${
                                            index === currentImageIndex ? 'bg-yellow-500' : 'bg-gray-300'
                                        }`}
                                    />
                                ))}
                            </div>
                            <button
                                onClick={() => handleRemoveImage(currentImageIndex)}
                                className="bg-red-500 text-white px-2 py-1 rounded mt-2"
                            >
                                Remove Image
                            </button>
                        </div>
                    ) : (
                        <p>No images added</p>
                    )}
                    {errors.deal_images && <p className="text-red-500">{errors.deal_images}</p>}
                    <input
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, false)}
                        className="mt-4"
                    />
                </div>
                {/* RIGHT SECTION: Deal Fields */}
                <div className="flex-1 pl-6 overflow-y-auto max-h-[80vh] space-y-4">
                    {/* Vendor */}
                    <div>
                        <label>
                            <strong>
                                Vendor ID:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <select
                            name="vendor_id"
                            value={addDealData.vendor_id}
                            onChange={(e) => handleVendorChange(e.target.value)}
                            className="border border-gray-300 rounded w-full p-2"
                        >
                            <option value="">Select Vendor</option>
                            {vendors
                                .filter((vendor) => vendor.approved_status === true)
                                .map((vendor) => (
                                    <option key={vendor.vendor_id} value={vendor.vendor_id}>
                                        {vendor.business_name}
                                    </option>
                                ))}
                        </select>
                        {errors.vendor_id && <p className="text-red-500">{errors.vendor_id}</p>}
                    </div>
                    {/* Category */}
                    <div>
                        <label>
                            <strong>
                                Category:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <select
                            name="category_id"
                            value={addDealData.category_id}
                            onChange={(e) => handleInputChange(e, false)}
                            className="border border-gray-300 rounded w-full p-2"
                        >
                            <option value="">Select Category</option>
                            {categories?.categories?.map((category) => (
                                <option key={category.category_id} value={category.category_id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                        {errors.category_id && <p className="text-red-500">{errors.category_id}</p>}
                    </div>
                    {/* Discount Type */}
                    <div>
                        <label>
                            <strong>
                                Discount Type:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <select
                            name="discount_type_id"
                            value={addDealData.discount_type_id}
                            onChange={(e) =>
                                setAddDealData((prev) => ({ ...prev, discount_type_id: "dType_028140" }))
                            }
                            className="border border-gray-300 rounded w-full p-2"
                        >
                            <option value="dType_028140">Percentage</option>
                        </select>
                        {errors.discount_type_id && <p className="text-red-500">{errors.discount_type_id}</p>}
                    </div>
                    {/* Store Locations */}
                    <div>
                        <label>
                            <strong>
                                Store Locations:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        {storeLocations.length > 0 ? (
                            <div className="border border-gray-300 rounded p-2 max-h-32 overflow-y-auto space-y-2">
                                {storeLocations
                                    .filter((loc) => loc.vendor_id === addDealData.vendor_id)
                                    .map((loc) => {
                                        const isChecked = addDealData.store_location_ids.includes(loc.location_id);
                                        return (
                                            <div key={loc.location_id} className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={() => handleToggleLocation(loc.location_id)}
                                                />
                                                <span>
                          {loc.address}, {loc.zipcode}
                        </span>
                                            </div>
                                        );
                                    })}
                            </div>
                        ) : (
                            <p>No active store locations available.</p>
                        )}
                        {errors.store_location_ids && <p className="text-red-500">{errors.store_location_ids}</p>}
                        <div className="flex items-center space-x-2 mt-2">
                            <button
                                onClick={() => setShowAddLocationModal(true)}
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                            >
                                Add Store Location
                            </button>
                            <button onClick={handleRefresh} className="bg-green-500 text-white px-4 py-2 rounded">
                                Refresh
                            </button>
                        </div>
                    </div>
                    {/* Deal Title */}
                    <div>
                        <label>
                            <strong>
                                Title:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="text"
                            name="deal_title"
                            value={addDealData.deal_title}
                            onChange={(e) => handleInputChange(e, false)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.deal_title && <p className="text-red-500">{errors.deal_title}</p>}
                    </div>
                    {/* Deal Description */}
                    <div>
                        <label>
                            <strong>
                                Description:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <ReactQuill
                            value={addDealData.deal_description}
                            onChange={handleDescriptionChange}
                            modules={{
                                toolbar: [
                                    [{ header: [1, 2, false] }],
                                    ['bold', 'italic', 'underline'],
                                    ['image', 'code-block'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                ],
                            }}
                            formats={[
                                'header',
                                'bold',
                                'italic',
                                'underline',
                                'image',
                                'code-block',
                                'list',
                                'bullet',
                            ]}
                            className="bg-white"
                        />
                        {errors.deal_description && <p className="text-red-500">{errors.deal_description}</p>}
                    </div>
                    {/* Available From */}
                    <div>
                        <label>
                            <strong>
                                Available From:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="date"
                            name="available_from"
                            value={addDealData.available_from}
                            onChange={(e) => handleInputChange(e, false)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.available_from && <p className="text-red-500">{errors.available_from}</p>}
                    </div>
                    {/* Available To */}
                    <div>
                        <label>
                            <strong>
                                Available To:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="date"
                            name="available_to"
                            value={addDealData.available_to}
                            onChange={(e) => handleInputChange(e, false)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.available_to && <p className="text-red-500">{errors.available_to}</p>}
                    </div>
                    {/* Terms and Conditions */}
                    <div>
                        <label>
                            <strong>
                                Terms and Conditions:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <ReactQuill
                            value={addDealData.terms_and_conditions}
                            onChange={handleTermsChange}
                            modules={{
                                toolbar: [
                                    [{ header: [1, 2, false] }],
                                    ['bold', 'italic', 'underline'],
                                    ['image', 'code-block'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                ],
                            }}
                            formats={[
                                'header',
                                'bold',
                                'italic',
                                'underline',
                                'image',
                                'code-block',
                                'list',
                                'bullet',
                            ]}
                            className="bg-white"
                        />
                        {errors.terms_and_conditions && <p className="text-red-500">{errors.terms_and_conditions}</p>}
                    </div>
                    {/* Original Price */}
                    <div>
                        <label>
                            <strong>
                                Original Price:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="number"
                            name="regular_price"
                            value={addDealData.regular_price}
                            onChange={(e) => handleInputChange(e, false)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.regular_price && <p className="text-red-500">{errors.regular_price}</p>}
                    </div>
                    {/* Discount as Percentage */}
                    <div>
                        <label>
                            <strong>
                                Discount (%):<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="number"
                            name="discount_value"
                            value={addDealData.discount_value}
                            onChange={(e) => handleInputChange(e, false)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.discount_value && <p className="text-red-500">{errors.discount_value}</p>}
                    </div>
                    {/* Net Price */}
                    <div>
                        <label>
                            <strong>
                                Net Price:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="number"
                            name="wah_price"
                            value={addDealData.wah_price}
                            onChange={(e) => handleInputChange(e, false)}
                            className="border border-gray-300 rounded w-full p-2 bg-gray-100"
                            readOnly
                        />
                        {errors.wah_price && <p className="text-red-500">{errors.wah_price}</p>}
                    </div>
                    {/* Total Coupon Count */}
                    <div>
                        <label>
                            <strong>
                                Total Coupon Count:<span className="text-red-500">*</span>
                            </strong>
                        </label>
                        <input
                            type="number"
                            name="coupon_count"
                            value={addDealData.coupon_count}
                            onChange={(e) => handleInputChange(e, false)}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                        {errors.coupon_count && <p className="text-red-500">{errors.coupon_count}</p>}
                    </div>
                    {/* Submit Button with Loading Spinner */}
                    <div>
                        <button
                            onClick={handleSaveDeal}
                            disabled={isSaving}
                            className="bg-blue-500 text-white px-4 py-2 rounded shadow flex items-center justify-center"
                        >
                            {isSaving ? (
                                <>
                                    <FaSpinner className="animate-spin h-5 w-5 mr-2" />
                                    Saving...
                                </>
                            ) : (
                                'Add Deal'
                            )}
                        </button>
                    </div>
                </div>
            </div>
            {showAddLocationModal && (
                <AddLocationModal
                    vendors={vendors}
                    newLocationData={newLocationData}
                    setNewLocationData={setNewLocationData}
                    closeAddModal={() => setShowAddLocationModal(false)}
                    handleAddLocation={handleAddLocation}
                />
            )}
        </div>
    );
};

export default AddDealModal;
