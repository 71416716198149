import React, { useEffect, useMemo, useState } from 'react';
import { FaChevronRight, FaChevronDown, FaSpinner } from 'react-icons/fa';
import Pagination from "../../components/Pagination";

// Detailed Enrollment Table for each plan's expanded view
const EnrollmentDetailsTable = ({ enrollments, plan, initialSearchTerm }) => {
    // Transform enrollment data into detailed rows.
    const initialRows = enrollments.map((enrollment) => {
        // Assuming enrollment.Customers is an array with one customer object.
        const customer = enrollment.Customers[0] || {};
        return {
            customerId: customer.customer_id || 'N/A',
            planId: enrollment.plan_id,
            subscriptionName: plan.plan_name,
            subscriptionRevenue: plan.subscription_fee || 0,
            startDate: enrollment.subscription_start_date,
            nextPaymentDue: enrollment.subscription_end_date,
        };
    });

    const [searchTerm, setSearchTerm] = useState(initialSearchTerm || '');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;

    const sortedRows = useMemo(() => {
        let sortableRows = [...initialRows];
        if (sortConfig.key) {
            sortableRows.sort((a, b) => {
                let aVal = a[sortConfig.key] || '';
                let bVal = b[sortConfig.key] || '';

                // For numeric comparison if key is subscriptionRevenue
                if (sortConfig.key === 'subscriptionRevenue') {
                    aVal = parseFloat(aVal);
                    bVal = parseFloat(bVal);
                } else {
                    aVal = aVal.toString().toLowerCase();
                    bVal = bVal.toString().toLowerCase();
                }
                if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return sortableRows;
    }, [initialRows, sortConfig]);

    const filteredRows = useMemo(() => {
        return sortedRows.filter((row) =>
            Object.values(row).some((value) =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [sortedRows, searchTerm]);

    // Pagination
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(filteredRows.length / rowsPerPage);

    const handleSort = (key) => {
        setSortConfig((prev) => {
            if (prev.key === key) {
                return { key, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
            }
            return { key, direction: 'asc' };
        });
    };

    return (
        <div className="mt-6">
            <div className="flex justify-between items-center mb-4">
                <input
                    type="text"
                    placeholder="Search customers..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1);
                    }}
                    className="border rounded px-3 py-1"
                />
            </div>
            {/* Changed table to w-full with a min-width of 800px */}
            <div>
                <table className="w-full" style={{ minWidth: '800px' }}>
                    <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th
                            onClick={() => handleSort('customerId')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Customer ID
                            {sortConfig.key === 'customerId' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('planId')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Subscription Plan ID
                            {sortConfig.key === 'planId' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('subscriptionName')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Subscription Name
                            {sortConfig.key === 'subscriptionName' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('subscriptionRevenue')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Subscription Revenue
                            {sortConfig.key === 'subscriptionRevenue' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('startDate')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Start Date
                            {sortConfig.key === 'startDate' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('nextPaymentDue')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Next Payment Due
                            {sortConfig.key === 'nextPaymentDue' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                    </tr>
                    </thead>
                    <tbody className="text-gray-700 text-sm font-light">
                    {currentRows.length > 0 ? (
                        currentRows.map((row, idx) => (
                            <tr key={idx} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-4 text-center">{row.customerId}</td>
                                <td className="py-3 px-4 text-center">{row.planId}</td>
                                <td className="py-3 px-4 text-center">{row.subscriptionName}</td>
                                <td className="py-3 px-4 text-center">${row.subscriptionRevenue}</td>
                                <td className="py-3 px-4 text-center">
                                    {new Date(row.startDate).toLocaleDateString()}
                                </td>
                                <td className="py-3 px-4 text-center">
                                    {new Date(row.nextPaymentDue).toLocaleDateString()}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6} className="text-center py-4">
                                No records found.
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(page) => setCurrentPage(page)}
            />
        </div>
    );
};

const SubscriptionsTable = ({ filteredPlans, handleEditClick, handleStatusToggle, loading, searchCustomerId }) => {
    // State to track which rows are expanded (by plan_id)
    const [expandedRows, setExpandedRows] = useState([]);
    // Sorting state for the main table
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    // Handler to toggle row expansion.
    const handleRowExpand = (planId) => {
        setExpandedRows((prev) =>
            prev.includes(planId)
                ? prev.filter((id) => id !== planId)
                : [...prev, planId]
        );
    };

    // Handler to toggle sorting for main table.
    const handleSort = (key) => {
        setSortConfig((prev) => {
            if (prev.key === key) {
                return { key, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
            }
            return { key, direction: 'asc' };
        });
    };

    // Memoized sorted plans.
    const sortedPlans = useMemo(() => {
        if (!filteredPlans) return [];
        const { key, direction } = sortConfig;
        const items = [...filteredPlans];
        if (key) {
            items.sort((a, b) => {
                let aVal = '';
                let bVal = '';
                switch (key) {
                    case 'planId':
                        aVal = a.plan_id?.toLowerCase() || '';
                        bVal = b.plan_id?.toLowerCase() || '';
                        break;
                    case 'planName':
                        aVal = a.plan_name?.toLowerCase() || '';
                        bVal = b.plan_name?.toLowerCase() || '';
                        break;
                    case 'planDescription':
                        aVal = a.plan_description?.toLowerCase() || '';
                        bVal = b.plan_description?.toLowerCase() || '';
                        break;
                    case 'IAPIdentifiers':
                        aVal = ((a.IAP_id_apple || '') + ' ' + (a.IAP_id_google || '')).toLowerCase();
                        bVal = ((b.IAP_id_apple || '') + ' ' + (b.IAP_id_google || '')).toLowerCase();
                        break;
                    case 'subscriptionFee':
                        aVal = parseFloat(a.subscription_fee) || 0;
                        bVal = parseFloat(b.subscription_fee) || 0;
                        break;
                    case 'subscriptionRenewalType':
                        aVal = a.subscription_renewal_type?.toLowerCase() || '';
                        bVal = b.subscription_renewal_type?.toLowerCase() || '';
                        break;
                    case 'planStatus':
                        aVal = a.status?.toLowerCase() || '';
                        bVal = b.status?.toLowerCase() || '';
                        break;
                    default:
                        return 0;
                }
                if (aVal < bVal) return direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return items;
    }, [filteredPlans, sortConfig]);

    // Auto-expand rows if searchCustomerId is found in a plan's enrollments
    useEffect(() => {
        if (searchCustomerId) {
            const matchingPlanIds = sortedPlans.filter(plan => {
                return plan.Customer_Subscription_Plan_Enrolled && plan.Customer_Subscription_Plan_Enrolled.some(enrollment => {
                    const customer = enrollment.Customers && enrollment.Customers[0];
                    return customer && customer.customer_id === searchCustomerId;
                });
            }).map(plan => plan.plan_id);
            setExpandedRows(matchingPlanIds);
        }
    }, [searchCustomerId, sortedPlans]);

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
            {/* Main table (with horizontal scroll) */}
            <div className="overflow-x-auto">
                <table className="min-w-full text-left">
                    {/* TABLE HEADER */}
                    <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th
                            onClick={() => handleSort('planId')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Subscription Plan ID
                            {sortConfig.key === 'planId' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('planName')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Subscription Plan Name
                            {sortConfig.key === 'planName' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('planDescription')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Plan Description
                            {sortConfig.key === 'planDescription' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('IAPIdentifiers')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            IAP Identifiers
                            {sortConfig.key === 'IAPIdentifiers' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('planStatus')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Subscription Status
                            {sortConfig.key === 'planStatus' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('subscriptionFee')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Subscription Amount
                            {sortConfig.key === 'subscriptionFee' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th
                            onClick={() => handleSort('subscriptionRenewalType')}
                            className="py-3 px-4 font-semibold text-center cursor-pointer select-none"
                        >
                            Renewal
                            {sortConfig.key === 'subscriptionRenewalType' && (
                                <span className="ml-1">
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                            )}
                        </th>
                        <th className="py-3 px-4 font-semibold text-center">
                            Subscription Enrolled Count
                        </th>
                        <th className="py-3 px-4 font-semibold text-center">
                            Customer Subscription Revenue
                        </th>
                        <th className="py-3 px-4 font-semibold text-center sticky right-0 z-10 bg-gray-200">
                            Actions
                        </th>
                    </tr>
                    </thead>
                    {/* TABLE BODY */}
                    <tbody className="text-gray-700 text-sm font-light">
                    {loading ? (
                        <tr>
                            <td colSpan={10} className="text-center py-6">
                                <div className="flex items-center justify-center space-x-2">
                                    <FaSpinner className="animate-spin inline-block" size={24} />
                                    <span>Loading subscriptions, please wait...</span>
                                </div>
                            </td>
                        </tr>
                    ) : sortedPlans.length > 0 ? (
                        sortedPlans.map((plan) => {
                            const isExpanded = expandedRows.includes(plan.plan_id);
                            const isActive = plan.status?.toLowerCase() === 'active';
                            // Row background is determined by plan status.
                            const rowBg = isActive ? 'bg-green-50' : 'bg-red-50';
                            return (
                                <React.Fragment key={plan.plan_id}>
                                    {/* MAIN ROW */}
                                    <tr className={`border-b border-gray-200 hover:bg-gray-100 ${rowBg}`}>
                                        <td className="py-3 px-4 font-medium text-blue-600 text-center">
                                            <div className="flex items-center justify-center space-x-2">
                                                <button
                                                    onClick={() => handleRowExpand(plan.plan_id)}
                                                    className="focus:outline-none"
                                                    title={isExpanded ? 'Collapse details' : 'Expand details'}
                                                >
                                                    {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                                                </button>
                                                <span>{plan.plan_id}</span>
                                            </div>
                                        </td>
                                        <td className="py-3 px-4 font-bold text-center">{plan.plan_name}</td>
                                        <td className="py-3 px-4 text-center">
                                            {plan.plan_description || 'N/A'}
                                        </td>
                                        <td className="py-3 px-4 text-center">
                                            {`Apple: ${plan.IAP_id_apple || 'N/A'}, Google: ${plan.IAP_id_google || 'N/A'}`}
                                        </td>
                                        <td className="py-3 px-4 text-center">
                                            {isActive ? (
                                                <span className="bg-green-200 text-green-800 px-2 py-1 rounded">
                                                        active
                                                    </span>
                                            ) : (
                                                <span className="bg-red-200 text-red-800 px-2 py-1 rounded">
                                                        inactive
                                                    </span>
                                            )}
                                        </td>
                                        <td className="py-3 px-4 font-bold text-center">
                                            {plan.subscription_fee ? `$${plan.subscription_fee}` : '$0.00'}
                                        </td>
                                        <td className="py-3 px-4 font-bold text-center">
                                            {plan.subscription_renewal_type || 'N/A'}
                                        </td>
                                        <td className="py-3 px-4 text-center">
                                            {plan.total_customers_enrolled}
                                        </td>
                                        <td className="py-3 px-4 text-center">
                                            ${plan.monthly_subscription_revenue}
                                        </td>
                                        <td className={`py-3 px-4 text-center sticky right-0 z-10 ${rowBg}`}>
                                            <div className="flex items-center justify-center space-x-4">
                                                <button
                                                    onClick={() => handleEditClick(plan)}
                                                    className="text-blue-500 font-medium hover:opacity-80 transition-opacity"
                                                >
                                                    View/Edit
                                                </button>
                                                <button
                                                    onClick={() => handleStatusToggle(plan)}
                                                    className={`font-medium px-4 py-2 rounded transition-opacity hover:opacity-80 w-28 text-center ${
                                                        isActive
                                                            ? 'bg-red-500 text-white'
                                                            : 'bg-blue-500 text-white'
                                                    }`}
                                                >
                                                    {isActive ? 'Deactivate' : 'Activate'}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    {/* Expanded row inserted immediately after its main row */}
                                    {isExpanded && (
                                        <tr>
                                            <td colSpan={10}>
                                                <div className="p-6 overflow-x-visible">
                                                    <h3 className="text-xl font-bold mb-4">
                                                        Enrolled Customers Details for {plan.plan_name}
                                                    </h3>
                                                    <EnrollmentDetailsTable
                                                        enrollments={plan.Customer_Subscription_Plan_Enrolled}
                                                        plan={plan}
                                                        initialSearchTerm={
                                                            (searchCustomerId &&
                                                                plan.Customer_Subscription_Plan_Enrolled &&
                                                                plan.Customer_Subscription_Plan_Enrolled.some(enrollment => {
                                                                    const customer = enrollment.Customers && enrollment.Customers[0];
                                                                    return customer && customer.customer_id === searchCustomerId;
                                                                }))
                                                                ? searchCustomerId
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={10} className="text-center py-6">
                                <span>No plans found.</span>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SubscriptionsTable;
