import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
    fetchSubscriptions,
    addSubscriptionPlan,
    updateSubscriptionPlan,
    togglePlanStatus,
} from '../../redux/actions/subscriptionActions';

import SubscriptionSearchBar from './SubscriptionSearchBar';
import SubscriptionsTable from './SubscriptionsTable';
import AddPlanModal from './AddPlanModal';
import EditPlanModal from './EditPlanModal';
import DeactivatePlanModal from './DeactivatePlanModal';

const Subscriptions = () => {
    const dispatch = useDispatch();
    const { plans, error } = useSelector((state) => state.subscriptions || {});
    const location = useLocation();

    // For modals
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);

    // For search in the subscriptions table (by plan id or name)
    const [searchTerm, setSearchTerm] = useState('');

    // For editing
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [planData, setPlanData] = useState({});

    // For adding
    const [addPlanData, setAddPlanData] = useState({
        plan_name: '',
        plan_description: '',
        subscription_fee: '',
        subscription_renewal_type: '',
        IAP_id_apple: '',
        IAP_id_google: '',
    });

    // For activating/deactivating
    const [planIdInput, setPlanIdInput] = useState('');

    // NEW: state to determine if subscription plans have been fetched
    const [plansFetched, setPlansFetched] = useState(false);
    // NEW: loading states for operations
    const [isAddingPlan, setIsAddingPlan] = useState(false);
    const [isUpdatingPlan, setIsUpdatingPlan] = useState(false);
    const [isTogglingPlan, setIsTogglingPlan] = useState(false);

    // Read query parameters for planId and customerId
    const queryParams = new URLSearchParams(location.search);
    const planIdParam = queryParams.get('planId');
    const customerIdParam = queryParams.get('customerId');

    // Set initial search term based on planId (ignore customerId here)
    useEffect(() => {
        if (planIdParam) {
            setSearchTerm(planIdParam);
        }
    }, [planIdParam]);

    // Fetch all subscription plans on mount and update plansFetched when complete.
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchSubscriptions());
            setPlansFetched(true);
        };
        fetchData();
    }, [dispatch]);

    // Filtered plans for display
    const filteredPlans = plans?.filter((plan) => {
        const planId = plan.plan_id || '';
        const planName = plan.plan_name || '';
        return (
            planId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            planName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    // --------------- Add Plan ---------------
    const handleAddPlan = async () => {
        // Basic validation
        if (!addPlanData.plan_name) {
            alert('Plan name is required!');
            return;
        }
        setIsAddingPlan(true);
        await dispatch(addSubscriptionPlan(addPlanData));
        setIsAddingPlan(false);
        setShowAddModal(false);
        setAddPlanData({
            plan_name: '',
            plan_description: '',
            subscription_fee: '',
            subscription_renewal_type: '',
            IAP_id_apple: '',
            IAP_id_google: '',
        });
    };

    const closeAddModal = () => {
        setShowAddModal(false);
        setAddPlanData({
            plan_name: '',
            plan_description: '',
            subscription_fee: '',
            subscription_renewal_type: '',
            IAP_id_apple: '',
            IAP_id_google: '',
        });
    };

    // --------------- Toggle Status (Activate/Deactivate) ---------------
    const handleStatusToggle = (plan) => {
        setSelectedPlan(plan);
        setShowDeactivateModal(true);
    };

    const handleConfirmDeactivation = async () => {
        const isActive = selectedPlan.status?.toLowerCase() === 'active';
        setIsTogglingPlan(true);
        await dispatch(togglePlanStatus(selectedPlan.plan_id, isActive));
        setIsTogglingPlan(false);
        closeDeactivateModal();
    };

    const closeDeactivateModal = () => {
        setShowDeactivateModal(false);
        setSelectedPlan(null);
        setPlanIdInput('');
    };

    // --------------- Edit Plan ---------------
    const handleEditClick = (plan) => {
        setSelectedPlan(plan);
        setPlanData({ ...plan });
        setShowEditModal(true);
    };

    const handleUpdatePlan = async () => {
        // If this plan has enrolled users, we might need to block certain fields
        const hasEnrolledUsers =
            selectedPlan.Customer_Subscription_Plan_Enrolled &&
            selectedPlan.Customer_Subscription_Plan_Enrolled.length > 0;

        const updates = {
            plan_name: planData.plan_name,
            plan_description: planData.plan_description,
            subscription_fee: Number(planData.subscription_fee) || 0,
            subscription_renewal_type: planData.subscription_renewal_type,
            IAP_id_apple: planData.IAP_id_apple,
            IAP_id_google: planData.IAP_id_google,
            status: planData.status, // Usually won't change here
        };

        setIsUpdatingPlan(true);
        await dispatch(updateSubscriptionPlan(planData.plan_id, updates));
        setIsUpdatingPlan(false);
        closeEditModal();
    };

    const closeEditModal = () => {
        setShowEditModal(false);
        setSelectedPlan(null);
        setPlanData({});
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Subscription Plans</h1>

            {/* ERROR Display */}
            {error && (
                <div className="bg-red-500 text-white px-4 py-2 rounded mb-4">
                    {error}
                </div>
            )}

            {/* Search bar + Add button */}
            <SubscriptionSearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                onAddPlanClick={() => setShowAddModal(true)}
            />

            {/* Table of Plans */}
            <SubscriptionsTable
                filteredPlans={filteredPlans}
                handleEditClick={handleEditClick}
                handleStatusToggle={handleStatusToggle}
                loading={!plansFetched}
                searchCustomerId={customerIdParam}  // Pass the customerId for auto-expansion in the expanded rows
            />

            {/* Add Plan Modal */}
            {showAddModal && (
                <AddPlanModal
                    closeAddModal={closeAddModal}
                    addPlanData={addPlanData}
                    setAddPlanData={setAddPlanData}
                    handleAddPlan={handleAddPlan}
                    isAdding={isAddingPlan}
                />
            )}

            {/* Deactivate/Activate Modal */}
            {showDeactivateModal && selectedPlan && (
                <DeactivatePlanModal
                    selectedPlan={selectedPlan}
                    planIdInput={planIdInput}
                    setPlanIdInput={setPlanIdInput}
                    handleConfirmDeactivation={handleConfirmDeactivation}
                    closeDeactivateModal={closeDeactivateModal}
                    isToggling={isTogglingPlan}
                />
            )}

            {/* Edit Plan Modal */}
            {showEditModal && selectedPlan && (
                <EditPlanModal
                    selectedPlan={selectedPlan}
                    planData={planData}
                    setPlanData={setPlanData}
                    closeEditModal={closeEditModal}
                    handleUpdatePlan={handleUpdatePlan}
                    hasEnrolledUsers={
                        selectedPlan.Customer_Subscription_Plan_Enrolled &&
                        selectedPlan.Customer_Subscription_Plan_Enrolled.length > 0
                    }
                    isUpdating={isUpdatingPlan}
                />
            )}
        </div>
    );
};

export default Subscriptions;
