import React, { useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import { FaTimes } from "react-icons/fa";

const NewChatModal = ({ onClose, onChatCreated, adminId, preselectedParticipant }) => {
    // If a participant is preselected, start at step 2
    const [step, setStep] = useState(preselectedParticipant ? 2 : 1);
    const [participantSearch, setParticipantSearch] = useState("");
    const [dealSearch, setDealSearch] = useState("");
    const [participants, setParticipants] = useState([]); // Combined list of vendors and customers
    const [deals, setDeals] = useState([]);
    const [selectedParticipant, setSelectedParticipant] = useState(preselectedParticipant || null);
    const [selectedDeal, setSelectedDeal] = useState(null);
    const [creating, setCreating] = useState(false);

    // Fetch vendors and customers for participant selection (only if not preselected)
    useEffect(() => {
        if (!preselectedParticipant) {
            const fetchParticipants = async () => {
                try {
                    // Fetch vendors.
                    const { data: vendors, error: vendorError } = await supabase
                        .from("Vendors")
                        .select("vendor_id, business_name");
                    if (vendorError) console.error("Error fetching vendors:", vendorError);

                    // Fetch customers.
                    const { data: customers, error: customerError } = await supabase
                        .from("Customers")
                        .select("customer_id, first_name, last_name");
                    if (customerError) console.error("Error fetching customers:", customerError);

                    // Map vendors and customers into a common structure.
                    const vendorList = vendors
                        ? vendors.map((v) => ({
                            id: v.vendor_id,
                            name: v.business_name,
                            type: "vendor",
                        }))
                        : [];
                    const customerList = customers
                        ? customers.map((c) => ({
                            id: c.customer_id,
                            name: `${c.first_name} ${c.last_name}`,
                            type: "customer",
                        }))
                        : [];
                    setParticipants([...vendorList, ...customerList]);
                } catch (err) {
                    console.error("Error fetching participants:", err);
                }
            };

            fetchParticipants();
        }
    }, [preselectedParticipant]);

    // New effect: If a participant is preselected, fetch deals for that participant automatically.
    useEffect(() => {
        if (preselectedParticipant) {
            fetchDealsForParticipant(preselectedParticipant);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preselectedParticipant]);

    // Fetch deals associated with the selected participant.
    const fetchDealsForParticipant = async (participant) => {
        try {
            if (participant.type === "vendor") {
                // For vendors, fetch deals where vendor_id matches.
                const { data, error } = await supabase
                    .from("Deals")
                    .select("deal_id, deal_title")
                    .eq("vendor_id", participant.id);
                if (error) {
                    console.error("Error fetching deals for vendor:", error);
                    setDeals([]);
                } else {
                    setDeals(data);
                }
            } else if (participant.type === "customer") {
                // For customers, fetch coupons then get deals linked to those coupons.
                const { data: coupons, error: couponError } = await supabase
                    .from("Coupons")
                    .select("deal_id")
                    .eq("customer_id", participant.id);
                if (couponError) {
                    console.error("Error fetching coupons for customer:", couponError);
                    setDeals([]);
                } else {
                    const dealIds = coupons.map((coupon) => coupon.deal_id);
                    if (dealIds.length > 0) {
                        const { data, error } = await supabase
                            .from("Deals")
                            .select("deal_id, deal_title")
                            .in("deal_id", dealIds);
                        if (error) {
                            console.error("Error fetching deals for customer:", error);
                            setDeals([]);
                        } else {
                            setDeals(data);
                        }
                    } else {
                        setDeals([]);
                    }
                }
            }
        } catch (err) {
            console.error("Error fetching deals:", err);
            setDeals([]);
        }
    };

    const handleParticipantSelect = (participant) => {
        setSelectedParticipant(participant);
        // Fetch deals for the selected participant before moving to step 2.
        fetchDealsForParticipant(participant);
        setStep(2);
    };

    const handleDealSelect = (deal) => {
        setSelectedDeal(deal);
    };

    const handleCreateChat = async () => {
        if (!selectedParticipant || !selectedDeal) return;
        setCreating(true);
        try {
            // Generate chat id as "chat_" followed by a random 6-digit number.
            const randomSixDigit = Math.floor(Math.random() * 900000) + 100000;
            const chatId = `chat_${randomSixDigit}`;

            const newChat = {
                chat_id: chatId,
                participant_1: adminId,
                participant_2: selectedParticipant.id,
                deal_id: selectedDeal.deal_id,
                last_message: "",
                last_message_time: new Date().toISOString(),
                unread_count: 0,
                participant1_unread_count: 0,
                participant2_unread_count: 0,
            };

            const { error } = await supabase.from("ChatSession").insert([newChat]);
            if (error) {
                console.error("Error creating new chat:", error);
            } else {
                onChatCreated(newChat);
                onClose();
            }
        } catch (err) {
            console.error("Error creating chat:", err);
        } finally {
            setCreating(false);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Modal overlay */}
            <div className="absolute inset-0 bg-black opacity-50 transition-opacity"></div>
            {/* Modal content */}
            <div className="bg-white rounded-lg shadow-lg z-10 max-w-lg w-full mx-4 animate-fadeIn">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-xl font-bold text-gray-800">Start New Chat</h2>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800 transition">
                        <FaTimes />
                    </button>
                </div>
                <div className="p-4">
                    {step === 1 && (
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Select a Participant</h3>
                            <input
                                type="text"
                                placeholder="Search by name or ID..."
                                value={participantSearch}
                                onChange={(e) => setParticipantSearch(e.target.value)}
                                className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-300 transition"
                            />
                            <div className="max-h-60 overflow-y-auto">
                                {participants
                                    .filter(
                                        (p) =>
                                            p.name.toLowerCase().includes(participantSearch.toLowerCase()) ||
                                            p.id.toLowerCase().includes(participantSearch.toLowerCase())
                                    )
                                    .map((p) => (
                                        <div
                                            key={p.id}
                                            onClick={() => handleParticipantSelect(p)}
                                            className="p-3 mb-2 rounded cursor-pointer hover:bg-blue-50 transition-colors border border-transparent hover:border-blue-300"
                                        >
                                            <p className="font-semibold">{p.name}</p>
                                            <p className="text-sm text-gray-500">
                                                {p.id} ({p.type})
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {step === 2 && selectedParticipant && (
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Select a Deal</h3>
                            <p className="mb-4">
                                Participant: <strong>{selectedParticipant.name}</strong> ({selectedParticipant.type})
                            </p>
                            <input
                                type="text"
                                placeholder="Search by deal title or ID..."
                                value={dealSearch}
                                onChange={(e) => setDealSearch(e.target.value)}
                                className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-300 transition"
                            />
                            <div className="max-h-60 overflow-y-auto">
                                {deals
                                    .filter(
                                        (d) =>
                                            d.deal_title.toLowerCase().includes(dealSearch.toLowerCase()) ||
                                            d.deal_id.toLowerCase().includes(dealSearch.toLowerCase())
                                    )
                                    .map((d) => (
                                        <div
                                            key={d.deal_id}
                                            onClick={() => handleDealSelect(d)}
                                            className={`p-3 mb-2 rounded cursor-pointer transition-colors border ${
                                                selectedDeal && selectedDeal.deal_id === d.deal_id
                                                    ? "bg-blue-100 border-blue-300"
                                                    : "bg-gray-50 border-transparent hover:bg-blue-50 hover:border-blue-300"
                                            }`}
                                        >
                                            <p className="font-semibold">{d.deal_title}</p>
                                            <p className="text-sm text-gray-500">Deal ID: {d.deal_id}</p>
                                        </div>
                                    ))}
                            </div>
                            <div className="mt-4 flex justify-between items-center">
                                <button
                                    onClick={() => setStep(1)}
                                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition"
                                >
                                    Back
                                </button>
                                <button
                                    onClick={handleCreateChat}
                                    disabled={!selectedDeal || creating}
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition disabled:opacity-50"
                                >
                                    {creating ? "Creating..." : "Create Chat"}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NewChatModal;
