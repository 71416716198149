import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    // Initialize from sessionStorage so the login persists in the session.
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const storedAuth = sessionStorage.getItem("isAuthenticated");
        return storedAuth === "true";
    });

    const login = () => {
        setIsAuthenticated(true);
        sessionStorage.setItem("isAuthenticated", "true");
    };

    const logout = () => {
        setIsAuthenticated(false);
        sessionStorage.removeItem("isAuthenticated");
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
