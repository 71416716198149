// src/components/Sidebar.js
import React, { useState, useEffect } from 'react';
import {
  FaUser,           // For Customers
  FaUsers,          // For Vendors
  FaMoneyCheckAlt,  // For Coupons
  FaThList,         // For Categories
  FaSignOutAlt,     // For Sign Out
  FaTags,           // For Deals
  FaCreditCard,     // For Subscriptions
  FaStore,          // For Store Locations
  FaCog,            // For App Config
  FaWallet,         // For Wallet menus
  FaAngleDown,
  FaAngleUp,
  FaChartLine,      // For Revenue menu
  FaComment
} from 'react-icons/fa';
import { AiOutlineDashboard } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import logo from '../assets/logo-wah.png'; // Ensure the path is correct

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  // Revenue submenu: auto-open if route starts with /revenue
  const isRevenueRoute = location.pathname.startsWith('/revenue');
  const [revenueSubmenuOpen, setRevenueSubmenuOpen] = useState(isRevenueRoute);

  // Wallet submenu: auto-open if route starts with /wallet
  const isWalletRoute = location.pathname.startsWith('/wallet');
  const [walletSubmenuOpen, setWalletSubmenuOpen] = useState(isWalletRoute);

  // Update submenu states when the route changes
  useEffect(() => {
    if (isRevenueRoute) setRevenueSubmenuOpen(true);
  }, [isRevenueRoute]);

  useEffect(() => {
    if (isWalletRoute) setWalletSubmenuOpen(true);
  }, [isWalletRoute]);

  // Handle sign-out
  const handleSignOut = () => {
    logout(); // Clears auth state and session storage if necessary
    navigate("/", { replace: true }); // Redirect to login page
  };

  return (
      <div className="w-64 h-screen bg-gray-800 text-white flex flex-col justify-between shadow-lg">
        <div>
          {/* Logo */}
          <div className="p-6 flex items-center justify-center">
            <img src={logo} alt="WAH Admin Panel" className="h-16" />
          </div>
          {/* Navigation Links */}
          <nav className="mt-6">
            <ul className="space-y-2 text-sm">
              <li>
                <a
                    href="/dashboard"
                    className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <AiOutlineDashboard className="mr-3 text-lg" />
                  <span>Dashboard</span>
                </a>
              </li>
              <li>
                <a
                    href="/vendors"
                    className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <FaUsers className="mr-3 text-lg" />
                  <span>Vendors</span>
                </a>
              </li>
              <li>
                <a
                    href="/customers"
                    className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <FaUser className="mr-3 text-lg" />
                  <span>Customers</span>
                </a>
              </li>
              <li>
                <a
                    href="/Deals"
                    className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <FaTags className="mr-3 text-lg" />
                  <span>Deals</span>
                </a>
              </li>
              <li>
                <a
                    href="/coupons"
                    className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <FaMoneyCheckAlt className="mr-3 text-lg" />
                  <span>Coupons</span>
                </a>
              </li>
              <li>
                <a
                    href="/Categories"
                    className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <FaThList className="mr-3 text-lg" />
                  <span>Categories</span>
                </a>
              </li>
              <li>
                <a
                    href="/storeLocations"
                    className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <FaStore className="mr-3 text-lg" />
                  <span>Store Locations</span>
                </a>
              </li>
              <li>
                <a
                    href="/subscriptions"
                    className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <FaCreditCard className="mr-3 text-lg" />
                  <span>Subscriptions</span>
                </a>
              </li>
              <li>
                <a
                    href="/adminChat"
                    className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <FaComment className="mr-3 text-lg" />
                  <span>Chat</span>
                </a>
              </li>
              <li>
                <a
                    href="/appConfig"
                    className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <FaCog className="mr-3 text-lg" />
                  <span>App Config</span>
                </a>
              </li>
              {/* Revenue submenu */}
              <li>
                <button
                    onClick={() => setRevenueSubmenuOpen(!revenueSubmenuOpen)}
                    className="flex items-center w-full px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <FaChartLine className="mr-3 text-lg" />
                  <span>Revenue</span>
                  {revenueSubmenuOpen ? <FaAngleUp className="ml-auto" /> : <FaAngleDown className="ml-auto" />}
                </button>
                {revenueSubmenuOpen && (
                    <ul className="ml-6 mt-2 space-y-2 text-sm">
                      <li>
                        <a
                            href="/revenue/vendor"
                            className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                        >
                          <FaUsers className="mr-3 text-lg" />
                          <span>Vendor</span>
                        </a>
                      </li>
                      <li>
                        <a
                            href="/revenue/customer"
                            className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                        >
                          <FaUser className="mr-3 text-lg" />
                          <span>Customer</span>
                        </a>
                      </li>
                      <li>
                        <a
                            href="/revenue/wah"
                            className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                        >
                          <FaWallet className="mr-3 text-lg" />
                          <span>Wah!</span>
                        </a>
                      </li>
                    </ul>
                )}
              </li>
              {/* Wallet submenu */}
              <li>
                <button
                    onClick={() => setWalletSubmenuOpen(!walletSubmenuOpen)}
                    className="flex items-center w-full px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                >
                  <FaWallet className="mr-3 text-lg" />
                  <span>Wallet</span>
                  {walletSubmenuOpen ? <FaAngleUp className="ml-auto" /> : <FaAngleDown className="ml-auto" />}
                </button>
                {walletSubmenuOpen && (
                    <ul className="ml-6 mt-2 space-y-2 text-sm">
                      <li>
                        <a
                            href="/wallet/vendor"
                            className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                        >
                          <FaUsers className="mr-3 text-lg" />
                          <span>Vendor</span>
                        </a>
                      </li>
                      <li>
                        <a
                            href="/wallet/customer"
                            className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                        >
                          <FaUser className="mr-3 text-lg" />
                          <span>Customer</span>
                        </a>
                      </li>
                    </ul>
                )}
              </li>
            </ul>
          </nav>
        </div>
        {/* Build Version and Sign Out */}
        <div className="p-6">
          <div className="mb-4 text-center text-gray-400 text-xs">
            Build No. 1.0.5
          </div>
          <button
              onClick={handleSignOut}
              className="flex items-center justify-center w-full px-6 py-2 text-gray-300 hover:text-red-500 hover:bg-gray-700 rounded-lg"
          >
            <FaSignOutAlt className="mr-2 text-lg" />
            <span>Sign Out</span>
          </button>
        </div>
      </div>
  );
};

export default Sidebar;
