import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    fetchLocations,
    addLocation,
    updateLocationAdmin,
    toggleLocationStatus,
} from '../../redux/actions/storeLocationActions';
import { getVendorsAdmin } from '../../api/api';
import StoreLocationSearchBar from './StoreLocationSearchBar';
import StoreLocationsTable from './StoreLocationsTable';
import AddLocationModal from './AddLocationModal';
import EditLocationModal from './EditLocationModal';
import DeactivateStoreModal from './DeactivateStoreModal';

const StoreLocations = () => {
    const dispatch = useDispatch();
    const locationHook = useLocation();
    // Assuming your redux state has a loading field for storeLocations,
    // but we use a local flag to ensure the spinner shows until fetch completes.
    const { locations, loading, error } = useSelector((state) => state.storeLocations || {});

    const [vendors, setVendors] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const [newLocationData, setNewLocationData] = useState({
        vendor_id: '',
        store_manager: '',
        mobile_phone: '',
        store_email: '',
        address: '',
        street: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        active: true,
    });

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [editedLocationData, setEditedLocationData] = useState({});

    // State for the deactivation modal
    const [showDeactivateStoreModal, setShowDeactivateStoreModal] = useState(false);
    const [storeIdInput, setStoreIdInput] = useState('');
    const [storeToggleTarget, setStoreToggleTarget] = useState(null);
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [isAddingLocation, setIsAddingLocation] = useState(false);

    // NEW: state to determine if locations have been fetched
    const [locationsFetched, setLocationsFetched] = useState(false);

    useEffect(() => {
        // Dispatch fetchLocations and wait for it to complete before marking locations as fetched.
        const fetchData = async () => {
            await dispatch(fetchLocations(null));
            setLocationsFetched(true);
        };
        fetchData();
        loadVendors();
        // eslint-disable-next-line
    }, [dispatch]);

    // Pre-filter search if query parameters are provided.
    useEffect(() => {
        const params = new URLSearchParams(locationHook.search);
        const storeLocationId = params.get('storeLocationId');
        const vendorId = params.get('vendorId');
        if (storeLocationId) {
            setSearchTerm(storeLocationId);
        } else if (vendorId) {
            setSearchTerm(vendorId);
        }
    }, [locationHook.search]);

    const loadVendors = async () => {
        try {
            const result = await getVendorsAdmin();
            setVendors(result);
        } catch (err) {
            console.error('Error fetching vendors:', err);
        }
    };

    const filteredLocations = locations?.filter((loc) => {
        const locationId = (loc.location_id || '').toLowerCase();
        const address = (loc.address || '').toLowerCase();
        const manager = (loc.store_manager || '').toLowerCase();
        const vendorId = (loc.vendor_id || '').toLowerCase();
        const term = searchTerm.toLowerCase();
        return (
            locationId.includes(term) ||
            address.includes(term) ||
            manager.includes(term) ||
            vendorId.includes(term)
        );
    });

    // Handler for adding a new location.
    const handleAddLocation = async () => {
        if (!newLocationData.vendor_id) {
            alert('Please select a vendor.');
            return;
        }
        setIsAddingLocation(true);
        await dispatch(addLocation(newLocationData));
        setIsAddingLocation(false);
        setShowAddModal(false);
        setNewLocationData({
            vendor_id: '',
            store_manager: '',
            mobile_phone: '',
            store_email: '',
            address: '',
            street: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
            active: true,
        });
    };

    const closeAddModal = () => {
        setShowAddModal(false);
        setNewLocationData({
            vendor_id: '',
            store_manager: '',
            mobile_phone: '',
            store_email: '',
            address: '',
            street: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
            active: true,
        });
    };

    // Handler for editing a location.
    const handleEditClick = (loc) => {
        setSelectedLocation(loc);
        setEditedLocationData({ ...loc });
        setShowEditModal(true);
    };

    const handleUpdateLocation = async () => {
        await dispatch(
            updateLocationAdmin(editedLocationData.location_id, {
                vendor_id: editedLocationData.vendor_id,
                store_manager: editedLocationData.store_manager,
                mobile_phone: editedLocationData.mobile_phone,
                store_email: editedLocationData.store_email,
                address: editedLocationData.address,
                street: editedLocationData.street,
                city: editedLocationData.city,
                state: editedLocationData.state,
                country: editedLocationData.country,
                zipcode: editedLocationData.zipcode,
                active: editedLocationData.active,
            })
        );
        // After updating, re-fetch the locations list so the changes appear.
        dispatch(fetchLocations(null));
        closeEditModal();
    };

    const closeEditModal = () => {
        setShowEditModal(false);
        setSelectedLocation(null);
        setEditedLocationData({});
    };

    // Open the confirmation modal for toggling location status.
    const openDeactivateStoreModal = (loc) => {
        setStoreToggleTarget(loc);
        setStoreIdInput('');
        setShowDeactivateStoreModal(true);
    };

    const closeDeactivateStoreModal = () => {
        setShowDeactivateStoreModal(false);
        setStoreToggleTarget(null);
        setStoreIdInput('');
    };

    // Called when the user confirms the toggle in the modal.
    const handleConfirmToggle = async () => {
        if (!storeToggleTarget) return;
        setIsUpdatingStatus(true);
        try {
            const newStatus = !storeToggleTarget.active;
            // Dispatch redux action for toggling location status.
            await dispatch(toggleLocationStatus(storeToggleTarget.location_id, newStatus));
            dispatch(fetchLocations(null));
            alert(
                `Store location ${storeToggleTarget.location_id} has been ${
                    newStatus ? 'activated' : 'deactivated'
                } successfully.`
            );
        } catch (err) {
            console.error('Error toggling store location status:', err);
            alert('An error occurred while updating the store location status.');
        }
        setIsUpdatingStatus(false);
        closeDeactivateStoreModal();
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Store Locations</h1>
            {error && (
                <div className="bg-red-500 text-white px-4 py-2 rounded mb-4">
                    {error}
                </div>
            )}
            <StoreLocationSearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                onAddLocationClick={() => setShowAddModal(true)}
            />
            <StoreLocationsTable
                filteredLocations={filteredLocations}
                handleEditClick={handleEditClick}
                handleToggleStatus={openDeactivateStoreModal}
                loading={!locationsFetched}
            />
            {showAddModal && (
                <AddLocationModal
                    vendors={vendors}
                    newLocationData={newLocationData}
                    setNewLocationData={setNewLocationData}
                    closeAddModal={closeAddModal}
                    handleAddLocation={handleAddLocation}
                    isAdding={isAddingLocation}
                />
            )}
            {showEditModal && selectedLocation && (
                <EditLocationModal
                    selectedLocation={selectedLocation}
                    editedLocationData={editedLocationData}
                    setEditedLocationData={setEditedLocationData}
                    closeEditModal={closeEditModal}
                    handleUpdateLocation={handleUpdateLocation}
                    vendors={vendors}
                />
            )}
            {showDeactivateStoreModal && storeToggleTarget && (
                <DeactivateStoreModal
                    selectedLocation={storeToggleTarget}
                    locationIdInput={storeIdInput}
                    setLocationIdInput={setStoreIdInput}
                    closeDeactivateStoreModal={closeDeactivateStoreModal}
                    handleConfirmToggle={handleConfirmToggle}
                    isUpdatingStatus={isUpdatingStatus}
                />
            )}
        </div>
    );
};

export default StoreLocations;
