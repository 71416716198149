// src/redux/reducers/customerReducer.js
import {
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_ERROR,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_ERROR,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_ERROR,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_ERROR,
} from '../types';

const initialState = {
    customers: [],
    error: null,
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CUSTOMERS_SUCCESS:
            return { ...state, customers: action.payload, error: null };
        case FETCH_CUSTOMERS_ERROR:
            return { ...state, customers: [], error: action.payload };

        case ADD_CUSTOMER_SUCCESS:
            return { ...state, error: null };
        case ADD_CUSTOMER_ERROR:
            return { ...state, error: action.payload };

        case UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: state.customers.map((cust) =>
                    cust.customer_id === action.payload.customer_id ? action.payload : cust
                ),
                error: null,
            };
        case UPDATE_CUSTOMER_ERROR:
            return { ...state, error: action.payload };

        case DELETE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: state.customers.filter(
                    (cust) => cust.user_id !== action.payload
                ),
                error: null,
            };
        case DELETE_CUSTOMER_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default customerReducer;
