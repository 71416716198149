// subscriptionActions.js

import {
    getSubscriptionPlansAdmin,
    addSubscriptionPlanAdmin,
    updateSubscriptionPlanAdmin,
    deactivatePlanAdmin,
    activatePlanAdmin,
} from "../../api/api";
import {
    FETCH_SUBSCRIPTIONS_SUCCESS,
    FETCH_SUBSCRIPTIONS_ERROR,
    ADD_SUBSCRIPTION_PLAN_SUCCESS,
    ADD_SUBSCRIPTION_PLAN_ERROR,
    UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
    UPDATE_SUBSCRIPTION_PLAN_ERROR,
} from "../types";

// Fetch all subscription plans (plus enrolled users)
export const fetchSubscriptions = () => async (dispatch) => {
    try {
        const plans = await getSubscriptionPlansAdmin();
        dispatch({ type: FETCH_SUBSCRIPTIONS_SUCCESS, payload: plans });
    } catch (error) {
        dispatch({ type: FETCH_SUBSCRIPTIONS_ERROR, payload: error.message });
    }
};

// Add a new subscription plan
export const addSubscriptionPlan = (planData) => async (dispatch) => {
    try {
        const newPlan = await addSubscriptionPlanAdmin(planData);
        dispatch({ type: ADD_SUBSCRIPTION_PLAN_SUCCESS, payload: newPlan });
        dispatch(fetchSubscriptions()); // re-fetch to keep data in sync
    } catch (error) {
        dispatch({ type: ADD_SUBSCRIPTION_PLAN_ERROR, payload: error.message });
    }
};

// Update an existing subscription plan
export const updateSubscriptionPlan = (planId, updates) => async (dispatch) => {
    try {
        const updatedPlan = await updateSubscriptionPlanAdmin(planId, updates);
        dispatch({ type: UPDATE_SUBSCRIPTION_PLAN_SUCCESS, payload: updatedPlan });
        dispatch(fetchSubscriptions()); // re-fetch to keep data in sync
    } catch (error) {
        dispatch({ type: UPDATE_SUBSCRIPTION_PLAN_ERROR, payload: error.message });
    }
};

// Toggle the status of a plan (activate or deactivate)
export const togglePlanStatus = (planId, isActive) => async (dispatch) => {
    try {
        // If it's currently active, we want to deactivate
        const updated = isActive
            ? await deactivatePlanAdmin(planId)
            : await activatePlanAdmin(planId);

        dispatch({ type: UPDATE_SUBSCRIPTION_PLAN_SUCCESS, payload: updated });
        dispatch(fetchSubscriptions());
    } catch (error) {
        dispatch({ type: UPDATE_SUBSCRIPTION_PLAN_ERROR, payload: error.message });
    }
};
