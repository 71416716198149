// src/redux/reducers/analyticsReducer.js
import { FETCH_ANALYTICS_SUCCESS, FETCH_ANALYTICS_ERROR } from "../types";

const initialState = {
    analytics: null,
    error: null,
};

const analyticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ANALYTICS_SUCCESS:
            return { ...state, analytics: action.payload, error: null };
        case FETCH_ANALYTICS_ERROR:
            return { ...state, analytics: null, error: action.payload };
        default:
            return state;
    }
};

export default analyticsReducer;
