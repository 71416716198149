import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, updateCategory, addCategory, deleteCategory } from "../../redux/actions/categoriesActions";
import { FaTrash, FaEdit, FaTimes, FaSpinner } from "react-icons/fa";

const Categories = () => {
  const dispatch = useDispatch();
  const { categories = [], error } = useSelector((state) => state.categories || {});

  const [searchTerm, setSearchTerm] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryData, setCategoryData] = useState({});

  // NEW: state to determine if categories have been fetched
  const [categoriesFetched, setCategoriesFetched] = useState(false);
  // NEW: states for add and delete loading
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isDeletingCategory, setIsDeletingCategory] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchCategories());
      setCategoriesFetched(true);
    };
    fetchData();
  }, [dispatch]);

  const handleEditClick = (category) => {
    setSelectedCategory(category);
    setCategoryData({ ...category });
    setShowEditModal(true);
  };

  const handleAddClick = () => {
    setCategoryData({ name: "", description: "", status: "Active" });
    setShowAddModal(true);
  };

  const handleDeleteClick = (category) => {
    setSelectedCategory(category);
    setShowDeleteModal(true);
  };

  const handleDeleteCategory = async () => {
    setIsDeletingCategory(true);
    await dispatch(deleteCategory(selectedCategory.category_id));
    setIsDeletingCategory(false);
    closeDeleteModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategoryData({ ...categoryData, [name]: value });
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setSelectedCategory(null);
    setCategoryData({});
  };

  const closeAddModal = () => {
    setShowAddModal(false);
    setCategoryData({});
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedCategory(null);
  };

  const handleUpdateCategory = () => {
    dispatch(updateCategory(categoryData));
    closeEditModal();
  };

  const handleAddCategory = async () => {
    setIsAddingCategory(true);
    await dispatch(addCategory(categoryData));
    setIsAddingCategory(false);
    closeAddModal();
  };

  const filteredCategories = categories.filter(
      (category) =>
          category.category_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-4">Categories</h1>

        {error && (
            <div className="bg-red-500 text-white px-4 py-2 rounded mb-4">
              {error}
            </div>
        )}

        {/* Search Bar and Add Category Button */}
        <div className="flex justify-between items-center mb-4">
          <input
              type="text"
              placeholder="Search by Category ID or Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border border-gray-300 rounded px-4 py-2 w-1/2"
          />
          <button
              onClick={handleAddClick}
              className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Add Category
          </button>
        </div>

        {/* Table */}
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full text-left">
            <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 font-semibold text-left">Category ID</th>
              <th className="py-3 px-6 font-semibold text-left">Name</th>
              <th className="py-3 px-6 font-semibold text-left">Description</th>
              <th className="py-3 px-6 font-semibold text-left">Status</th>
              <th className="py-3 px-6 font-semibold text-left">Actions</th>
            </tr>
            </thead>
            <tbody className="text-gray-700 text-sm font-light">
            {!categoriesFetched ? (
                <tr>
                  <td colSpan="5" className="py-3 px-6 text-center">
                    <div className="flex items-center justify-center space-x-2">
                      <FaSpinner className="animate-spin" size={24} />
                      <span>Loading categories, please wait...</span>
                    </div>
                  </td>
                </tr>
            ) : filteredCategories.length > 0 ? (
                filteredCategories.map((category) => (
                    <tr
                        key={category.category_id}
                        className={`border-b border-gray-200 ${
                            category.status === "Active" ? "bg-green-50" : "bg-red-50"
                        } hover:bg-gray-100`}
                    >
                      <td className="py-3 px-6 font-bold">
                        {category.category_id.split("_")[1]}
                      </td>
                      <td className="py-3 px-6 font-bold">{category.name}</td>
                      <td className="py-3 px-6 font-bold">{category.description || "N/A"}</td>
                      <td className="py-3 px-6 font-bold">{category.status}</td>
                      <td className="py-3 px-6 flex justify-between items-center">
                        <button
                            onClick={() => handleEditClick(category)}
                            className="text-blue-500 font-medium flex items-center"
                        >
                          <FaEdit className="mr-1" />
                          View/Edit
                        </button>
                        <button
                            onClick={() => handleDeleteClick(category)}
                            className="text-red-500 font-medium flex items-center"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                ))
            ) : (
                <tr>
                  <td colSpan="5" className="py-3 px-6 text-center">
                    No categories found.
                  </td>
                </tr>
            )}
            </tbody>
          </table>
        </div>

        {/* Edit Modal */}
        {showEditModal && selectedCategory && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg max-w-2xl w-full relative">
                <button
                    onClick={closeEditModal}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
                >
                  <FaTimes size={18} />
                </button>
                <h3 className="text-xl font-bold mb-4">Edit Category</h3>
                <div className="space-y-4">
                  <div>
                    <label>
                      <strong>Category Name:</strong>
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={categoryData.name}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                  </div>
                  <div>
                    <label>
                      <strong>Description:</strong>
                    </label>
                    <textarea
                        name="description"
                        value={categoryData.description || ""}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded w-full p-2"
                    ></textarea>
                  </div>
                  <div>
                    <label>
                      <strong>Status:</strong>
                    </label>
                    <select
                        name="status"
                        value={categoryData.status}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded w-full p-2"
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                  <button
                      onClick={handleUpdateCategory}
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
        )}

        {/* Add Modal */}
        {showAddModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg max-w-2xl w-full relative">
                <button
                    onClick={closeAddModal}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
                >
                  <FaTimes size={18} />
                </button>
                <h3 className="text-xl font-bold mb-4">Add Category</h3>
                <div className="space-y-4">
                  <div>
                    <label>
                      <strong>Category Name:</strong>
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={categoryData.name}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                  </div>
                  <div>
                    <label>
                      <strong>Description:</strong>
                    </label>
                    <textarea
                        name="description"
                        value={categoryData.description || ""}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded w-full p-2"
                    ></textarea>
                  </div>
                  <div>
                    <label>
                      <strong>Status:</strong>
                    </label>
                    <select
                        name="status"
                        value={categoryData.status}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded w-full p-2"
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                  <button
                      onClick={handleAddCategory}
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    {isAddingCategory ? <FaSpinner className="animate-spin" size={16} /> : "Add Category"}
                  </button>
                </div>
              </div>
            </div>
        )}

        {/* Delete Modal */}
        {showDeleteModal && selectedCategory && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg max-w-md w-full text-center">
                <h3 className="text-lg font-bold mb-4">Delete Category</h3>
                <p>
                  Would you like to delete the category <strong>{selectedCategory.name}</strong>? All deals under this category will be moved to "Others".
                </p>
                <div className="flex justify-between mt-6">
                  <button
                      onClick={closeDeleteModal}
                      className="bg-gray-300 px-4 py-2 rounded"
                  >
                    Cancel
                  </button>
                  <button
                      onClick={handleDeleteCategory}
                      className="bg-red-500 text-white px-4 py-2 rounded"
                  >
                    {isDeletingCategory ? <FaSpinner className="animate-spin" size={16} /> : "Delete"}
                  </button>
                </div>
              </div>
            </div>
        )}
      </div>
  );
};

export default Categories;
