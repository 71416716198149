// src/redux/actions/analyticsActions.js
import { FETCH_ANALYTICS_SUCCESS, FETCH_ANALYTICS_ERROR } from "../types";
import { getAnalyticsAdmin } from "../../api/api";

// Action to fetch analytics data from the backend edge function
export const fetchAnalytics = () => async (dispatch) => {
    try {
        const analyticsData = await getAnalyticsAdmin();
        dispatch({ type: FETCH_ANALYTICS_SUCCESS, payload: analyticsData });
    } catch (error) {
        dispatch({ type: FETCH_ANALYTICS_ERROR, payload: error.message });
    }
};
