import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchAppConfigs } from "../../redux/actions/appConfigActions";
import AppConfigTable from "./AppConfigTable";
import AddAppConfigModal from "./AddAppConfigModal";
import EditAppConfigModal from "./EditAppConfigModal";

const AppConfig = () => {
    const dispatch = useDispatch();
    const { configs, error } = useSelector((state) => state.appConfig);
    const location = useLocation();

    const [searchTerm, setSearchTerm] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState(null);

    // NEW: state to determine if configs have been fetched
    const [configsFetched, setConfigsFetched] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchAppConfigs());
            setConfigsFetched(true);
        };
        fetchData();
    }, [dispatch]);

    // Filter by app_version or maintenance_message
    const filteredConfigs = configs?.filter((cfg) =>
        cfg.app_version.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (cfg.maintenance_message &&
            cfg.maintenance_message.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handleEditClick = (config) => {
        setSelectedConfig(config);
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
        setSelectedConfig(null);
    };

    const closeAddModal = () => {
        setShowAddModal(false);
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">App Configurations</h1>
            {error && (
                <div className="bg-red-500 text-white px-4 py-2 rounded mb-4">
                    {error}
                </div>
            )}
            <div className="flex justify-between items-center mb-4">
                <input
                    type="text"
                    placeholder="Search by App Version or Message"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-gray-300 rounded px-4 py-2 w-1/2"
                />
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={() => setShowAddModal(true)}
                >
                    Add Config
                </button>
            </div>
            <AppConfigTable
                configs={filteredConfigs}
                onEditClick={handleEditClick}
                loading={!configsFetched}
            />
            {showAddModal && (
                <AddAppConfigModal
                    closeAddModal={closeAddModal}
                    existingConfig={configs && configs.length > 0 ? configs[0] : {}}
                />
            )}
            {showEditModal && selectedConfig && (
                <EditAppConfigModal config={selectedConfig} closeEditModal={closeEditModal} />
            )}
        </div>
    );
};

export default AppConfig;
