import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRevenue } from '../../redux/actions/revenueActions';
import RevenueVendorTable from './RevenueVendorTable';
import RevenueSearchBar from './RevenueSearchBar';
import TotalsCard from './TotalsCard';
import { FaSpinner } from 'react-icons/fa';

const RevenueVendorPage = () => {
    const dispatch = useDispatch();
    const { revenue, error } = useSelector((state) => state.revenue || { revenue: null });
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        dispatch(fetchRevenue());
    }, [dispatch]);

    // Extract vendor data with two arrays: baseCoupons and upsell.
    const vendorData = useMemo(() => {
        return revenue ? revenue.vendor : { baseCoupons: [], upsell: [] };
    }, [revenue]);

    // Merge both arrays into one combined list.
    const combinedData = useMemo(() => {
        return [
            ...(vendorData.baseCoupons || []),
            ...(vendorData.upsell || [])
        ];
    }, [vendorData]);

    // Merge coupon data (which contains regular_price) into vendor deals if missing regular_price.
    const mergedCombinedData = useMemo(() => {
        return combinedData.map(deal => {
            if (deal.regular_price == null && revenue && revenue.coupons && revenue.coupons.ppu) {
                const couponDeal = revenue.coupons.ppu.find(coupon => coupon.deal_id === deal.deal_id);
                if (couponDeal && couponDeal.regular_price != null) {
                    return { ...deal, regular_price: couponDeal.regular_price };
                }
            }
            return deal;
        });
    }, [combinedData, revenue]);

    // Filter merged data based on searchTerm (checking both ID and vendor_name).
    const filteredData = useMemo(() => {
        if (!searchTerm) return mergedCombinedData;
        const term = searchTerm.toLowerCase();
        return mergedCombinedData.filter(item =>
            (item.vend_id && item.vend_id.toLowerCase().includes(term)) ||
            (item.vendor_name && item.vendor_name.toLowerCase().includes(term)) ||
            (item.deal_id && item.deal_id.toLowerCase().includes(term)) ||
            (item.upsell_id && item.upsell_id.toLowerCase().includes(term))
        );
    }, [mergedCombinedData, searchTerm]);

    // For totals, only use base items to avoid double counting.
    const baseDataForRaw = useMemo(() => {
        return filteredData.filter(item => !item.upsell_id);
    }, [filteredData]);

    const globalFilteredData = filteredData;

    const totalsTitle = searchTerm
        ? `Vendor Totals for "${searchTerm}"`
        : 'Vendor Totals (All Vendors)';

    const totalsConfig = {
        custom: {
            base_vendor_net_revenue: () =>
                globalFilteredData
                    .filter(item => !item.upsell_id)
                    .reduce((sum, item) => {
                        const wahDealPrice = Number(item.wah_deal_price || 0);
                        const totalFees = Number(item.wah_fee || 0) + Number(item.stripe_fee || 0);
                        const diff = Math.max(0, wahDealPrice - totalFees);
                        return sum + diff * Number(item.coupons_redeemed || 0);
                    }, 0),
            base_vendor_revenue_forecast: () =>
                globalFilteredData
                    .filter(item => !item.upsell_id)
                    .reduce((sum, item) => {
                        const wahDealPrice = Number(item.wah_deal_price || 0);
                        const totalFees = Number(item.wah_fee || 0) + Number(item.stripe_fee || 0);
                        const diff = Math.max(0, wahDealPrice - totalFees);
                        return sum + diff * Number(item.coupon_count || 0);
                    }, 0),
            upsell_revenue: () =>
                globalFilteredData
                    .filter(item => item.upsell_id)
                    .reduce((sum, item) => sum + Number(item.upsell_revenue || 0), 0),
            vendor_net_revenue: () => {
                const baseNet = globalFilteredData
                    .filter(item => !item.upsell_id)
                    .reduce((sum, item) => {
                        const wahDealPrice = Number(item.wah_deal_price || 0);
                        const totalFees = Number(item.wah_fee || 0) + Number(item.stripe_fee || 0);
                        const diff = Math.max(0, wahDealPrice - totalFees);
                        return sum + diff * Number(item.coupons_redeemed || 0);
                    }, 0);
                const upsellRev = globalFilteredData
                    .filter(item => item.upsell_id)
                    .reduce((sum, item) => sum + Number(item.upsell_revenue || 0), 0);
                return baseNet + upsellRev;
            },
            vendor_revenue_forecast: () =>
                globalFilteredData
                    .filter(item => !item.upsell_id)
                    .reduce((sum, item) => {
                        const wahDealPrice = Number(item.wah_deal_price || 0);
                        const totalFees = Number(item.wah_fee || 0) + Number(item.stripe_fee || 0);
                        const diff = Math.max(0, wahDealPrice - totalFees);
                        return sum + diff * Number(item.coupon_count || 0);
                    }, 0),
            wah_fee: () =>
                globalFilteredData
                    .filter(item => !item.upsell_id)
                    .reduce((sum, item) => {
                        const fee = Number(item.wah_fee || 0);
                        const count = Number(item.coupon_count || 0);
                        return sum + fee * count;
                    }, 0)
        },
        noCurrency: [
            'coupon_count',
            'coupons_redeemed',
            'vendor_count',
            'coupons_generated'
        ],
        fields: [
            { key: 'vendor_revenue_forecast', label: 'REVENUE FORECAST' },
            { key: 'base_vendor_net_revenue', label: 'NET REVENUE: Coupons' },
            { key: 'upsell_revenue', label: 'NET REVENUE: UPSELL' },
            { key: 'vendor_net_revenue', label: 'NET REVENUE: TOTAL' },
            { key: 'wah_fee', label: 'TOTAL WAH FEE PAID' },
            { key: 'stripe_fee', label: 'TOTAL STRIPE FEE PAID' },
            { key: 'coupon_count', label: 'COUPON COUNT: PUBLISHED' },
            { key: 'coupons_redeemed', label: 'COUPONS REDEEMED' },
        ]
    };

    const vendorCount = useMemo(() => {
        const vendors = filteredData.map(item => item.vend_id);
        return new Set(vendors).size;
    }, [filteredData]);

    if (!revenue) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <FaSpinner className="animate-spin text-4xl text-blue-500" />
                <p className="mt-4 text-lg">Loading revenue data, please wait...</p>
            </div>
        );
    }

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Vendor Value Realization</h1>
            {error && (
                <div className="bg-red-200 text-red-800 px-4 py-2 rounded mb-4">
                    {error}
                </div>
            )}
            <RevenueSearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                filterSource="Vendor"
            />
            <div className="mb-4">
                <span className="text-lg font-medium">Vendor Count: {vendorCount}</span>
            </div>
            <TotalsCard
                data={baseDataForRaw}
                title={totalsTitle}
                config={totalsConfig}
                loading={false}
            />
            <div className="mt-6">
                <h2 className="text-xl font-bold mb-4">Vendor Value Realization</h2>
                <RevenueVendorTable
                    data={filteredData}
                    searchTerm={searchTerm}
                    showWahRevenue={false}
                />
            </div>
        </div>
    );
};

export default RevenueVendorPage;
