import {
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDORS_ERROR,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_ERROR,
  ADD_VENDOR_SUCCESS,
  ADD_VENDOR_ERROR,
} from '../types';

import {
  getVendorsAdmin,
  updateVendorAdmin,
  addVendorAdmin,
} from '../../api/api';

// Fetch all vendors
export const fetchVendors = () => async (dispatch) => {
  try {
    const vendors = await getVendorsAdmin();
    dispatch({ type: FETCH_VENDORS_SUCCESS, payload: vendors });
  } catch (error) {
    dispatch({ type: FETCH_VENDORS_ERROR, payload: error.message });
  }
};

// Add vendor
export const addVendor = (vendorData) => async (dispatch) => {
  try {
    const response = await addVendorAdmin(vendorData);
    dispatch({ type: ADD_VENDOR_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({ type: ADD_VENDOR_ERROR, payload: error.message });
    throw error;
  }
};

// Update vendor (details or status)
export const updateVendor = (vendorId, updates, deactivate, action) => async (dispatch) => {
  try {
    const updatedVendor = await updateVendorAdmin(vendorId, updates, deactivate, action);
    dispatch({ type: UPDATE_VENDOR_SUCCESS, payload: updatedVendor });
    dispatch(fetchVendors());
  } catch (error) {
    dispatch({ type: UPDATE_VENDOR_ERROR, payload: error.message });
  }
};
