// types.js

export const FETCH_VENDORS_SUCCESS = "FETCH_VENDORS_SUCCESS";
export const FETCH_VENDORS_ERROR = "FETCH_VENDORS_ERROR";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
export const UPDATE_VENDOR_ERROR = "UPDATE_VENDOR_ERROR";
export const ADD_VENDOR_SUCCESS = 'ADD_VENDOR_SUCCESS';
export const ADD_VENDOR_ERROR = 'ADD_VENDOR_ERROR';

export const DELETE_VENDOR_SUCCESS = 'DELETE_VENDOR_SUCCESS';
export const DELETE_VENDOR_ERROR = 'DELETE_VENDOR_ERROR';


// Deals
export const FETCH_DEALS_SUCCESS = "FETCH_DEALS_SUCCESS";
export const FETCH_DEALS_ERROR = "FETCH_DEALS_ERROR";
export const ADD_DEAL_SUCCESS = "ADD_DEAL_SUCCESS";
export const ADD_DEAL_ERROR = "ADD_DEAL_ERROR";
export const UPDATE_DEAL_SUCCESS = "UPDATE_DEAL_SUCCESS";
export const UPDATE_DEAL_ERROR = "UPDATE_DEAL_ERROR";

// Categories
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_ERROR = "FETCH_CATEGORIES_ERROR";

// Store Locations
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_ERROR = 'ADD_LOCATION_ERROR';

// CUSTOMER types
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_ERROR = 'FETCH_CUSTOMERS_ERROR';

export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_ERROR = 'ADD_CUSTOMER_ERROR';

export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR';

export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR';

// Coupon Types
export const FETCH_COUPONS_SUCCESS = "FETCH_COUPONS_SUCCESS";
export const FETCH_COUPONS_ERROR = "FETCH_COUPONS_ERROR";

export const REGENERATE_COUPON_SUCCESS = "REGENERATE_COUPON_SUCCESS";
export const REGENERATE_COUPON_ERROR = "REGENERATE_COUPON_ERROR";

// Subscription Types
export const FETCH_SUBSCRIPTIONS_SUCCESS = "FETCH_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SUBSCRIPTIONS_ERROR = "FETCH_SUBSCRIPTIONS_ERROR";

export const ADD_SUBSCRIPTION_PLAN_SUCCESS = "ADD_SUBSCRIPTION_PLAN_SUCCESS";
export const ADD_SUBSCRIPTION_PLAN_ERROR = "ADD_SUBSCRIPTION_PLAN_ERROR";

export const UPDATE_SUBSCRIPTION_PLAN_SUCCESS = "UPDATE_SUBSCRIPTION_PLAN_SUCCESS";
export const UPDATE_SUBSCRIPTION_PLAN_ERROR = "UPDATE_SUBSCRIPTION_PLAN_ERROR";

//App Config Types
export const FETCH_APPCONFIGS_SUCCESS = "FETCH_APPCONFIGS_SUCCESS";
export const FETCH_APPCONFIGS_ERROR = "FETCH_APPCONFIGS_ERROR";
export const UPDATE_APPCONFIG_SUCCESS = "UPDATE_APPCONFIG_SUCCESS";
export const UPDATE_APPCONFIG_ERROR = "UPDATE_APPCONFIG_ERROR";
export const ADD_APPCONFIG_SUCCESS = "ADD_APPCONFIG_SUCCESS";
export const ADD_APPCONFIG_ERROR = "ADD_APPCONFIG_ERROR";

// Analytics Types
export const FETCH_ANALYTICS_SUCCESS = "FETCH_ANALYTICS_SUCCESS";
export const FETCH_ANALYTICS_ERROR = "FETCH_ANALYTICS_ERROR";

// src/redux/revenueTypes.js
export const FETCH_REVENUE_SUCCESS = 'FETCH_REVENUE_SUCCESS';
export const FETCH_REVENUE_ERROR = 'FETCH_REVENUE_ERROR';

export const FETCH_VENDOR_WALLETS_SUCCESS = 'FETCH_VENDOR_WALLETS_SUCCESS';
export const FETCH_VENDOR_WALLETS_ERROR = 'FETCH_VENDOR_WALLETS_ERROR';
export const FETCH_CUSTOMER_WALLETS_SUCCESS = 'FETCH_CUSTOMER_WALLETS_SUCCESS';
export const FETCH_CUSTOMER_WALLETS_ERROR = 'FETCH_CUSTOMER_WALLETS_ERROR';
export const UPDATE_WALLET_SUCCESS = 'UPDATE_WALLET_SUCCESS';
export const UPDATE_WALLET_ERROR = 'UPDATE_WALLET_ERROR';