import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRevenue } from '../../redux/actions/revenueActions';
import RevenueCouponsTable from './RevenueCouponsTable';
import RevenueSearchBar from './RevenueSearchBar';
import TotalsCard from './TotalsCard';
import { FaSpinner } from 'react-icons/fa';

const RevenueCustomerPage = () => {
    const dispatch = useDispatch();
    const { revenue, error } = useSelector((state) => state.revenue || { revenue: null });
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        dispatch(fetchRevenue());
    }, [dispatch]);

    // Wrap the initialization in useMemo to keep it stable.
    const couponsData = useMemo(() => {
        return revenue ? revenue.coupons : { ppu: [], su: [] };
    }, [revenue]);

    // Combine PPU and SU data into one list.
    const allCoupons = useMemo(() => {
        return [...(couponsData.ppu || []), ...(couponsData.su || [])];
    }, [couponsData]);

    // Filter coupons based on search term (by deal_id, cust_id, or customer_name).
    const filteredCoupons = useMemo(() => {
        if (!searchTerm) return allCoupons;
        const lowerSearch = searchTerm.toLowerCase();
        return allCoupons.filter(coupon =>
            (coupon.deal_id && coupon.deal_id.toString().toLowerCase().includes(lowerSearch)) ||
            (coupon.cust_id && coupon.cust_id.toLowerCase().includes(lowerSearch)) ||
            (coupon.customer_name && coupon.customer_name.toLowerCase().includes(lowerSearch))
        );
    }, [allCoupons, searchTerm]);

    const totalsTitle = searchTerm
        ? `Customer Totals for "${searchTerm}"`
        : 'Customer Totals (All Customers)';

    const totalsConfig = {
        exclude: ['regular_price'],
        custom: {
            customer_count: (data) => {
                const customers = data.map(item => item.cust_id);
                return new Set(customers).size;
            },
            customer_net_savings: (data) => {
                return data.reduce((sum, item) => {
                    return sum + Number(item.customer_net_savings || 0);
                }, 0);
            }
        },
        noCurrency: [
            'coupon_count',
            'coupons_redeemed',
            'customer_count',
            'coupons_generated'
        ],
        fields: [
            { key: 'coupon_count', label: 'COUPON COUNT' },
            { key: 'coupons_generated', label: 'COUPONS GENERATED' },
            { key: 'coupons_redeemed', label: 'COUPONS REDEEMED' },
            { key: 'customer_net_savings', label: 'CUSTOMER NET SAVINGS' },
            { key: 'customer_count', label: 'CUSTOMER COUNT' }
        ]
    };

    const loading = !revenue;

    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <FaSpinner className="animate-spin text-4xl text-blue-500" />
                <p className="mt-4 text-lg">Loading revenue data, please wait...</p>
            </div>
        );
    }

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Customer Value Reports (Coupons)</h1>
            {error && (
                <div className="bg-red-200 text-red-800 px-4 py-2 rounded mb-4">
                    {error}
                </div>
            )}
            <RevenueSearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                filterSource="Coupons"
            />
            <TotalsCard
                data={filteredCoupons}
                title={totalsTitle}
                config={totalsConfig}
                loading={false}
            />
            <div className="mt-6">
                <h2 className="text-xl font-bold mb-4">Customer Value Realization</h2>
                <RevenueCouponsTable
                    coupons={couponsData}
                    searchTerm={searchTerm}
                    showWahRevenue={false}
                    type="ppu"
                />
            </div>
        </div>
    );
};

export default RevenueCustomerPage;
