import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CouponSearchBar from "./CouponSearchBar";
import CouponTable from "./CouponTable";
import { fetchCoupons } from "../../redux/actions/couponActions";

const Coupons = () => {
    const dispatch = useDispatch();
    const { coupons, totalCount, error } = useSelector((state) => state.coupons);

    // Notification state.
    const [notification, setNotification] = useState("");

    // Search term state.
    const [searchTerm, setSearchTerm] = useState("");

    // Status filter state.
    const [statusFilter, setStatusFilter] = useState("");

    // NEW: state to determine if coupons have been fetched
    const [couponsFetched, setCouponsFetched] = useState(false);

    useEffect(() => {
        // Dispatch fetchCoupons and wait for it to complete before setting couponsFetched to true.
        const fetchData = async () => {
            await dispatch(fetchCoupons());
            setCouponsFetched(true);
        };
        fetchData();
    }, [dispatch]);

    // Read query parameters to set initial filters.
    // Now also checks for vendorId.
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const vendorIdParam = params.get("vendorId");
        const customerIdParam = params.get("customerId");
        const couponIdParam = params.get("couponId");
        const dealIdParam = params.get("dealId");
        const statusParam = params.get("status");

        if (vendorIdParam) {
            setSearchTerm(vendorIdParam);
        } else if (customerIdParam) {
            setSearchTerm(customerIdParam);
        } else if (couponIdParam) {
            setSearchTerm(couponIdParam);
        } else if (dealIdParam) {
            setSearchTerm(dealIdParam);
        }

        if (statusParam) {
            setStatusFilter(statusParam);
        }
    }, []);

    // Filter coupons based on search term and status filter.
    // Now also includes vendor id (from coupon.Deals.vendor_id)
    const filteredCoupons = (coupons || []).filter((coupon) => {
        const term = searchTerm.toLowerCase();
        const matchesSearch =
            coupon.coupon_id.toLowerCase().includes(term) ||
            coupon.customer_id.toLowerCase().includes(term) ||
            coupon.deal_id.toLowerCase().includes(term) ||
            (coupon.Deals &&
                coupon.Deals.deal_title &&
                coupon.Deals.deal_title.toLowerCase().includes(term)) ||
            (coupon.Deals &&
                coupon.Deals.vendor_id &&
                coupon.Deals.vendor_id.toLowerCase().includes(term));
        const matchesStatus =
            statusFilter && statusFilter !== "all"
                ? coupon.status.toLowerCase() === statusFilter.toLowerCase()
                : true;
        return matchesSearch && matchesStatus;
    });

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Coupons</h1>
            {error && (
                <div className="bg-red-200 text-red-800 px-4 py-2 rounded mb-4">
                    Error: {error}
                </div>
            )}
            <CouponSearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
            />
            <CouponTable filteredCoupons={filteredCoupons} loading={!couponsFetched} />
        </div>
    );
};

export default Coupons;
