import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DateFilterDropdown from "./DateFilterDropdown";
import { getDateRangeFromFilter } from "./dateUtils";
// import Chart from "react-apexcharts";  // Chart code is commented out
// import Modal from "./Modal";

const VendorsStoresTile = ({ data }) => {
    const navigate = useNavigate();

    // Live stats from backend
    const liveVendors = typeof data?.vendors === "number" ? data.vendors : 0;
    const liveUnapproved = typeof data?.unapproved === "number" ? data.unapproved : 0;

    // For filtering vendorsRaw if needed
    const vendorsRaw = Array.isArray(data?.vendorsRaw) ? data.vendorsRaw : [];

    // For simplicity, we use live numbers. Calculate active vendors as total minus pending approvals.
    const displayedVendors = liveVendors;
    const activeVendors = displayedVendors - liveUnapproved;

    const [dateFilter, setDateFilter] = useState("live");

    return (
        <div className="p-4 bg-gray-700 rounded-lg shadow-lg">
            {/* Tile Header */}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold text-gray-200">Vendors</h2>
                <DateFilterDropdown value={dateFilter} onChange={setDateFilter} />
            </div>

            {/* Totals Section */}
            <div className="mb-4 cursor-pointer" onClick={() => navigate("/vendors")}>
                <div className="text-center">
                    <p className="text-5xl font-bold text-gray-200">
                        {displayedVendors.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Total Vendors</p>
                </div>
            </div>
            <div className="mb-6 grid grid-cols-2 gap-4">
                <div
                    className="text-center cursor-pointer"
                    onClick={() => navigate("/vendors?filterStatus=active")}
                >
                    <p className="text-4xl font-bold text-gray-200">
                        {activeVendors.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Active</p>
                </div>
                <div
                    className="text-center cursor-pointer"
                    onClick={() => navigate("/vendors?filterStatus=inactive")}
                >
                    <p className="text-4xl font-bold text-gray-200">
                        {liveUnapproved.toLocaleString()}
                    </p>
                    <p className="text-lg text-gray-400">Pending Approvals</p>
                </div>
            </div>

            {/* Chart Section - Temporarily commented out */}
            {/*
      <div className="mt-6">
        <Chart options={lineOptions} series={lineSeries} type="line" height={250} />
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4"
          onClick={() => setShowModal(true)}
        >
          Expand Chart
        </button>
      </div>
      */}
        </div>
    );
};

export default VendorsStoresTile;
