import React from "react";

const CustomersSearchBar = ({
                                searchTerm,
                                setSearchTerm,
                                subscriptionFilter,
                                setSubscriptionFilter,
                            }) => {
    return (
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4 space-y-2 md:space-y-0">
            <input
                type="text"
                placeholder="Search by Customer ID, First Name, Last Name, Email, or Phone"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="border border-gray-300 rounded px-4 py-2 w-full md:w-2/3"
            />
            <select
                value={subscriptionFilter}
                onChange={(e) => setSubscriptionFilter(e.target.value)}
                className="border border-gray-300 rounded px-4 py-2 w-full md:w-1/3"
            >
                <option value="all">All Subscription Statuses</option>
                <option value="subscribed">Subscribed</option>
                <option value="unsubscribed">Unsubscribed</option>
            </select>
        </div>
    );
};

export default CustomersSearchBar;
