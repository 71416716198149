import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAnalytics } from "../redux/actions/analyticsActions";
import CustomersTile from "../components/dashboard/CustomersTile";
import VendorsStoresTile from "../components/dashboard/VendorsStoresTile";
import DealsTile from "../components/dashboard/DealsTile";
import CouponsTile from "../components/dashboard/CouponsTile";
import WahRevenueTile from "../components/dashboard/WahRevenueTile";
import CommunicationTile from "../components/dashboard/CommunicationTile";
import RealizedValueTile from "../components/dashboard/RealizedValueTile";
import { FaSpinner } from "react-icons/fa";

const Dashboard = () => {
    const dispatch = useDispatch();
    const { analytics, error } = useSelector((state) => state.analytics);

    useEffect(() => {
        dispatch(fetchAnalytics());
    }, [dispatch]);

    if (error) {
        return (
            <div className="p-6 text-red-500">
                <p>Error: {error}</p>
            </div>
        );
    }
    if (!analytics) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <FaSpinner className="animate-spin text-4xl text-blue-500" />
                <p className="mt-4 text-lg">Loading analytics...</p>
            </div>
        );
    }

    const safeCustomers = analytics.customers ?? { registered: 0, subscribed: 0, unsubscribed: 0, growth: [] };
    const safeVendorsStores =
        analytics.vendorsStores ??
        { vendors: 0, stores: 0, trend: { vendors: 0, stores: 0 }, growth: { vendors: [], stores: [] } };
    const safeDeals =
        analytics.deals ??
        {
            published: 0,
            active: 0,
            deactivated: 0,
            paused: 0,
            expired: 0,
            trend: { published: 0, active: 0, deactivated: 0, paused: 0, expired: 0 },
        };
    const safeCoupons =
        analytics.coupons ??
        {
            published: 0,
            generated: 0,
            redeemed: 0,
            trend: { published: 0, generated: 0, redeemed: 0 },
        };
    const safeWahRevenue =
        analytics.wahRevenue ??
        {
            vendorCouponsSale: 0,
            wahFeeFromCustomers: 0,
            subscriptions: 0,
            trend: { total: 0, vendorCouponsSale: 0, wahFeeFromCustomers: 0, subscriptions: 0 },
            chartData: [],
            timeSeriesData: [],
        };
    const safeCommunication = analytics.communication ?? { customerChats: 0, vendorChats: 0 };
    const safeRealizedValue =
        analytics.realizedValue ??
        {
            customerData: { dealsPurchased: 0, savings: 0, trend: { dealsPurchased: 0, savings: 0 }, dealBreakdown: [] },
            vendorData: { couponsRedeemed: 0, revenue: 0, trend: { couponsRedeemed: 0, revenue: 0 }, dealBreakdown: [] },
        };

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-800 via-gray-900 to-black text-gray-100 p-6">
            <header className="mb-8">
                <h1 className="text-3xl md:text-4xl font-bold tracking-wide">Dashboard</h1>
            </header>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                <div className="bg-gray-700 rounded-lg shadow-lg hover:shadow-xl transition-shadow p-4">
                    <CustomersTile data={safeCustomers} />
                </div>
                <div className="bg-gray-700 rounded-lg shadow-lg hover:shadow-xl transition-shadow p-4">
                    <VendorsStoresTile data={safeVendorsStores} />
                </div>
                <div className="bg-gray-700 rounded-lg shadow-lg hover:shadow-xl transition-shadow p-4">
                    <DealsTile data={safeDeals} />
                </div>
                <div className="bg-gray-700 rounded-lg shadow-lg hover:shadow-xl transition-shadow p-4">
                    <CouponsTile data={safeCoupons} />
                </div>
                <div className="bg-gray-700 rounded-lg shadow-lg hover:shadow-xl transition-shadow p-4">
                    <WahRevenueTile data={safeWahRevenue} />
                </div>
                {/*<div className="bg-gray-700 rounded-lg shadow-lg hover:shadow-xl transition-shadow p-4">*/}
                {/*    <RealizedValueTile*/}
                {/*        customerData={safeRealizedValue.customerData}*/}
                {/*        vendorData={safeRealizedValue.vendorData}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="bg-gray-700 rounded-lg shadow-lg hover:shadow-xl transition-shadow p-4">
                    <CommunicationTile data={safeCommunication} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
