import { combineReducers } from 'redux';
import vendorReducer from './vendorReducer';
import dealReducer from './dealReducer';
import categoriesReducer from './categoriesReducer';
import storeLocationReducer from './storeLocationReducer';
import customerReducer from "./customerReducer";
import couponReducer from './couponReducer';
import subscriptionReducer from './subscriptionReducer';
import appConfigReducer from './appConfigReducer';
import analyticsReducer from './analyticsReducer';
import revenueReducer from './revenueReducer';
import walletReducer from './walletReducer';

export default combineReducers({
  vendors: vendorReducer,
  deals: dealReducer,
  categories: categoriesReducer,
  storeLocations: storeLocationReducer,
  customers: customerReducer,
  coupons: couponReducer,
  subscriptions: subscriptionReducer,
  appConfig: appConfigReducer,
  analytics: analyticsReducer,
  revenue: revenueReducer,
  wallets: walletReducer,
});
