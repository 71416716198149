import React from 'react';

const EditLocationModal = ({
                               selectedLocation,
                               editedLocationData,
                               setEditedLocationData,
                               closeEditModal,
                               handleUpdateLocation,
                               vendors,
                           }) => {
    if (!selectedLocation) return null;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedLocationData((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg max-w-lg w-full relative max-h-[80vh] overflow-y-auto">
                <button
                    onClick={closeEditModal}
                    className="absolute top-2 right-2 text-gray-500"
                >
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">Edit Store Location</h2>

                {/* Vendor (optional) */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Vendor</label>
                    <select
                        name="vendor_id"
                        value={editedLocationData.vendor_id || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    >
                        <option value="">Select vendor</option>
                        {vendors?.map((v) => (
                            <option key={v.vendor_id} value={v.vendor_id}>
                                {v.business_name} ({v.vendor_id})
                            </option>
                        ))}
                    </select>
                </div>

                {/* Store Manager */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Store Manager</label>
                    <input
                        type="text"
                        name="store_manager"
                        value={editedLocationData.store_manager || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                </div>

                {/* Mobile Phone */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Mobile Phone</label>
                    <input
                        type="text"
                        name="mobile_phone"
                        value={editedLocationData.mobile_phone || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                </div>

                {/* Store Email */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Store Email</label>
                    <input
                        type="email"
                        name="store_email"
                        value={editedLocationData.store_email || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                </div>

                {/* Address */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Address</label>
                    <input
                        type="text"
                        name="address"
                        value={editedLocationData.address || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                </div>

                {/* Street */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Street</label>
                    <input
                        type="text"
                        name="street"
                        value={editedLocationData.street || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                </div>

                {/* City */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">City</label>
                    <input
                        type="text"
                        name="city"
                        value={editedLocationData.city || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                </div>

                {/* State */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">State</label>
                    <input
                        type="text"
                        name="state"
                        value={editedLocationData.state || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                </div>

                {/* Country */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Country</label>
                    <input
                        type="text"
                        name="country"
                        value={editedLocationData.country || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                </div>

                {/* Zipcode */}
                <div className="mb-3">
                    <label className="block font-semibold mb-1">Zipcode</label>
                    <input
                        type="number"
                        name="zipcode"
                        value={editedLocationData.zipcode || ''}
                        onChange={handleChange}
                        className="border border-gray-300 rounded w-full p-2"
                    />
                </div>

                <button
                    onClick={handleUpdateLocation}
                    className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
                >
                    Save Changes
                </button>
            </div>
        </div>
    );
};

export default EditLocationModal;
