import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const EditCustomerModal = ({
                               customerData,
                               setCustomerData,
                               closeEditModal,
                               handleSaveChanges,
                               isUpdatingCustomer,
                           }) => {
    if (!customerData) return null;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomerData((prev) => ({ ...prev, [name]: value }));
    };

    // When the zipcode field loses focus, auto-populate address fields
    const handleZipcodeBlur = async () => {
        const zipcode = customerData.zipcode;
        if (zipcode && zipcode.length === 5) {
            try {
                const response = await fetch(`http://api.zippopotam.us/us/${zipcode}`);
                if (response.ok) {
                    const data = await response.json();
                    const place = data.places[0];
                    setCustomerData((prev) => ({
                        ...prev,
                        city: place["place name"] || prev.city,
                        state: place["state abbreviation"] || prev.state,
                        country: data.country || prev.country,
                    }));
                }
            } catch (error) {
                console.error("Failed to fetch address info", error);
            }
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg max-w-4xl w-full relative">
                <button onClick={closeEditModal} className="absolute top-2 right-2 text-gray-500">
                    ✖
                </button>
                <h2 className="text-xl font-bold mb-4">Edit Customer</h2>

                <div className="grid grid-cols-2 gap-4 max-h-[70vh] overflow-y-auto">
                    <div>
                        <label>
                            <strong>First Name:</strong>
                        </label>
                        <input
                            type="text"
                            name="first_name"
                            value={customerData.first_name || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>
                    <div>
                        <label>
                            <strong>Last Name:</strong>
                        </label>
                        <input
                            type="text"
                            name="last_name"
                            value={customerData.last_name || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>
                    <div>
                        <label>
                            <strong>Zipcode:</strong>
                        </label>
                        <input
                            type="text"
                            name="zipcode"
                            value={customerData.zipcode || ''}
                            onChange={handleInputChange}
                            onBlur={handleZipcodeBlur}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>
                    <div>
                        <label>
                            <strong>Address:</strong>
                        </label>
                        <input
                            type="text"
                            name="address"
                            value={customerData.address || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>
                    <div>
                        <label>
                            <strong>Street:</strong>
                        </label>
                        <input
                            type="text"
                            name="street"
                            value={customerData.street || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>
                    <div>
                        <label>
                            <strong>City:</strong>
                        </label>
                        <input
                            type="text"
                            name="city"
                            value={customerData.city || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>
                    <div>
                        <label>
                            <strong>State:</strong>
                        </label>
                        <input
                            type="text"
                            name="state"
                            value={customerData.state || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>
                    <div>
                        <label>
                            <strong>Country:</strong>
                        </label>
                        <input
                            type="text"
                            name="country"
                            value={customerData.country || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>
                    <div className="col-span-2">
                        <label>
                            <strong>Community:</strong>
                        </label>
                        <input
                            type="text"
                            name="community"
                            value={customerData.community || ''}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded w-full p-2"
                        />
                    </div>
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        onClick={handleSaveChanges}
                        className="bg-blue-500 text-white px-4 py-2 rounded shadow"
                        disabled={isUpdatingCustomer}
                    >
                        {isUpdatingCustomer ? (
                            <span className="flex items-center">
                                <FaSpinner className="animate-spin mr-2" /> Saving...
                            </span>
                        ) : (
                            "Save Changes"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditCustomerModal;
