// src/components/CustomerRealizedValueModal.jsx
import React from "react";

const CustomerRealizedValueModal = ({ customer, onClose }) => {
    // Filter redeemed coupons from customer's Coupons array
    const redeemedCoupons = (customer.Coupons || []).filter(
        (coupon) => coupon.status === "redeemed"
    );

    // Group redeemed coupons by deal_id
    const groups = {};
    redeemedCoupons.forEach((coupon) => {
        const dealId = coupon.deal_id;
        if (!groups[dealId]) {
            groups[dealId] = [];
        }
        groups[dealId].push(coupon);
    });

    // Aggregate group data
    const aggregatedData = Object.keys(groups).map((dealId) => {
        const group = groups[dealId];
        const redeemedCount = group.length;
        // Concatenate all coupon IDs for this group
        const couponIds = group.map((coupon) => coupon.coupon_id).join(", ");
        // Use deal info from the first coupon (assumed same for all in group)
        const deal = group[0].Deals || {};
        const regularPrice = Number(deal.regular_price || 0);
        const wahDealPrice = Number(deal.wah_price || 0);
        const wahFee = wahDealPrice * 0.10;
        const stripeFee = wahDealPrice * 0.029 + 0.30;
        const totalWahRevenue = wahFee * redeemedCount;
        const netSavingsPerCoupon = regularPrice - (wahDealPrice + wahFee + stripeFee);
        const totalNetSavings = netSavingsPerCoupon * redeemedCount;
        return {
            deal_id: dealId,
            coupon_ids: couponIds,
            regular_price: regularPrice,
            wah_deal_price: wahDealPrice,
            wah_fee: wahFee,
            stripe_fee: stripeFee,
            wah_revenue: totalWahRevenue,
            customer_net_savings: totalNetSavings,
        };
    });

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4 z-50">
            <div className="bg-white p-6 rounded shadow-lg max-h-full overflow-auto w-full max-w-5xl">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">
                        Customer Realized Value Details:{" "}
                        {customer.full_name
                            ? `${customer.full_name} (${customer.customer_id})`
                            : customer.customer_id}
                    </h2>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
                        Close
                    </button>
                </div>
                <div className="mb-4">
                    <h3 className="text-lg font-semibold mb-2">Aggregated Totals</h3>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        <div>
                            <span className="text-gray-500 uppercase text-xs">Total Redeemed Coupons</span>
                            <div className="text-xl font-bold">{redeemedCoupons.length}</div>
                        </div>
                        <div>
                            <span className="text-gray-500 uppercase text-xs">Customer Net Savings</span>
                            <div className="text-xl font-bold">
                                ${Number(customer.customer_net_savings || 0).toFixed(2)}
                            </div>
                        </div>
                        <div>
                            <span className="text-gray-500 uppercase text-xs">Wah! Revenue Generated</span>
                            <div className="text-xl font-bold">
                                ${Number(customer.wah_revenue_generated || 0).toFixed(2)}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 className="text-lg font-semibold mb-2">Redeemed Coupon Details</h3>
                    <div className="overflow-x-auto">
                        <table className="min-w-full text-left border">
                            <thead>
                            <tr className="bg-gray-100 text-gray-700 uppercase text-sm">
                                <th className="py-2 px-4">Coupon ID(s)</th>
                                <th className="py-2 px-4">Deal ID</th>
                                <th className="py-2 px-4">Regular Price</th>
                                <th className="py-2 px-4">Wah Deal Price</th>
                                <th className="py-2 px-4">WAH Fee</th>
                                <th className="py-2 px-4">Stripe Fee</th>
                                <th className="py-2 px-4">WAH Revenue</th>
                                <th className="py-2 px-4">Customer Net Savings</th>
                            </tr>
                            </thead>
                            <tbody>
                            {aggregatedData.map((row, idx) => (
                                <tr key={idx} className="border-b hover:bg-gray-50">
                                    <td className="py-2 px-4">{row.coupon_ids}</td>
                                    <td className="py-2 px-4">{row.deal_id}</td>
                                    <td className="py-2 px-4">${row.regular_price.toFixed(2)}</td>
                                    <td className="py-2 px-4">${row.wah_deal_price.toFixed(2)}</td>
                                    <td className="py-2 px-4">${row.wah_fee.toFixed(2)}</td>
                                    <td className="py-2 px-4">${row.stripe_fee.toFixed(2)}</td>
                                    <td className="py-2 px-4">${row.wah_revenue.toFixed(2)}</td>
                                    <td className="py-2 px-4">${row.customer_net_savings.toFixed(2)}</td>
                                </tr>
                            ))}
                            {aggregatedData.length === 0 && (
                                <tr>
                                    <td colSpan={8} className="text-center py-4">
                                        No redeemed coupons found.
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerRealizedValueModal;
