// subscriptionReducer.js

import {
    FETCH_SUBSCRIPTIONS_SUCCESS,
    FETCH_SUBSCRIPTIONS_ERROR,
    ADD_SUBSCRIPTION_PLAN_SUCCESS,
    ADD_SUBSCRIPTION_PLAN_ERROR,
    UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
    UPDATE_SUBSCRIPTION_PLAN_ERROR,
} from "../types";

const initialState = {
    plans: [],
    error: null,
};

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SUBSCRIPTIONS_SUCCESS:
            return { ...state, plans: action.payload, error: null };

        case FETCH_SUBSCRIPTIONS_ERROR:
            return { ...state, plans: [], error: action.payload };

        case ADD_SUBSCRIPTION_PLAN_SUCCESS:
            return { ...state, plans: [...state.plans, action.payload], error: null };

        case ADD_SUBSCRIPTION_PLAN_ERROR:
            return { ...state, error: action.payload };

        case UPDATE_SUBSCRIPTION_PLAN_SUCCESS:
            return {
                ...state,
                plans: state.plans.map((plan) =>
                    plan.plan_id === action.payload.plan_id ? action.payload : plan
                ),
                error: null,
            };

        case UPDATE_SUBSCRIPTION_PLAN_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default subscriptionReducer;
