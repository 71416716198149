// api.js

import axios from 'axios';

// const API_URL = "https://infnamnlgddsnzcttpfq.supabase.co/functions/v1";
// const AUTH_TOKEN = 'Bearer ';

const API_URL = process.env.REACT_APP_API_URL;
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

// Fetch all vendors
export const getVendorsAdmin = async () => {
  try {
    const response = await axios.get(`${API_URL}/getVendorsAdmin`, {
      headers: { 'Authorization': AUTH_TOKEN },
      maxBodyLength: Infinity,
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching vendors:", error);
    throw error;
  }
};

//add new vendor
export const addVendorAdmin = async (vendorData) => {
    try {
      const response = await axios.post(`${API_URL}/addVendorAdmin`, vendorData, {
        headers: { Authorization: AUTH_TOKEN, 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      console.error("Error adding vendor:", error);
      throw error;
    }
  };
  

// Update vendor details or status
export const updateVendorAdmin = async (vendorId, updates = {}, deactivate = false, action) => {
  try {
    const response = await axios.post(
      `${API_URL}/updateVendorsAdmin`,
      { vendorId, updates, deactivate, action },
      { headers: { 'Authorization': AUTH_TOKEN, 'Content-Type': 'application/json' } }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating vendor:", error);
    throw error;
  }
};

// Fetch all deals
export const getDealsAdmin = async () => {
  try {
    const response = await axios.get(`${API_URL}/getDealsAdmin`, {
      headers: { 'Authorization': AUTH_TOKEN },
      maxBodyLength: Infinity,
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching deals:", error);
    throw error;
  }
};

// Add a new deal
export const addDealAdmin = async (dealData) => {
    try {
      const response = await axios.post(`${API_URL}/addDealAdmin`, dealData, {
        headers: { Authorization: AUTH_TOKEN, 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      console.error("Error adding deal:", error);
      throw error;
    }
  };

export const updateDealAdmin = async (dealId, updates) => {
    try {
      const response = await axios.post(
        `${API_URL}/updateDealsAdmin`,
        { dealId, updates },
        {
          headers: {
            Authorization: AUTH_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error updating deal:", error);
      throw error;
    }
  };
  

// Deactivate a deal
export const deactivateDeal = async (dealId) => {
    try {
      const response = await axios.post(
        `${API_URL}/deactivateDeal`,
        { deal_id: dealId },
        { headers: { 'Authorization': AUTH_TOKEN, 'Content-Type': 'application/json' } }
      );
      return response.data;
    } catch (error) {
      console.error("Error deactivating deal:", error);
      throw error;
    }
  };
  
  // Activate a deal
  export const activateDeal = async (dealId) => {
    try {
      const response = await axios.post(
        `${API_URL}/activateDeal`,
        { deal_id: dealId },
        { headers: { 'Authorization': AUTH_TOKEN, 'Content-Type': 'application/json' } }
      );
      return response.data;
    } catch (error) {
      console.error("Error activating deal:", error);
      throw error;
    }
  };



  export const getCategoriesAdmin = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/getCategories`,
        {
          headers: { Authorization: AUTH_TOKEN, 'Content-Type': 'application/json' },
        }
      );
      return response.data; // Return the entire response to preserve the `categories` key
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  };
  
  export const updateCategoryAdmin = async (categoryData) => {
    try {
      const response = await axios.post(
        `${API_URL}/updateCategoryAdmin`,
        categoryData,
        {
          headers: { Authorization: AUTH_TOKEN, "Content-Type": "application/json" },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating category:", error);
      throw error;
    }
  };
  
  export const addCategoryAdmin = async (categoryData) => {
    try {
      const response = await axios.post(
        `${API_URL}/addCategoryAdmin`,
        categoryData,
        {
          headers: { Authorization: AUTH_TOKEN, "Content-Type": "application/json" },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error adding category:", error);
      throw error;
    }
  };

  export const deleteCategoryAdmin = async (categoryId) => {
    try {
      const response = await axios.post(
        `${API_URL}/deleteCategoryAdmin`,
        { categoryId },
        {
          headers: { Authorization: AUTH_TOKEN, "Content-Type": "application/json" },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting category:", error);
      throw error;
    }
  };
  
  // Fetch vendor locations
export const getStoreLocationsAdmin = async (vendorId) => {
    try {
      const response = await axios.get(`${API_URL}/getStoreLocationsAdmin`, {
        headers: { Authorization: AUTH_TOKEN },
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching vendor locations:', error);
      throw error;
    }
  };
  
  // Add a new store location
  export const addStoreLocationAdmin = async (locationData) => {
    try {
      const response = await axios.post(`${API_URL}/addStoreLocationAdmin`, locationData, {
        headers: { Authorization: AUTH_TOKEN, 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      console.error('Error adding vendor location:', error);
      throw error;
    }
  };
  

  export const validateAdmin = async (loginData) => {
    try {
      const response = await axios.post(
        `${API_URL}/validateAdmin`,
        loginData,
        {
          headers: { Authorization: AUTH_TOKEN, "Content-Type": "application/json" },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error validating admin:", error);
      throw error;
    }
  };

  // Fetch all customers
export const getCustomersAdmin = async () => {
  try {
    const response = await axios.get(`${API_URL}/getCustomersAdmin`, {
      headers: { Authorization: AUTH_TOKEN },
      maxBodyLength: Infinity,
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching customers:", error);
    throw error;
  }
};

export const addCustomerAdmin = async (customerData) => {
  try {
    const response = await axios.post(`${API_URL}/addCustomer`, customerData, {
      headers: { Authorization: AUTH_TOKEN, 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error("Error adding customer:", error);
    throw error;
  }
};

// Update customer details
export const updateCustomerAdmin = async (customerId, updates = {}) => {
  try {
    // The backend presumably expects { customer_id, updates } or similar
    const response = await axios.post(
        `${API_URL}/updateCustomerAdmin`,
        {
          customer_id: customerId,
          updates,
        },
        {
          headers: {
            Authorization: AUTH_TOKEN,
            'Content-Type': 'application/json',
          },
        }
    );
    return response.data; // The updated customer
  } catch (error) {
    console.error("Error updating customer:", error);
    throw error;
  }
};

// Delete customer
export const deleteCustomerAdmin = async (customerId) => {
  try {
    const response = await axios.delete(
        `${API_URL}/deleteCustomerAdmin`,
        {
          data: { customer_id: customerId },
          headers: {
            Authorization: AUTH_TOKEN,
            'Content-Type': 'application/json',
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting customer:", error);
    throw error;
  }
};

export const getCouponsAdmin = async () => {
  try {
    const response = await axios.get(`${API_URL}/getCouponsAdmin`, {
      headers: { Authorization: AUTH_TOKEN },
      maxBodyLength: Infinity,
    });
    // Expecting response.data = { data: coupons, totalCount }
    return response.data;
  } catch (error) {
    console.error("Error fetching coupons:", error);
    throw error;
  }
};

// Fetch all subscription plans w/ nested enrollments
export const getSubscriptionPlansAdmin = async () => {
  try {
    // GET from your edge function
    const response = await axios.get(`${API_URL}/getSubscriptionPlansAdmin`, {
      headers: { Authorization: AUTH_TOKEN },
    });
    // The edge function returns { data: [ ...plans ] }
    return response.data.data;
  } catch (error) {
    console.error("Error fetching subscription plans:", error);
    throw error;
  }
};

// Add a new subscription plan
export const addSubscriptionPlanAdmin = async (planData) => {
  try {
    // POST to your edge function
    // planData might be { plan_name, plan_description, subscription_fee, etc. }
    const response = await axios.post(`${API_URL}/addSubscriptionPlanAdmin`, planData, {
      headers: {
        Authorization: AUTH_TOKEN,
        "Content-Type": "application/json",
      },
    });
    // Edge function typically returns { success: true, data: [ createdPlan ] }
    // We'll assume data is an array w/ the newly created plan in index 0
    return response.data.data ? response.data.data[0] : null;
  } catch (error) {
    console.error("Error adding subscription plan:", error);
    throw error;
  }
};

// Update an existing subscription plan
export const updateSubscriptionPlanAdmin = async (planId, updates) => {
  try {
    // PUT to your edge function
    // You may pass { plan_id, ...updates } or do it as path param
    const body = { plan_id: planId, ...updates };
    const response = await axios.put(`${API_URL}/updateSubscriptionPlanAdmin`, body, {
      headers: {
        Authorization: AUTH_TOKEN,
        "Content-Type": "application/json",
      },
    });
    // Expect { success: true, data: [ updatedPlan ] }
    return response.data.data ? response.data.data[0] : null;
  } catch (error) {
    console.error("Error updating subscription plan:", error);
    throw error;
  }
};

// Deactivate plan (just an update w/ status: "inactive")
export const deactivatePlanAdmin = async (planId) => {
  try {
    const body = { plan_id: planId, status: "inactive" };
    const response = await axios.put(`${API_URL}/updateSubscriptionPlanAdmin`, body, {
      headers: {
        Authorization: AUTH_TOKEN,
        "Content-Type": "application/json",
      },
    });
    return response.data.data ? response.data.data[0] : null;
  } catch (error) {
    console.error("Error deactivating subscription plan:", error);
    throw error;
  }
};

// Activate plan (just an update w/ status: "active")
export const activatePlanAdmin = async (planId) => {
  try {
    const body = { plan_id: planId, status: "active" };
    const response = await axios.put(`${API_URL}/updateSubscriptionPlanAdmin`, body, {
      headers: {
        Authorization: AUTH_TOKEN,
        "Content-Type": "application/json",
      },
    });
    return response.data.data ? response.data.data[0] : null;
  } catch (error) {
    console.error("Error activating subscription plan:", error);
    throw error;
  }
};

export const updateStoreLocationAdmin = async (locationId, updates) => {
  try {
    const body = { location_id: locationId, ...updates };
    const response = await axios.put(`${API_URL}/updateStoreLocationAdmin`, body, {
      headers: { Authorization: AUTH_TOKEN, 'Content-Type': 'application/json' },
    });
    return response.data; // { success: true, data: [...] }
  } catch (error) {
    console.error('Error updating store location:', error);
    throw error;
  }
};

export const getAllAppConfigs = async () => {
  try {
    const response = await axios.get(`${API_URL}/getAppConfigAdmin`, {
      headers: { Authorization: AUTH_TOKEN },
      maxBodyLength: Infinity,
    });
    // The GET endpoint returns an object { data: [...] }
    return response.data.data;
  } catch (error) {
    console.error("Error fetching app configs:", error);
    throw error;
  }
};

export const updateAppConfigAdmin = async (configId, updates) => {
  try {
    const response = await axios.post(
        `${API_URL}/updateAppConfigAdmin`,
        { id: configId, updates },
        {
          headers: { Authorization: AUTH_TOKEN, "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating app config:", error);
    throw error;
  }
};

export const addAppConfigAdmin = async (configData) => {
  try {
    const response = await axios.post(
        `${API_URL}/addAppConfigAdmin`,
        configData,
        {
          headers: { Authorization: AUTH_TOKEN, "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding app config:", error);
    throw error;
  }
};

// Fetch analytics data from the backend edge function
export const getAnalyticsAdmin = async () => {
  try {
    const response = await axios.get(`${API_URL}/getAnalyticsAdmin`, {
      headers: { Authorization: AUTH_TOKEN },
      maxBodyLength: Infinity,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching analytics:", error);
    throw error;
  }
};


// >>>>>>> NEW FUNCTION FOR DELETING A VENDOR <<<<<<<
export const deleteVendorAdmin = async (vendorId) => {
  try {
    // Use DELETE method; pass vendor_id in the request body as "data"
    const response = await axios.delete(`${API_URL}/deleteVendorAdmin`, {
      headers: { Authorization: AUTH_TOKEN, 'Content-Type': 'application/json' },
      data: { vendor_id: vendorId },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting vendor:", error);
    throw error;
  }
};

// Function to fetch revenue reports from the edge function (getRevenueAdmin)
export const getRevenueAdmin = async () => {
  try {
    const response = await axios.get(`${API_URL}/getRevenueAdmin`, {
      headers: { Authorization: AUTH_TOKEN },
      maxBodyLength: Infinity,
    });
    return response.data; // Expected to be { data: { subscriptions, coupons, vendor } }
  } catch (error) {
    console.error("Error fetching revenue:", error);
    throw error;
  }
};

// Fetch vendor wallets (with recent transactions)
export const getVendorWalletAdmin = async () => {
  try {
    const response = await axios.get(`${API_URL}/getVendorWalletAdmin`, {
      headers: { Authorization: AUTH_TOKEN },
      maxBodyLength: Infinity,
    });
    // Expected response: { wallets: [...] }
    return response.data.wallets;
  } catch (error) {
    console.error("Error fetching vendor wallets:", error);
    throw error;
  }
};

// Fetch customer wallets (with recent transactions)
export const getCustomerWalletAdmin = async () => {
  try {
    const response = await axios.get(`${API_URL}/getCustomerWalletAdmin`, {
      headers: { Authorization: AUTH_TOKEN },
      maxBodyLength: Infinity,
    });
    return response.data.wallets;
  } catch (error) {
    console.error("Error fetching customer wallets:", error);
    throw error;
  }
};

// Update wallet balance
export const updateWalletAdmin = async (walletId, amount, operation) => {
  try {
    const response = await axios.put(
        `${API_URL}/updateWalletAdmin`,
        { wallet_id: walletId, amount, operation },
        {
          headers: { Authorization: AUTH_TOKEN, "Content-Type": "application/json" },
        }
    );
    return response.data.wallet;
  } catch (error) {
    console.error("Error updating wallet:", error);
    throw error;
  }
};

export const regenerateCouponAdmin = async (couponId) => {
  try {
    const response = await axios.post(
        `${API_URL}/regenerateCouponAdmin`,
        { coupon_id: couponId },
        { headers: { Authorization: AUTH_TOKEN } }
    );
    // Expected response.data: { new_qr_code }
    return response.data;
  } catch (error) {
    console.error("Error regenerating coupon:", error);
    throw error;
  }
};

