import React, { useState, useMemo } from 'react';
import Pagination from '../../components/Pagination';

const RevenueCouponsTable = ({ coupons, searchTerm, showWahRevenue = true, type = 'ppu' }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Wrap the conditional initialization in a useMemo so that it doesn't change on every render.
    const data = useMemo(() => {
        return type === 'ppu' ? (coupons.ppu || []) : (coupons.su || []);
    }, [coupons, type]);

    // Group coupons by customer (cust_id) and aggregate values.
    const aggregatedData = useMemo(() => {
        const groups = {};
        data.forEach((coupon) => {
            const key = coupon.cust_id;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(coupon);
        });
        const aggregated = [];
        for (const custId in groups) {
            const deals = groups[custId];
            const couponCount = deals.reduce((sum, d) => sum + Number(d.coupon_count || 0), 0);
            const couponsRedeemed = deals.reduce((sum, d) => sum + Number(d.coupons_redeemed || 0), 0);
            const customerNetSavings = deals.reduce((sum, d) => sum + Number(d.customer_net_savings || 0), 0);
            const wahRevenue = deals.reduce((sum, d) => sum + Number(d.wah_revenue || 0), 0);
            aggregated.push({
                cust_id: custId,
                coupon_count: couponCount,
                coupons_redeemed: couponsRedeemed,
                customer_net_savings: customerNetSavings,
                wah_revenue: wahRevenue,
                deals: deals,
                customer_name: deals[0].customer_name // assume same for all coupons
            });
        }
        // Filter aggregated data based on searchTerm.
        let filtered = aggregated;
        if (searchTerm) {
            const lowerSearch = searchTerm.toLowerCase();
            filtered = aggregated.filter(item =>
                item.cust_id.toString().toLowerCase().includes(lowerSearch) ||
                (item.customer_name && item.customer_name.toLowerCase().includes(lowerSearch)) ||
                item.deals.some(deal => deal.deal_id && deal.deal_id.toString().toLowerCase().includes(lowerSearch))
            );
        }
        // Sort aggregated data.
        if (sortConfig.key) {
            filtered.sort((a, b) => {
                let aVal = a[sortConfig.key];
                let bVal = b[sortConfig.key];
                if (typeof aVal === 'string') {
                    aVal = aVal.toLowerCase();
                    bVal = bVal.toLowerCase();
                }
                if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return filtered;
    }, [data, searchTerm, sortConfig]);

    const totalPages = Math.ceil(aggregatedData.length / itemsPerPage);
    const paginatedData = aggregatedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleSort = (key) => {
        setSortConfig(prev => {
            if (prev.key === key) {
                return { key, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
            }
            return { key, direction: 'asc' };
        });
    };

    // Modal state for customer details.
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const openModal = (customer) => {
        setSelectedCustomer(customer);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedCustomer(null);
        setModalOpen(false);
    };

    return (
        <div className="border rounded shadow">
            <div className="overflow-x-auto">
                <table className="min-w-full text-left">
                    <thead>
                    <tr className="bg-gray-100 text-gray-700 uppercase text-sm">
                        <th onClick={() => handleSort('cust_id')} className="py-2 px-4 cursor-pointer">
                            Cust ID {sortConfig.key === 'cust_id' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('coupon_count')} className="py-2 px-4 cursor-pointer">
                            Coupon Count {sortConfig.key === 'coupon_count' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('coupons_redeemed')} className="py-2 px-4 cursor-pointer">
                            Coupons Redeemed {sortConfig.key === 'coupons_redeemed' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('customer_net_savings')} className="py-2 px-4 cursor-pointer">
                            Customer Net Savings {sortConfig.key === 'customer_net_savings' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        {showWahRevenue && (
                            <th onClick={() => handleSort('wah_revenue')} className="py-2 px-4 cursor-pointer">
                                Wah! Revenue {sortConfig.key === 'wah_revenue' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                            </th>
                        )}
                        <th className="py-2 px-4">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedData.map((customer) => (
                        <tr key={customer.cust_id} className="border-b hover:bg-gray-50">
                            <td className="py-2 px-4">
                                {customer.cust_id} {customer.customer_name && (<span className="text-gray-500 text-xs">({customer.customer_name})</span>)}
                            </td>
                            <td className="py-2 px-4">{customer.coupon_count}</td>
                            <td className="py-2 px-4">{customer.coupons_redeemed}</td>
                            <td className="py-2 px-4">
                                {isNaN(customer.customer_net_savings)
                                    ? 'N/A'
                                    : `$${Number(customer.customer_net_savings).toFixed(2)}`}
                            </td>
                            {showWahRevenue && (
                                <td className="py-2 px-4">
                                    ${Number(customer.wah_revenue).toFixed(2)}
                                </td>
                            )}
                            <td className="py-2 px-4">
                                <button
                                    onClick={() => openModal(customer)}
                                    className="bg-blue-500 text-white px-2 py-1 rounded text-xs"
                                >
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />

            {/* Modal for customer details */}
            {modalOpen && selectedCustomer && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-lg max-h-full overflow-auto">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-bold">
                                Customer Details: {selectedCustomer.customer_name
                                ? `${selectedCustomer.customer_name} (${selectedCustomer.cust_id})`
                                : selectedCustomer.cust_id}
                            </h2>
                            <button onClick={closeModal} className="text-gray-600 hover:text-gray-800">
                                Close
                            </button>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Aggregated Totals</h3>
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                <div>
                                    <span className="text-gray-500 uppercase text-xs">Coupon Count</span>
                                    <div className="text-xl font-bold">{selectedCustomer.coupon_count}</div>
                                </div>
                                <div>
                                    <span className="text-gray-500 uppercase text-xs">Coupons Redeemed</span>
                                    <div className="text-xl font-bold">{selectedCustomer.coupons_redeemed}</div>
                                </div>
                                <div>
                                    <span className="text-gray-500 uppercase text-xs">Customer Net Savings</span>
                                    <div className="text-xl font-bold">
                                        {isNaN(selectedCustomer.customer_net_savings)
                                            ? 'N/A'
                                            : `$${Number(selectedCustomer.customer_net_savings).toFixed(2)}`}
                                    </div>
                                </div>
                                {showWahRevenue && (
                                    <div>
                                        <span className="text-gray-500 uppercase text-xs">Wah! Revenue</span>
                                        <div className="text-xl font-bold">
                                            ${Number(selectedCustomer.wah_revenue).toFixed(2)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-lg font-semibold mb-2">Deal Details</h3>
                            <table className="min-w-full text-left">
                                <thead>
                                <tr className="bg-gray-100 text-gray-700 uppercase text-sm">
                                    <th className="py-2 px-4">Deal ID</th>
                                    <th className="py-2 px-4">Regular Price</th>
                                    <th className="py-2 px-4">Wah! Deal Price</th>
                                    <th className="py-2 px-4">Wah! Fee</th>
                                    <th className="py-2 px-4">Stripe Fee</th>
                                    <th className="py-2 px-4">Coupons Redeemed</th>
                                    <th className="py-2 px-4">Customer Net Savings</th>
                                </tr>
                                </thead>
                                <tbody>
                                {selectedCustomer.deals.map((deal, idx) => (
                                    <tr key={idx} className="border-b hover:bg-gray-50">
                                        <td className="py-2 px-4">{deal.deal_id}</td>
                                        <td className="py-2 px-4">${Number(deal.regular_price).toFixed(2)}</td>
                                        <td className="py-2 px-4">${Number(deal.wah_deal_price).toFixed(2)}</td>
                                        <td className="py-2 px-4">${Number(deal.wah_fee).toFixed(2)}</td>
                                        <td className="py-2 px-4">${Number(deal.stripe_fee).toFixed(2)}</td>
                                        <td className="py-2 px-4">{deal.coupons_redeemed}</td>
                                        <td className="py-2 px-4">
                                            {isNaN(deal.customer_net_savings)
                                                ? 'N/A'
                                                : `$${Number(deal.customer_net_savings).toFixed(2)}`}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RevenueCouponsTable;
