import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRevenue } from '../../redux/actions/revenueActions';
import RevenueSearchBar from './RevenueSearchBar';
import TotalsCard from './TotalsCard';
import RevenueSubscriptionsTable from './RevenueSubscriptionsTable';
import { FaSpinner } from 'react-icons/fa';

const RevenueWahPage = () => {
    const dispatch = useDispatch();
    const { revenue, error } = useSelector((state) => state.revenue || { revenue: null });
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        dispatch(fetchRevenue());
    }, [dispatch]);

    // Always compute subscriptionsData using a fallback so hooks run in the same order.
    const subscriptionsData =
        revenue && revenue.subscriptions
            ? revenue.subscriptions
            : { monthly: [], yearly: [] };

    // Merge all revenue streams (subscriptions, coupons, vendor) for the TotalsCard.
    const mergedRevenueData = useMemo(() => {
        const subs = revenue && revenue.subscriptions
            ? [...(revenue.subscriptions.monthly || []), ...(revenue.subscriptions.yearly || [])]
            : [];
        const coupons = revenue && revenue.coupons
            ? [...(revenue.coupons.ppu || []), ...(revenue.coupons.su || [])]
            : [];
        const vendorData = revenue && revenue.vendor
            ? [...(revenue.vendor.baseCoupons || []), ...(revenue.vendor.upsell || [])]
            : [];
        return [...subs, ...coupons, ...vendorData];
    }, [revenue]);

    // Filter merged data based on searchTerm using deal_id, vend_id, cust_id, or customer_id.
    const filteredRevenueData = useMemo(() => {
        if (!searchTerm) return mergedRevenueData;
        const lowerTerm = searchTerm.toLowerCase();
        return mergedRevenueData.filter((item) =>
            (item.deal_id && item.deal_id.toLowerCase().includes(lowerTerm)) ||
            (item.vend_id && item.vend_id.toLowerCase().includes(lowerTerm)) ||
            (item.cust_id && item.cust_id.toLowerCase().includes(lowerTerm)) ||
            (item.customer_id && item.customer_id.toLowerCase().includes(lowerTerm))
        );
    }, [mergedRevenueData, searchTerm]);

    // Totals configuration for overall Wah! revenue.
    // We list all fields from the screenshot in the fields array.
    const totalsConfig = {
        // Custom aggregator functions for specific fields.
        custom: {
            total_wah_revenue: (data) =>
                data
                    .filter(item => !item.upsell_id) // Only base items if you prefer
                    .reduce((sum, item) => {
                        const fee = Number(item.wah_fee || 0);
                        const count = Number(item.coupon_count || 0);
                        return sum + fee * count;
                    }, 0),

            total_plan_amount: (data) =>
                data.reduce((sum, item) => sum + Number(item.plan_amount || 0), 0),

            unique_deals: (data) => {
                const deals = data.map(item => item.deal_id).filter(Boolean);
                return new Set(deals).size;
            },

            unique_vendors: (data) => {
                const vendors = data.map(item => item.vend_id).filter(Boolean);
                return new Set(vendors).size;
            },

            unique_customers: (data) => {
                const customers = data.map(item => item.cust_id || item.customer_id).filter(Boolean);
                return new Set(customers).size;
            },

            // If `customer_net_savings` is already a numeric field in your data,
            // remove this aggregator. Otherwise, here's a sample aggregator:
            customer_net_savings: (data) =>
                data.reduce((sum, item) => sum + Number(item.customer_net_savings || 0), 0)
        },

        // Fields to display as plain integers (no currency).
        noCurrency: [
            'coupon_count',
            'coupons_generated',
            'coupons_redeemed',
            'unique_deals',
            'unique_vendors',
            'unique_customers'
        ],

        // fields array to control order & labels. Must match keys either from data or custom aggregator.
        fields: [
            { key: 'regular_price', label: 'REGULAR PRICE' },
            { key: 'wah_fee', label: 'WAH FEE' },
            { key: 'stripe_fee', label: 'STRIPE FEE' },
            { key: 'coupon_count', label: 'COUPON COUNT' },
            { key: 'coupons_generated', label: 'COUPONS GENERATED' },
            { key: 'coupons_redeemed', label: 'COUPONS REDEEMED' },
            { key: 'customer_net_savings', label: 'CUSTOMER NET SAVINGS' },
            { key: 'total_wah_revenue', label: 'TOTAL WAH REVENUE' },
            { key: 'total_plan_amount', label: 'TOTAL PLAN AMOUNT' },
            { key: 'unique_deals', label: 'UNIQUE DEALS' },
            { key: 'unique_vendors', label: 'UNIQUE VENDORS' },
            { key: 'unique_customers', label: 'UNIQUE CUSTOMERS' }
        ]
    };

    const totalsTitle = searchTerm
        ? `Wah! Totals for "${searchTerm}"`
        : 'Wah! Totals (All Revenue)';

    // Define a loading state based on whether revenue is available.
    const loading = !revenue;

    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <FaSpinner className="animate-spin text-4xl text-blue-500" />
                <p className="mt-4 text-lg">Loading revenue data, please wait...</p>
            </div>
        );
    }

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Wah! Revenue Reports</h1>
            {error && (
                <div className="bg-red-200 text-red-800 px-4 py-2 rounded mb-4">
                    {error}
                </div>
            )}
            <RevenueSearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                filterSource="Deal/Vendor/Customer ID"
            />

            {/* Totals Card including all fields from your screenshot */}
            <TotalsCard
                data={filteredRevenueData}
                title={totalsTitle}
                config={totalsConfig}
                loading={false}
            />

            <div className="mt-6">
                <h2 className="text-xl font-bold mb-4">Monthly – Total Revenue</h2>
                <RevenueSubscriptionsTable
                    subscriptions={subscriptionsData}
                    searchTerm={searchTerm}
                    showWahRevenue={true}
                    type="monthly"
                />
            </div>

            <div className="mt-6">
                <h2 className="text-xl font-bold mb-4">Yearly – Total Revenue</h2>
                <RevenueSubscriptionsTable
                    subscriptions={subscriptionsData}
                    searchTerm={searchTerm}
                    showWahRevenue={true}
                    type="yearly"
                />
            </div>
        </div>
    );
};

export default RevenueWahPage;
