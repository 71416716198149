// AppConfigTable.jsx
import React, { useState, useMemo } from "react";
import {
    FaChevronRight,
    FaChevronDown,
    FaChevronUp,
    FaSpinner,
} from "react-icons/fa";
import Pagination from "../../components/Pagination";

const AppConfigTable = ({ configs, onEditClick, loading }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [expandedRows, setExpandedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const handleSort = (key) => {
        setSortConfig((prev) => {
            if (prev.key === key) {
                return { key, direction: prev.direction === "asc" ? "desc" : "asc" };
            }
            return { key, direction: "asc" };
        });
        setCurrentPage(1);
    };

    const sortedConfigs = useMemo(() => {
        if (!configs) return [];
        const items = [...configs];
        if (sortConfig.key) {
            items.sort((a, b) => {
                let aVal = a[sortConfig.key] || "";
                let bVal = b[sortConfig.key] || "";
                if (typeof aVal === "string") aVal = aVal.toLowerCase();
                if (typeof bVal === "string") bVal = bVal.toLowerCase();
                if (aVal < bVal) return sortConfig.direction === "asc" ? -1 : 1;
                if (aVal > bVal) return sortConfig.direction === "asc" ? 1 : -1;
                return 0;
            });
        }
        return items;
    }, [configs, sortConfig]);

    const toggleExpand = (id) => {
        setExpandedRows((prev) =>
            prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
        );
    };

    const currentConfigs = useMemo(
        () => sortedConfigs.slice((currentPage - 1) * pageSize, currentPage * pageSize),
        [sortedConfigs, currentPage]
    );

    const totalPages = Math.ceil(sortedConfigs.length / pageSize);

    // Helper to render a field as a link if it starts with "http" or as plain text otherwise.
    const renderLinkOrText = (value, label) => {
        if (!value) return <span>-</span>;
        if (value.startsWith("http")) {
            return (
                <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline"
                >
                    {label}
                </a>
            );
        }
        return <span>{value}</span>;
    };

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full text-left">
                <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th
                        onClick={() => handleSort("app_version")}
                        className="py-3 px-6 text-center cursor-pointer select-none"
                    >
                        App Version{" "}
                        {sortConfig.key === "app_version" &&
                            (sortConfig.direction === "asc" ? <FaChevronUp /> : <FaChevronDown />)}
                    </th>
                    <th
                        onClick={() => handleSort("maintenance_mode")}
                        className="py-3 px-6 text-center cursor-pointer select-none"
                    >
                        Maintenance Mode{" "}
                        {sortConfig.key === "maintenance_mode" &&
                            (sortConfig.direction === "asc" ? <FaChevronUp /> : <FaChevronDown />)}
                    </th>
                    <th
                        onClick={() => handleSort("vendor_free_max_deal_limit")}
                        className="py-3 px-6 text-center cursor-pointer select-none"
                    >
                        Vendor Free Max Deal Limit{" "}
                        {sortConfig.key === "vendor_free_max_deal_limit" &&
                            (sortConfig.direction === "asc" ? <FaChevronUp /> : <FaChevronDown />)}
                    </th>
                    <th
                        onClick={() => handleSort("vendor_paid_max_deal_limit")}
                        className="py-3 px-6 text-center cursor-pointer select-none"
                    >
                        Vendor Paid Max Deal Limit{" "}
                        {sortConfig.key === "vendor_paid_max_deal_limit" &&
                            (sortConfig.direction === "asc" ? <FaChevronUp /> : <FaChevronDown />)}
                    </th>
                    <th
                        onClick={() => handleSort("vendor_commision")}
                        className="py-3 px-6 text-center cursor-pointer select-none"
                    >
                        Vendor Commission{" "}
                        {sortConfig.key === "vendor_commision" &&
                            (sortConfig.direction === "asc" ? <FaChevronUp /> : <FaChevronDown />)}
                    </th>
                    <th
                        onClick={() => handleSort("customer_commision")}
                        className="py-3 px-6 text-center cursor-pointer select-none"
                    >
                        Customer Commission{" "}
                        {sortConfig.key === "customer_commision" &&
                            (sortConfig.direction === "asc" ? <FaChevronUp /> : <FaChevronDown />)}
                    </th>
                    <th className="py-3 px-6 text-center">Actions</th>
                </tr>
                </thead>
                <tbody className="text-gray-700 text-sm font-light">
                {loading ? (
                    <tr>
                        <td colSpan="7" className="text-center py-6">
                            <div className="flex items-center justify-center space-x-2">
                                <FaSpinner className="animate-spin" size={24} />
                                <span>Loading app config, please wait...</span>
                            </div>
                        </td>
                    </tr>
                ) : currentConfigs.length > 0 ? (
                    currentConfigs.map((config) => (
                        <React.Fragment key={config.id}>
                            <tr className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-6 text-center font-medium text-blue-600">
                                    <div className="flex items-center justify-center space-x-2">
                                        <button
                                            onClick={() => toggleExpand(config.id)}
                                            className="focus:outline-none"
                                            title={expandedRows.includes(config.id) ? "Collapse details" : "Expand details"}
                                        >
                                            {expandedRows.includes(config.id) ? <FaChevronDown /> : <FaChevronRight />}
                                        </button>
                                        <span>{config.app_version}</span>
                                    </div>
                                </td>
                                <td className="py-3 px-6 text-center">
                                    {config.maintenance_mode ? "Enabled" : "Disabled"}
                                </td>
                                <td className="py-3 px-6 text-center">
                                    {config.vendor_free_max_deal_limit || "-"}
                                </td>
                                <td className="py-3 px-6 text-center">
                                    {config.vendor_paid_max_deal_limit || "-"}
                                </td>
                                <td className="py-3 px-6 text-center">
                                    {config.vendor_commision || "-"}
                                </td>
                                <td className="py-3 px-6 text-center">
                                    {config.customer_commision || "-"}
                                </td>
                                <td className="py-3 px-6 text-center">
                                    <button
                                        onClick={() => onEditClick(config)}
                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                    >
                                        Edit
                                    </button>
                                </td>
                            </tr>
                            {expandedRows.includes(config.id) && (
                                <tr>
                                    <td colSpan="7" className="bg-gray-50 p-4">
                                        {/* Expanded view: Terms & Conditions and Privacy Policies at the top */}
                                        <div className="mb-4 border-b pb-4">
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label className="block text-gray-700 font-medium">
                                                        Wah Deal T&C:
                                                    </label>
                                                    {renderLinkOrText(config.wah_deal_tc, "Wah Deal T&C")}
                                                </div>
                                                <div>
                                                    <label className="block text-gray-700 font-medium">
                                                        Vendor T&C:
                                                    </label>
                                                    {renderLinkOrText(config.vendor_tc, "Vendor T&C")}
                                                </div>
                                                <div>
                                                    <label className="block text-gray-700 font-medium">
                                                        Customer T&C:
                                                    </label>
                                                    {renderLinkOrText(config.customer_tc, "Customer T&C")}
                                                </div>
                                                <div>
                                                    <label className="block text-gray-700 font-medium">
                                                        Vendor Privacy Policy:
                                                    </label>
                                                    {renderLinkOrText(config.vendor_pp, "Vendor Privacy Policy")}
                                                </div>
                                                <div>
                                                    <label className="block text-gray-700 font-medium">
                                                        Customer Privacy Policy:
                                                    </label>
                                                    {renderLinkOrText(config.customer_pp, "Customer Privacy Policy")}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Other expanded details */}
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div>
                                                <label className="block text-gray-700 font-medium">
                                                    Maintenance Message:
                                                </label>
                                                <p className="mt-1 text-gray-800">{config.maintenance_message || "-"}</p>
                                            </div>
                                            <div>
                                                <label className="block text-gray-700 font-medium">
                                                    Force Update:
                                                </label>
                                                <p className="mt-1 text-gray-800">
                                                    {config.force_update ? "Enabled" : "Disabled"}
                                                </p>
                                            </div>
                                            <div>
                                                <label className="block text-gray-700 font-medium">
                                                    Force Update Message:
                                                </label>
                                                <p className="mt-1 text-gray-800">{config.forceupdatemessage || "-"}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))
                ) : (
                    <tr>
                        <td colSpan="7" className="text-center py-4">
                            No configurations found.
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            {sortedConfigs.length > pageSize && (
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
            )}
        </div>
    );
};

export default AppConfigTable;
