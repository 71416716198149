import React from 'react';

const StoreLocationSearchBar = ({ searchTerm, setSearchTerm, onAddLocationClick }) => {
    return (
        <div className="flex justify-between items-center mb-4">
            <input
                type="text"
                placeholder="Search by Location ID, Address, Manager, etc."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="border border-gray-300 rounded px-4 py-2 w-1/2"
            />
            <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={onAddLocationClick}
            >
                Add Store
            </button>
        </div>
    );
};

export default StoreLocationSearchBar;
