import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  fetchDeals,
  toggleDealStatus,
  updateDeal,
  addDeal,
} from '../../redux/actions/dealActions';
import { getVendorsAdmin, getCategoriesAdmin } from '../../api/api';
import { fetchLocations, addLocation } from '../../redux/actions/storeLocationActions';
import { uploadImageToSupabase } from '../../utils/uploadImages';

import DealSearchBar from './Deals/DealSearchBar';
import DealsTable from './Deals/DealsTable';
import DeactivateDealModal from './Deals/modals/DeactivateDealModal';
import EditDealModal from './Deals/modals/EditDealModal';
import AddDealModal from './Deals/modals/AddDealModal';

const Deals = () => {
  const dispatch = useDispatch();
  const { deals, error } = useSelector((state) => state.deals || {});
  const { locations } = useSelector((state) => state.storeLocations || {});

  const [vendors, setVendors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUpdatingDeal, setIsUpdatingDeal] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const [dealData, setDealData] = useState({});
  const [addDealData, setAddDealData] = useState({
    vendor_id: '',
    category_id: '',
    deal_title: '',
    deal_description: '',
    available_from: '',
    available_to: '',
    regular_price: '',
    discount_value: '',
    wah_price: '',
    coupon_count: '',
    terms_and_conditions: '',
    product_service: '',
    deal_images: [],
    store_location_ids: [],
    discount_type_id: '',
    coupon_expiry: '',
    enable_feedback: false,
    enable_scheduling: false,
    deal_sub_title: '',
    coupon_remaining_count: '',
  });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [imageFiles, setImageFiles] = useState([]);
  const [dealIdInput, setDealIdInput] = useState('');
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [initialRedeemed, setInitialRedeemed] = useState(null);

  // NEW: state to determine if deals have been fetched
  const [dealsFetched, setDealsFetched] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      // Wait for fetchDeals to finish before marking dealsFetched as true
      await dispatch(fetchDeals());
      setDealsFetched(true);
    };
    fetchData();
    fetchVendorsAndCategories();
  }, [dispatch]);

  // Notification auto-close
  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => setNotification(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  // Handle query params for searching
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const vendorIdParam = params.get('vendorId');
    const dealIdParam = params.get('dealId');
    const storeLocationIdParam = params.get('storeLocationId');
    const statusParam = params.get('status');

    if (vendorIdParam) {
      setSearchTerm(vendorIdParam);
    } else if (dealIdParam) {
      setSearchTerm(dealIdParam);
    } else if (storeLocationIdParam) {
      setSearchTerm(storeLocationIdParam);
    }

    if (statusParam) {
      setStatusFilter(statusParam);
    }
  }, [location.search]);

  const fetchVendorsAndCategories = async () => {
    try {
      const fetchedVendors = await getVendorsAdmin();
      const fetchedCategories = await getCategoriesAdmin();
      setVendors(fetchedVendors);
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('Error fetching vendors or categories:', error);
    }
  };

  const handleRefreshLocations = (vendorId) => {
    if (vendorId) {
      dispatch(fetchLocations(vendorId));
    }
  };

  // ========== Add Deal Methods ==========
  const handleVendorChange = (vendorId) => {
    setAddDealData((prevData) => ({
      ...prevData,
      vendor_id: vendorId,
      store_location_ids: [],
    }));
    if (vendorId) {
      dispatch(fetchLocations(vendorId));
    }
  };

  const handleAddLocation = () => {
    const locationData = {
      vendor_id: addDealData.vendor_id,
      location_name: prompt('Enter new store location name:'),
    };
    if (locationData.location_name) {
      dispatch(addLocation(locationData));
    }
  };

  const handleAddDeal = (uploadedImageUrls) => {
    if (
        !addDealData.vendor_id ||
        !addDealData.store_location_ids ||
        addDealData.store_location_ids.length === 0
    ) {
      setNotification('Please select a vendor and store location(s) before adding a deal.');
      return;
    }
    const dealPayload = {
      vendor_id: addDealData.vendor_id,
      category_id: addDealData.category_id,
      deal_title: addDealData.deal_title,
      deal_description: addDealData.deal_description,
      location_id: Array.isArray(addDealData.store_location_ids)
          ? addDealData.store_location_ids
          : [addDealData.store_location_ids],
      discount_type_id: addDealData.discount_type_id,
      regular_price: addDealData.regular_price,
      discount_value: addDealData.discount_value,
      wah_price: addDealData.wah_price,
      coupon_count: addDealData.coupon_count,
      coupon_remaining_count: addDealData.coupon_count,
      available_from: addDealData.available_from,
      available_to: addDealData.available_to,
      coupon_expiry: addDealData.coupon_expiry,
      enable_feedback: addDealData.enable_feedback || false,
      enable_scheduling: addDealData.enable_scheduling || false,
      deal_status: 'under_review',
      terms_and_conditions: addDealData.terms_and_conditions,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      deal_sub_title: addDealData.deal_sub_title || null,
      deal_images: uploadedImageUrls,
    };
    setLoading(true);
    dispatch(addDeal(dealPayload))
        .then(() => {
          setNotification('Deal added successfully!');
          dispatch(fetchDeals());
          setShowAddModal(false);
          resetAddDealData();
        })
        .catch((err) => {
          console.error('Error adding deal:', err);
          setNotification('Error adding deal. Please try again.');
        })
        .finally(() => setLoading(false));
  };

  const uploadImagesAndAddDeal = async () => {
    try {
      const uploadedImageUrls = await Promise.all(
          imageFiles.map((file) => uploadImageToSupabase(file))
      );
      handleAddDeal(uploadedImageUrls);
    } catch (error) {
      console.error('Error uploading images:', error);
      setNotification('Error uploading images. Please try again.');
    }
  };

  const resetAddDealData = () => {
    setAddDealData({
      vendor_id: '',
      category_id: '',
      deal_title: '',
      deal_description: '',
      available_from: '',
      available_to: '',
      regular_price: '',
      discount_value: '',
      wah_price: '',
      coupon_count: '',
      terms_and_conditions: '',
      product_service: '',
      deal_images: [],
      store_location_ids: [],
      discount_type_id: '',
      coupon_expiry: '',
      enable_feedback: false,
      enable_scheduling: false,
      deal_sub_title: '',
      coupon_remaining_count: '',
    });
    setImageFiles([]);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
    resetAddDealData();
  };

  // ========== Deactivate / Activate Deal Methods ==========
  const handleStatusToggle = (deal) => {
    setSelectedDeal(deal);
    setShowDeactivateModal(true);
  };

  const handleConfirmDeactivation = async () => {
    setIsUpdatingStatus(true);
    const isActive = selectedDeal.deal_status?.toLowerCase() === 'active';
    await dispatch(toggleDealStatus(selectedDeal.deal_id, isActive));
    setNotification(`Deal ${isActive ? 'deactivated' : 'activated'} successfully`);
    dispatch(fetchDeals());
    setIsUpdatingStatus(false);
    closeDeactivateModal();
  };

  const closeDeactivateModal = () => {
    setShowDeactivateModal(false);
    setDealIdInput('');
    setSelectedDeal(null);
  };

  // ========== Edit Deal Methods ==========
  const handleEditClick = (deal) => {
    setSelectedDeal(deal);
    setDealData({
      ...deal,
      available_from: deal.available_from?.split('T')[0] || '',
      available_to: deal.available_to?.split('T')[0] || '',
      // Ensure deal_images is always an array. If it's not, wrap it in an array.
      deal_images: Array.isArray(deal.deal_images) ? deal.deal_images : [deal.deal_images],
    });
    const total = parseInt(deal.coupon_count, 10) || 0;
    const remaining = parseInt(deal.coupon_remaining_count, 10) || 0;
    setInitialRedeemed(total - remaining);
    setShowEditModal(true);
    setImageFiles([]);
    setCurrentImageIndex(0);
  };


  const handleRemoveImageEdit = (index) => {
    setImageFiles((prev) => prev.filter((_, i) => i !== index));
    setDealData((prev) => ({
      ...prev,
      deal_images: prev.deal_images.filter((_, i) => i !== index),
    }));
  };

  const uploadImagesAndUpdateDeal = async () => {
    try {
      setIsUpdatingDeal(true);
      const uploadedImageUrls = await Promise.all(
          imageFiles.map((file) => uploadImageToSupabase(file))
      );
      const updatedFields = Object.keys(dealData).reduce((acc, key) => {
        if (dealData[key] !== selectedDeal[key]) {
          acc[key] = dealData[key];
        }
        return acc;
      }, {});
      const updatedDealData = {
        ...updatedFields,
        deal_images: [
          ...dealData.deal_images.filter((img) => !img.startsWith('blob:')),
          ...uploadedImageUrls,
        ],
      };
      await dispatch(updateDeal(selectedDeal.deal_id, updatedDealData));
      dispatch(fetchDeals());
      setNotification('Deal updated successfully!');
      closeEditModal();
    } catch (error) {
      console.error('Error updating deal images:', error);
      setNotification('Error updating deal images. Please try again.');
    } finally {
      setIsUpdatingDeal(false);
    }
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setSelectedDeal(null);
    setDealData({});
  };

  // ========== Common Handlers ==========
  const handleInputChange = (e, isEdit = false) => {
    const { name, value } = e.target;
    if ((name === 'regular_price' || name === 'discount_value') && parseFloat(value) < 0)
      return;
    if (name === 'coupon_count') {
      const newTotal = parseInt(value, 10) || 0;
      if (isEdit) {
        setDealData((prev) => {
          const redeemed =
              initialRedeemed !== null
                  ? initialRedeemed
                  : (parseInt(prev.coupon_count, 10) || 0) -
                  (parseInt(prev.coupon_remaining_count, 10) || 0);
          return {
            ...prev,
            coupon_count: value,
            coupon_remaining_count: Math.max(newTotal - redeemed, 0),
          };
        });
      } else {
        setAddDealData((prev) => ({
          ...prev,
          coupon_count: value,
          coupon_remaining_count: newTotal,
        }));
      }
    } else {
      if (isEdit) {
        setDealData((prev) => {
          const updated = { ...prev, [name]: value };
          if (name === 'regular_price' || name === 'discount_value') {
            const originalPrice = parseFloat(updated.regular_price) || 0;
            const discount = parseFloat(updated.discount_value) || 0;
            updated.wah_price = (originalPrice - (originalPrice * discount) / 100).toFixed(2);
          }
          return updated;
        });
      } else {
        setAddDealData((prev) => {
          const updated = { ...prev, [name]: value };
          if (name === 'regular_price' || name === 'discount_value') {
            const originalPrice = parseFloat(updated.regular_price) || 0;
            const discount = parseFloat(updated.discount_value) || 0;
            updated.wah_price = (originalPrice - (originalPrice * discount) / 100).toFixed(2);
          }
          return updated;
        });
      }
    }
  };

  const handleImageChange = (e, isEdit = false) => {
    const files = Array.from(e.target.files);
    setImageFiles((prev) => [...prev, ...files]);
    if (isEdit) {
      setDealData((prev) => ({
        ...prev,
        deal_images: [
          ...(prev.deal_images || []),
          ...files.map((file) => URL.createObjectURL(file)),
        ],
      }));
    } else {
      setAddDealData((prev) => ({
        ...prev,
        deal_images: [
          ...(prev.deal_images || []),
          ...files.map((file) => URL.createObjectURL(file)),
        ],
      }));
    }
  };

  const handleRemoveImage = (index) => {
    setImageFiles((prev) => prev.filter((_, i) => i !== index));
    setAddDealData((prev) => ({
      ...prev,
      deal_images: prev.deal_images.filter((_, i) => i !== index),
    }));
  };

  const handleDescriptionChangeEdit = (value) => {
    setDealData((prev) => ({ ...prev, deal_description: value }));
  };
  const handleTermsChangeEdit = (value) => {
    setDealData((prev) => ({ ...prev, terms_and_conditions: value }));
  };
  const handleDescriptionChange = (value) => {
    setAddDealData((prev) => ({ ...prev, deal_description: value }));
  };
  const handleTermsChange = (value) => {
    setAddDealData((prev) => ({ ...prev, terms_and_conditions: value }));
  };

  // ========== Search Filtering ==========
  const dealsData = Array.isArray(deals) ? deals : deals?.deals || [];
  const filteredDealsData = dealsData.filter((deal) => {
    const term = searchTerm.toLowerCase();
    const dealId = deal?.deal_id?.toLowerCase() || '';
    const vendorId = deal?.vendor_id?.toLowerCase() || '';
    const title = deal?.deal_title?.toLowerCase() || '';
    const storeMatch =
        deal.store_locations &&
        deal.store_locations.some((loc) => loc.location_id.toLowerCase() === term);
    const matchesSearch =
        dealId.includes(term) ||
        vendorId.includes(term) ||
        title.includes(term) ||
        storeMatch;
    const matchesStatus = statusFilter
        ? deal.deal_status?.toLowerCase() === statusFilter.toLowerCase()
        : true;
    return matchesSearch && matchesStatus;
  });

  return (
      <div className="p-6 relative">
        <h1 className="text-2xl font-bold mb-4">Deals</h1>

        {notification && (
            <div className="fixed top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded shadow-lg flex items-center">
              <span>{notification}</span>
              <button className="ml-4" onClick={() => setNotification(null)}>
                ✖
              </button>
            </div>
        )}

        <DealSearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            onAddDealClick={() => setShowAddModal(true)}
        />

        <DealsTable
            filteredDeals={filteredDealsData}
            handleEditClick={handleEditClick}
            handleStatusToggle={handleStatusToggle}
            loading={!dealsFetched}
        />

        {showDeactivateModal && selectedDeal && (
            <DeactivateDealModal
                selectedDeal={selectedDeal}
                dealIdInput={dealIdInput}
                setDealIdInput={setDealIdInput}
                handleConfirmDeactivation={handleConfirmDeactivation}
                closeDeactivateModal={closeDeactivateModal}
                isUpdatingStatus={isUpdatingStatus}
            />
        )}

        {showEditModal && selectedDeal && (
            <EditDealModal
                selectedDeal={selectedDeal}
                dealData={dealData}
                setDealData={setDealData}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                closeEditModal={closeEditModal}
                uploadImagesAndUpdateDeal={uploadImagesAndUpdateDeal}
                handleRemoveImageEdit={handleRemoveImageEdit}
                handleImageChange={handleImageChange}
                handleInputChange={handleInputChange}
                handleDescriptionChangeEdit={handleDescriptionChangeEdit}
                handleTermsChangeEdit={handleTermsChangeEdit}
                isUpdatingDeal={isUpdatingDeal}
            />
        )}

        {showAddModal && (
            <AddDealModal
                vendors={vendors}
                categories={categories}
                locations={locations}
                addDealData={addDealData}
                setAddDealData={setAddDealData}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                closeAddModal={closeAddModal}
                handleVendorChange={handleVendorChange}
                handleAddLocation={handleAddLocation}
                uploadImagesAndAddDeal={uploadImagesAndAddDeal}
                handleRemoveImage={handleRemoveImage}
                handleImageChange={handleImageChange}
                handleInputChange={handleInputChange}
                handleDescriptionChange={handleDescriptionChange}
                handleTermsChange={handleTermsChange}
                imageFiles={imageFiles}
                handleRefreshLocations={handleRefreshLocations}
            />
        )}
      </div>
  );
};

export default Deals;
